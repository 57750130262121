/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import {TextField, InputLabel, Grid} from '@material-ui/core';
const styles = theme => ({

});

class LargeTextField extends React.Component {
    state = {
        inputValue: this.props.value,
        content: ''
    };

    componentDidMount = () => {
        let { inputValue } = this.state;
        if (typeof inputValue === 'string' || inputValue instanceof String) {
            if (this.props.initialValue && inputValue === null){
                inputValue = this.props.initialValue
            }
            let content = inputValue;
            this.setState({content});
        }

    }

    onChange = () => {
        let { content} = this.state;

        this.props.onChange(content);
    }

    onTextChange = (e) => {
        this.setState({content: e.target.value}, () => {
            this.onChange();
        });
    }


    render() {
        const {classes,  label} = this.props; //value
        let {content} = this.state;

        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel>{label}</InputLabel>
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <TextField  
                            multiline={true} 
                            onChange={(e) => {this.setState({content: e.target.value})}} 
                            onBlur={this.onTextChange} value={content} 
                            placeholder={label}></TextField>
                        </Grid>
                        
                    </Grid>
                </NoSsr>
            </div>
        );
    }
}

LargeTextField.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(LargeTextField);
