import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const styles = theme => ({})

const TextOnlyField = (props) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleInput = (event) => {
    const regex = /^[a-zA-Z0-9\s]*$/; // regular expression that only allows alphabetical letters and whitespace
    const input = event.target.value;

    if (regex.test(input)) {
      setInputValue(input);
      props.onChange(input); // pass the input value to the parent component
    }
  };

  const handleClear = () => {
    setInputValue("");
    props.onChange(""); // pass an empty string to the parent component
  };

  return (
    <TextField
      label={props.label}
      value={inputValue}
      placeholder={props.placeholder}
      onChange={handleInput}
      variant={props.variant || "outlined"}
      margin={props.margin || "normal"}
      fullWidth={props.fullWidth || true}
      multiline={props.multiline || false}
      InputProps={{
        endAdornment: inputValue && ( 
          <IconButton onClick={handleClear}>
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
};



export default withStyles(styles, {withTheme: true})(TextOnlyField);

