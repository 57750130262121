import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import React, {useState} from "react";
import Api from './../Share/api';
import idEncoder from './../Share/idEncoder';
import {withRouter} from 'react-router-dom';
import Toolbar from "@material-ui/core/Toolbar";

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import PromptModal from './PromptModal';

import './BroweModel.css';
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";

import { CircularProgress } from '@material-ui/core';

 const styles = theme => ({
//     paper: {
//         position: 'absolute',
//         minWidth: theme.spacing.unit * 60,
//         backgroundColor: theme.palette.background.paper,
//         boxShadow: theme.shadows[5],
//         padding: 0,
//         outline: 'none',
//         overflow: "auto"
//     },
    // bottomAppBar: {
    //     top: 'auto',
    //     bottom: 0,
    // },
    // MuiToolbar: {
    //     background: "#13364F",
    //     color: "#FFF"
    // },
    // MuiAppBar: {
    //     background: "#01579b"
    // },
    // loading: {
    //     display: "flex",
    //     width: "100%",
    //     height: "100%",
    //     "flex-direction": "column",
    //     "justify-content": "center",
    //     "align-items": "center"
    // }
});

let modalBodyClasses = {
    modalBody: {
        padding: "20px",
        overflow: "auto",
        height: 500,
        maxHeight: "50%"
    }
};

const listStyles = {
    card: {
        height: "100%",
        padding: 8,
        textAlign: "center",
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)'
        }
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
        marginTop: 12
    },
    pos: {
        marginBottom: 12,
    },

};


function TemplateListing(props) {

    let [hovered, setHovered] = useState(false);
    let [textFilter, setTextFilter] = useState('');
    const {classes} = props;

    function navigateToEditRoot(templateName) {
        props.history.push('/popup/edit-template/' + idEncoder.encode({templateName: templateName}));
    }

    function listing() {

        function lis() {
            return props.list.map(x => {
                let validJson = false;
                try {
                    JSON.parse(x.value);
                    validJson = true
                } catch (error) {}

                if(validJson){
                    let value = JSON.parse(x.value);               

                    if(value.config.archive || (value.config.tags  && value.config.tags.indexOf('global') !== -1)){
                        return false;
                    } 
 
                    let description = value.config.description ? value.config.description : "";
                    let searchText = x.name + " " + value.config.title + " " + description;
                    let archived = value.config.hasOwnProperty('archived') ? value.config.archived : false;
                    if (x.scope === undefined && archived === false && searchText.toLowerCase().includes(textFilter.toLowerCase())) {
                        return <Grid key={x._id} item xs={6} sm={6} md={4} lg={2} style={{marginBottom: 28, position: "relative"}}><Card
                            onClick={e => props.onPickTemplate(e, x)}
                            className={classes.card}
                            style={{cursor: "pointer"}}>
                            <CardContent onMouseEnter={() => {setHovered(x.name)}} onMouseLeave={() => {setHovered(false)}}>
                                {/* { hovered === x.name
                                    && <Button  variant="contained" color="secondary" size={"small"} style={{position:"absolute", minWidth: 30, maxWidth: 30, padding: 4, bottom: 20, left: 20}}
                                        onClick={(e) => {e.stopPropagation(); navigateToEditRoot(x.name)}}>
                                    <i style={{fontSize: "14px"}}
                                    className="material-icons">
                                    build
                                </i>
                                </Button>} */}
                                <i className="material-icons primaryIcon">
                                    {value.config.muiIcon || "web_asset"}
                                </i>
                                <Typography variant="h5" className={classes.title} color="textPrimary" gutterBottom>
                                    {value.config.title || x.name}
                                </Typography>
                                <Typography className={classes.title} component="p" color="textSecondary" gutterBottom>
                                    {value.config.description}
                                </Typography>
                            </CardContent>
                        </Card>
                        </Grid>
                    } else {
                        return null;
                    }
                }else{
                    return null;
                }

            });
        }

        if (props.list && Array.isArray(props.list)) {
            return <Grid className={classes.grid} container justify="flex-start" spacing={1}>{lis()}</Grid>
        }

        return <div>No templates yet ...</div>;
    }

    return <div className="template-list">
        <TextField placeholder={"Filter components"} value={textFilter} onChange={(e) => { setTextFilter(e.target.value)}}/>
        {listing()}
    </div>
}

let TemplateListingStyled = withStyles(listStyles)(TemplateListing);

/**
 * Extensability
 *  - the back button goes to the configured location
 *  - on picking a template a custom action happens
 * 
 * Input props:
 *  - props
 *      - dataScope
 */
class BrowseModal extends React.Component {

    constructor(props) {
        super(props);

        // let id = idEncoder.decode(props.match.params.id);
        this.state = {
            // tab ui
            value: 0,
            title: props.customTitle || 'Pick a component to add to the page',
            // instance holder
            // id: id,
            // editInstance: getInstance(id),
            // data to use
            list: [],
            modalStyles: {
                top: `50%`,
                left: `50%`,
                width: '70%',
                minHeight: '70%',
                transform: `translate(-50%, -50%)`,
            },
            fullScreenMode: props.fullScreenMode || false,
            promptOpen: false,
            promptTitle: '',
            promptOnSubmit: () => {
            }
        };
    }

    componentDidMount() {
        document.title = this.displayName || 'Browse';
        this.runApi();
    }

    runApi() {
        (new Api()).templateBrowse(this.props.dataScope.ref, this.props.match.params.id).then((data) => {
            this.setState({list: data.data});
        });
    }

    handleChange = (event, value) => {
        this.setState({value});
    };

    handleClose = () => {
        this.props.history.push('/');
        // this.props.history.go(-1);
        // this.setState({ value: false });
    };

    /**
     * New empty template
     *  - clone template - without a name, but with new desired name -> wet_mushroom
     *  - assign this template to the instance property ....
     */
    onCreateNewTemplate = (newTemplateName) => {
        let {dataScope, location, history, match, noSecurityContext} = this.props;
        this.props.onBrowseAction({dataScope, history, location, match, newTemplateName, noSecurityContext});
    };

    /**
     * WHAT HAPPENS ON TEMPLATE PICK
     */
    onPickTemplate = async (e, template) => {
        e.preventDefault();
        let {dataScope, location, history, match, noSecurityContext} = this.props;
        this.props.onBrowseAction({dataScope, location, history, match, template, noSecurityContext});
    };

    fullScreenToggle = (e) => {
        if (this.state.fullScreenMode) {
            this.setState({
                modalStyles: {
                    width: "70%",
                    minHeight: "70%",
                    top: `50%`,
                    left: `50%`,
                    transform: `translate(-50%, -50%)`
                }
            });
        } else {
            this.setState({modalStyles: {width: "100%", minHeight: "100%"}});
        }
        this.setState({fullScreenMode: !this.state.fullScreenMode});
    }

    // assign the new template to the id ....
    onPickReusableBlock = async (e, blockInstance) => {
        e.preventDefault();
        let {dataScope, location, history, match, noSecurityContext} = this.props;
        this.props.onBrowseAction({dataScope, location, history, match, blockInstance, noSecurityContext});
        // this.props.onBrowseAction({blockInstance, attachToId: this.state.});
    };

    

    onCreateNewTemplateRequest = () => {
        this.setState({'promptTitle': 'Enter template name'});
        this.setState({'promptOnSubmit': this.onCreateNewTemplate});
        this.setState({'promptOpen': true});
    }

    

    closePrompt = () => {
        this.setState({'promptOpen': false});
    }


    render() {
        const {classes, match} = this.props;
        const {list, fullScreenMode, title} = this.state;

        return <div><DialogStyled open={true} onClose={this.handleClose} fullScreen={fullScreenMode} classes={{root: 'root', paperWidthLg: 'paperWidthLg'}} maxWidth={"lg"}>
            <div>
                <PromptModal onClose={this.closePrompt} callback={this.state.promptOnSubmit}
                             open={this.state.promptOpen}
                             title={this.state.promptTitle}></PromptModal>

                <Toolbar className={classes.MuiToolbar}>
                    <Typography variant="h5" color="inherit">
                        {title}
                    </Typography>
                    <div style={{position: "absolute", right: "12px"}}>
                    <CloseIcon style={{cursor: 'pointer'}}  onClick={this.handleClose} />
                    </div>
                </Toolbar>
                <div style={modalBodyClasses.modalBody}>
                    { !list.templates && <div className={classes.loading}><CircularProgress /></div> }
                    {list && list.templates && <TemplateListingStyled id={match.params.id} list={list.templates} history={this.props.history}
                                                                      onPickTemplate={this.onPickTemplate}></TemplateListingStyled>}
                </div>

            </div>
            {/* <Grid item xs={12}>
                <AppBar position="fixed" style={{
                    bottom: 0,
                    top: 'auto',
                    padding: "12px 0",
                    height: "61px",
                    backgroundColor: "#2B2B2B"
                }}>
                    <Grid container spacing={0}>
                        <Button style={{marginLeft: 20}} variant="contained" color="secondary"
                                onClick={(e) => this.onCreateNewTemplateRequest()}>New Template</Button>
                    </Grid>
                </AppBar>
            </Grid> */}
        </DialogStyled>

        </div>
    }
}

let dialogClasses = {
    root: {
        minWidth: 1280
    },
    paperWidthLg: {
        minWidth: 1280
    }
};

let BrowseModalStyled = withStyles(modalBodyClasses)(BrowseModal);
let DialogStyled = withStyles(dialogClasses)(Dialog);

export default withRouter(withStyles(styles)(BrowseModalStyled));