/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Done from '@material-ui/icons/Done';

import {RadioGroup, Radio, Grid} from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "22px",
        zIndex: 99999,
        maxWidth: "300px"
    },
    label: {
        fontSize: "12px",
        paddingBottom: "8px",
        display: "block"
    },
    gridItem: {
        ".thumb-label":{

        }
    },
    thumbGrid: {
        marginTop: 0
    },
    thumbLabel: {
        marginTop: 12,
        color: "#13364F",
        opacity: "0.6 !important",
        fontSize: "14 !important",
        marginBottom: "0 !important", 
        lineHeight: "17px !important",
        "&.selected": {
            opacity: "1 !important",
            fontWeight: "bold"
        }
    },
    thumbImage: {
        cursor: "pointer",
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(19, 54, 79, 0.4)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderRadius: "4px",
        padding: "16px",
        border: "3px solid transparent",
        "&.selected": {
            border: "3px solid #97D3D5",
        },
        aspectRatio: "1/1",
    },
    selectedTick: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: 25,
        height: 25,
        display: "flex",
        justifyContent: "end",
        alignItems: "end",
        borderRadius: "80px 0px 0px 0px",
        background: "#97D3D5",
        "& svg": {
            "width": 20,
            "height": 20
        }
    },
    backgroundContain: {
        backgroundSize: "contain"
    }
});


class RadioWithThumbs extends React.Component {
    
    state = {
        inputValue: this.props.value,
    };

    handleGroupChange = (e, newValue) => {
        this.props.onChange(newValue);
        this.setState({value: newValue}, () => {});
    }

    componentDidMount = () => {
        let { inputValue } = this.state;
        this.setState({
            value: inputValue
        });
        
    }

    render() {
        const {classes, label, options, onChange, colSize, hideLabel} = this.props;
        const { value } = this.state;

        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel>{ label }</InputLabel>
                    <RadioGroup
                            aria-label="ThumbGroup"
                            name="ThumbGroup"
                            className={classes.thumbGroup}
                            value={value}
                            onChange={this.handleGroupChange}
                        >
                        <Grid container spacing={2} className={classes.thumbGrid}>
                            {   options && options.items && 
                                options.items.map(option => (
                                <Grid key={option.value} item className={classes.gridItem} xs={colSize}>
                                    <label style={{backgroundImage: `url(${option.thumb})`}} className={`${colSize < 6 ? classes.backgroundContain : ""} ${classes.thumbImage}  ${option.value == value ? 'selected' : ''}`}>
                                        <FormControlLabel className="layout-input" style={{display: "none"}} value={option.value} control={<Radio />} label={option.label} />
                                        { option.value == value && <div className={classes.selectedTick}><Done></Done></div>}
                                    </label>
                                    { !hideLabel && <InputLabel className={`${classes.thumbLabel} ${option.value == value ? 'selected' : ''}`}>{ option.label }</InputLabel> }
                                </Grid>
                                ))
                            }
                        </Grid>
                    </RadioGroup>
                </NoSsr>
            </div>
        );
    }
}

RadioWithThumbs.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(RadioWithThumbs);
