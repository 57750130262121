import {withStyles} from '@material-ui/core/styles';
import React, {useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Api from '../Share/api';
import {Form, FormFragment} from "react-forms-processor";
import debounce from "../Share/debounce";
import { Toolbar, Button } from '@material-ui/core';
import {fieldRenderer, createEventedFieldRenderer} from '../Share/formFieldRenderer';
import './EditModel.css';
import TemplateModifyStyled from '../Components/TemplateModify';
import Drawer from '@material-ui/core/Drawer';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Grid from '@material-ui/core/Grid';
import { Card, CardContent } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';

const debug = window.debug('app:rev-ui:EditModalSidebar');

const drawerWidth = 334;

const dialogStyles = {
    drawer: {
        
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerFullScreen: {
        width: "100%",
        flexShrink: 0
    },
    drawerPaper: {
        boxShadow: "none",
        borderRight: 0,
        backgroundColor: 'rgba(19, 54, 79, 0.06)',
        width: drawerWidth
    },
    drawerPaperFullScreen: {
        backgroundColor: '#F4F6F8',
        width: "100%",
    },    
    MuiToolbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        textAlign: "center",
        background: "#13364F",
        paddingLeft: 0,
        paddingRight: 0,
        height: "69px",
        boxShadow: "none"

    },
    MuiAppBar: {
        background: "#13364F"
    },
    panelLockOverlay:{
        display: "none",
        position: "absolute",
        top:0,
        left:0,
        width: "100%",
        height: "100%",
        zIndex: 9999999,
        background: "rgba(0,0,0,.2)"
    },
    headerTitle: {  
        width: '100%',
        paddingLeft: "70px",
        color: '#ffffff',
        fontSize: "15px",
        letterSpacing: 0,
        lineHeight: "100%",
        fontWeight: 900
    },
    headerClose: {
        background: "rgba(255,255,255,0.08)",
        marginRight: "0px",
        height: "100%",
        aspectRatio: "1",
        justifyContent: "center",
        alignItems: "center",
        display: "flex"
    },
    headerIcon: {
        color: "rgba(255,255,255,1)",
        fontSize:  "20px",
        cursor: "pointer" 
    }
    
 
}; 
 
let menuFormClasses = {
    root: {
        padding: "4px"
    },
    optionsWrapper: {
        padding: "4px",
        "& p":{
            opacity: 0.6, 
        }
    },
    menuItem: {
        position: "relative",
        fontFamily: "Mulish",
        background: "#ffffff",
        color: "#13364F",
        fontSize: "14px",
        fontWeight: "700",
        cursor: "pointer",
        padding: "9px 16px",
        "& .MuiSvgIcon-root":{
            "transition": "all 0.2s"
        },
        '&:hover': {
            background: "#13364F",
            color: '#98D4D5',
            opacity: 1,
            "& .MuiSvgIcon-root":{
                "margin-right": "-5px"
            }
        },
        transition: "all 0.2s",
        boxShadow: "0 0 4px 0 rgba(152,212,213,0.36)",
        marginBottom: "8px",
        borderRadius: "8px;",
        "text-transform": "capitalize"
   },
   menuItemName:{
       display: "flex",
       gap: "14px",
       alignItems: "center"
   },
   rightArrow: {
       position: "absolute",
       top: "50%",
       transform: "translateY(-50%)",
       right: "16px",
       color: "#13364F",
       opacity: 0.6,
       "& .MuiSvgIcon-root":{
            fontSize: "12px",
       },
       '&:hover': {
            "& .MuiSvgIcon-root":{
                opacity: 1,
            }
        }
   },
   leftArrow: {
    float: "none",
    position: "absolute",
    color: "#13364F",
    "& .MuiSvgIcon-root":{
         fontSize: "16px",
    }
   },
   backButton: {
    cursor: "pointer",
    marginBottom: 16
   },
   fieldContainer: {
       marginTop: 20,
       background: "#fff",
       borderRadius: "8px"
   },
   fieldCard: {
       position: "relative",
       background: "transparent",
       borderBottom: "1px solid #ECEFF1 !important",
       paddingTop: 16,
       paddingBottom: 16,
       paddingLeft: 16,
       paddingRight: 16
   },
   optionHeader: {
       background: "transparent",
       color: "#13364F",
       padding: "0",
       fontWeight: "900",
       textAlign: "center"
   },
   optionHeaderInner: {
        padding: "8px 0px 0px"
   },
   optionHeaderTitle: {
    fontSize: "15px",
    letterSpacing: 0,
    lineHeight: "26px",
    fontWeight: 900
   },
   optionHeaderSuperTitle: {
    color: "#13364F",
    fontSize: "15px",
    letterSpacing: 0,
    lineHeight: "22px",
    fontWeight: 900
   },
   backButtonWrapper: {
       float: "left",
       padding: "8px 16px 0px 16px",
       color: '#13364F',
       cursor: "pointer",
       transition: "all 200ms"
   },
   templateName: {
        color: "#ffffff",
        fontSize: "14px",
        letterSpacing: 0,
        lineHeight: "22px",
        display:"block",
        fontStyle: "normal"
   },
   fieldGroupTitle: {  
        gap: "14px",
        display: "flex",
        alignItems: "center",
        fontFamily: "Mulish",
        color: "#13364F !important",
        fontSize: "14px",
        fontWeight: "700",
        cursor: "pointer",
        padding: "9px 16px",
        "& .MuiSvgIcon-root":{
            "transition": "all 0.2s"
        },
        "&:after":{
            backgroundImage: "var(--bs-accordion-btn-icon)  !important",
            opacity: 0.7,
            backgroundSize: 16,
            backgroundPosition: "center"
        }
   }
};

function filterEnableDisableFields(schema){
    return schema.filter((field, index) => {
        for(var i=0; i<schema.length;i++){
            let otherField = schema[i];
            if(otherField.enableFields && otherField.enableFields.indexOf(field.id) != -1){
                if ((otherField.type === "checkbox" || otherField.type === "switch") &&  (typeof otherField.value === "undefined" || otherField.value === undefined)) {
                    otherField.value = false; //Convert other types to false
                }
                if (otherField.value == false) {
                   return false;
                }
            }
            if(otherField.disableFields && otherField.disableFields.indexOf(field.id) != -1){
                if ((otherField.type === "checkbox" || otherField.type === "switch") &&  (typeof otherField.value === "undefined" || otherField.value === undefined)) {
                    otherField.value = false; //Convert other types to false
                }
                if (otherField.value == true) {
                   return false;
                }
            }
        }
        return true;
    });
}
 
function filterEnableConditionals(schema) {
    return schema.filter((field, index) => {
        if(field.condition && (field.condition.action === "enable" || field.condition.action === "disable")){
            for(var i=0; i<schema.length;i++){
                let otherField = schema[i];
                if ((otherField.type === "checkbox" || otherField.type === "switch") &&  (typeof otherField.value === "undefined" || otherField.value === undefined)) {
                    otherField.value = false; //Convert other types to false
                }
                if (field.condition && field.condition.id === otherField.id) {
                    if(field.condition.value !== otherField.value && field.condition.action === "enable"){
                        return false;
                    } 
                    if(field.condition.value === otherField.value && field.condition.action === "disable"){
                        return false;
                    } 
                }
            }
            return true;
        }else{
            return true;
        }
    });
}

function renderNavFields(fields, schema, props) {
    let renderer = createEventedFieldRenderer(props.event);
    //Get any objects and just return the ID
    let fieldNames = fields.map((elem) => {
        if (typeof elem === "string") {
          return elem;
        } else {
          return elem.id;
        }
      });
    schema = filterEnableConditionals(schema);
    schema = filterEnableDisableFields(schema);
    //Filter the schema by the fields in this menu
    schema = schema.filter((item)=>{return fieldNames.indexOf(item.id) > -1});
    //Sort the schema by the order of fields in the field UI array
    schema = schema.sort((a, b) => {
        return fieldNames.indexOf(a.name) - fieldNames.indexOf(b.name);
    });
    //First update any fields in the schema that have label overrides in the field UI, then return the field renderer
    return schema
            .map(obj1 => {
                const obj2 = fields.find(obj2 => obj2.id === obj1.id);
                if (obj2) {
                    return { ...obj1, label: obj2.label };
                }
                return obj1;
            })
            .map(schema => <Card className={props.classes.fieldCard} key={schema.id}><CardContent><Form  key={schema.id}
                    renderer={renderer}
                    onChange={value => {
                        props.onSave('prop', value)
                }}>
                <FormFragment className={props.classes.fieldItem} defaultFields={[schema]}></FormFragment>
            </Form>
            </CardContent>
        </Card>
    )
}
 
function renderGroupedNavFields(groups, schema, props) {

    return <div className="accordion accordion-flush" id="groupAccordion">
        { 
            Object.keys(groups).map((key) => {
                let group = groups[key];
                if(group.fields.length != 0){
                    return <div className="accordion-item" key={key}>
                        <div className="accordion-header" id={"heading"+key}>
                            <Typography variant="h4"  
                                className={`accordion-button ${props.classes.fieldGroupTitle} ${group.collapsed ? "collapsed" : ""}`}  
                                data-bs-toggle="collapse" 
                                data-bs-target={"#"+key} 
                                aria-expanded={!group.collapsed}
                                aria-controls={key}>{ group.muiIcon && <span className="material-icons material-icons-outlined">{group.muiIcon}</span> }{ group.title }</Typography>
                        </div>
                        <div id={key} className={`accordion-collapse collapse ${group.collapsed ? "" : "show"}`}  data-bs-parent="#groupAccordion" aria-labelledby={"#heading"+key}>
                            { group.fields && renderNavFields(group.fields, schema, props) }
                        </div>
                    </div>
                }else{
                    return ""
                }
                }
            )
        }
    </div>
}

// show block configurable properties
function BlockProps(props) {
    let [currentMenu, setMenu] = useState(0);
    let [currentTemplateId, setCurrentTemplateId] = useState(0);
    
    useEffect(() => {
        if (currentTemplateId !== props.inspect.instance) {
            setMenu(0);
            setCurrentTemplateId(props.inspect.instance);
        }
    }, [props.inspect])

    useEffect(() => {
        if (props.inspect && props.inspect.schema && props.inspect.schema.length) {
            if (currentMenu === 0) {
                if (props.inspect.config.config.fieldUI && Object.keys(props.inspect.config.config.fieldUI).length > 0) {
                    // "deep" copy the field UI as it's used for other purposes and we want to assign additional properties to it while we navigate the hirearcy.
                    let fieldUi = JSON.parse(JSON.stringify(props.inspect.config.config.fieldUI));
                    setMenu(fieldUi);
                } else {
                    let defaultFieldUi = {
                        "title": "Options",
                        "fields": props.inspect.schema.map((field) => { return field.id})
                    }
                    setMenu(defaultFieldUi);
                }
            }
        }
    });    

    if (props.inspect && props.inspect.schema && props.inspect.schema.length) {
        // remove listers @todo @gregor, i didn't want to filter it out in the inspect in case it's used by other things
        props.inspect.schema = props.inspect.schema.filter((prop) => {
        let propVal = props.inspect.config.config.values[prop.id];
        if (typeof propVal !== 'object' && propVal === "array") {
            return false;
        }
        return true;  
    });

    return <div className={props.classes.root}  data-step-2 >
            
            { /*<InputLabel style={{marginBottom: 12}}>Component > <strong>{ props.inspect.id.templateName }</strong></InputLabel> */}
                { currentMenu.parent && <div className={props.classes.optionHeader }>
                    <div className={props.classes.backButtonWrapper} onClick={() => {setMenu(currentMenu.parent)}}>
                        <div className={props.classes.leftArrow}><ArrowBackIosIcon></ArrowBackIosIcon></div>
                    </div>
                    <div className={props.classes.optionHeaderInner}>
                        <Typography variant="h4" className={props.classes.optionHeaderTitle}>{ currentMenu.title }</Typography>
                    </div>
                </div>
                }
                { !currentMenu.parent && <div className={props.classes.optionHeader }>
                    { !props.isRoot && <div className={props.classes.backButtonWrapper} onClick={props.onGoBack}>
                        <div className={props.classes.leftArrow}><ArrowBackIosIcon></ArrowBackIosIcon></div>
                    </div>
                    }
                </div>
                }      
                <div className={props.classes.optionsWrapper}>
                    { currentMenu.subTitle && <p><strong>{ currentMenu.subTitle}</strong></p> }
                    { currentMenu.description && <p>{ currentMenu.description}</p> }
                    { currentMenu.nav &&
                        Object.keys(currentMenu.nav).map((key) => {
                            let menu = currentMenu.nav[key];
                            return <div className={props.classes.menuItem} key={key} onClick={() => {menu.parent = currentMenu; setMenu(menu)}}><span className={props.classes.menuItemName}><span className="material-icons material-icons-outlined">{menu.muiIcon}</span>{menu.title}</span><div className={props.classes.rightArrow}><ArrowForwardIosIcon></ArrowForwardIosIcon></div></div>
                        })
                    }
                    { currentMenu.fields && currentMenu.fields.length > 0 && currentMenu.fields[0] != ""  && <div className={props.classes.fieldContainer}>
                        {
                            !props.hideOptions && currentMenu.fields && renderNavFields(currentMenu.fields, props.inspect.schema, props)
                        }
                    </div> 
                    }

                    {  currentMenu.fieldGroups &&  <div className={props.classes.fieldContainer}>
                            { !props.hideOptions && currentMenu.fieldGroups && renderGroupedNavFields(currentMenu.fieldGroups, props.inspect.schema, props) } 
                        </div> 
                    }
            </div>
        </div>
    }
    return <div></div>
}

let BlockPropsStyled = withStyles(menuFormClasses)(BlockProps);

class EditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            templateName: null,
            editInstance: null,
            inspect: {},
            templateInspect: {},
            fullScreenMode: false,
            width: drawerWidth,
            adminShow: false,
            hideOptions: false
        };
    }


    componentDidMount() {
        this.setState({editInstance: this.props.id.instance, inspect: this.getInstance(this.props.id)}, () => {
            // debounce autosave
            this.autoSave = debounce(this.autoSave, 500);
            this.onSaveTemplate = debounce(this.onSaveTemplate, 500);
            
            document.title = this.displayName || 'Edit';
         //   console.log(2)
            this.runInspect();
            this.props.onSidebarOpening(this.state.width);
        });

        window.addEventListener('message', event => {
            const m = event.data;
            if (m.action === 'preview-loaded') {
                this.setState({loading: false});
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.getInstance(this.props.id) !== this.getInstance(prevProps.id)) {
            this.setState({editInstance: this.getInstance(this.props.id) }, () => {
                console.log(2)
                console.log("this.props.id",this.props.id)
                console.log("prevProps",prevProps)
                console.log("getInstance",this.getInstance(this.props.id))
                this.runInspect();
            })
        } 
    }   
   
 
    getInstance(id) {  
        if (id.instance && !id.prop) {
            return id.instance;
        } else if (id.templateInstance) {
            return id.templateInstance;
        }  
    }
 
    runInspect() {  
        if (this.state.editInstance) {
            if (this.state.templateName === null) { 
                (new Api()).templateInspect(this.state.editInstance, true, this.props.dataScope.ref).then((data) => {
                    // inspect the selected template only
                   console.log("data",data)
                    this.setState({inspect: data.instanceInspect[0].inspect[0]});
                    this.setState({templateInspect: data.templatesSchemas[0]});
                });
            } else {
                (new Api()).templateInspect(null, true, this.props.dataScope.ref, this.state.templateName).then((data) => {
                    // inspect the selected template only
                    this.setState({inspect: data.instanceInspect[0].inspect[0]});
                    this.setState({templateInspect: data.templatesSchemas[0]});
                    this.setState({value: 2}); 
                });
            }
        } 
    }      
    
    hasDeepProperty = (obj, prop) => {
        if (obj.hasOwnProperty(prop)) {
            return true;
        }
        return Object.values(obj).some(val => {
            if (typeof val === 'object') {
            return this.hasDeepProperty(val, prop);
            }
            return false;
        });
    }

    handleChange = (event, value) => {
        if (value === 2) {
            //this.setState({fullScreenMode:true})
        }
        this.setState({value});
    };

    handleClose = () => {
        this.props.onSidebarClose();
        this.props.history.push('/');
    };

    onBranchBlock = (newBranchName) => {
        debug({onBranchBlock: newBranchName});
        let api = new Api();

        let originalTemplateName = this.state.inspect.id.templateName;
        let newTemplateName;
        if (newBranchName) {
            newTemplateName = originalTemplateName + '/' + newBranchName;
        }

        api.templateClone(originalTemplateName, newTemplateName, this.props.dataScope.ref)
            .then(response => {
                if (response.templateName) {
                    // assign the new branched template to the inspected instance
                    // let instance = this.id.
                    let noSecurityContext = this.props.dataScope.type === 'sandbox' ? 0 : this.props.noSecurityContext;
                    return api.templateAssign(this.state.editInstance, this.state.editInstance, response.templateName, this.props.dataScope.ref, noSecurityContext);
                }
            })
            .then(response => { 
                this.runInspect();
            });
    };     

 
    addToRows(rows,fieldData, updateValue){
        let {instance, scope} = this.state.inspect;
        let t = Date.now();
        fieldData.value = updateValue; 
        fieldData.t = t;
        fieldData.instance = instance;
        fieldData.scope = scope;
        fieldData.name = fieldData.id;
        rows.push(fieldData);
        return rows;
    }
 
    getFieldSchema(field, schema){
        return schema.find(obj => obj.id === field.name);
    }

    applySetFieldOptions = (rows) => {
        try{
            let schema = this.state.inspect.schema;
            let updateCount = 0;
            let activeField = rows[0];
            let activeFieldSchema = this.getFieldSchema(rows[0], schema);
            activeFieldSchema.value = activeField.value; 
            if(activeFieldSchema.isControl){
                let fieldOptions = Array.isArray(activeFieldSchema.options) ? activeFieldSchema.options[0] : activeFieldSchema.options;
                let activeFieldOptions = fieldOptions.items.find(item => item.value === activeField.value);
                let fieldsToUpdate = activeFieldOptions.setFields;
                for(var i=0;i<schema.length;i++){
                    let updateValue = fieldsToUpdate[schema[i].name];
                    if(fieldsToUpdate.hasOwnProperty(schema[i].name)){
                        rows = this.addToRows(rows, schema[i], updateValue)
                    }
                    updateCount++;
                   
                }
            }
            if(activeFieldSchema.controlId){
                for(var j=0;j<schema.length;j++){
                    if(activeFieldSchema.controlId === schema[j].name){
                        rows = this.addToRows(rows, schema[j], "custom")
                        updateCount++;
                    }
                }
            }
            if(updateCount){
                this.setState({hideOptions: true})
                this.setState({hideOptions: false})
            }
        }catch(e){
            // Errors here
        }
        return rows;
    }


    /**
     * - Debounce
     * - Save to sandbox
     * @param type
     * @param value 
     */
    autoSave = (type, value, context) => {
        this.setState({loading: true});
        let rows = [];
        let prefix = type === 'style' ? '_style_' : '';
        let {instance, scope} = this.state.inspect;
        let t = Date.now();
        for (let [key, val] of Object.entries(value)) {
            rows.push({
                name: prefix + key, value: val, instance, scope, t
            });
        }
        //Field values to be set by other fields
        rows = this.applySetFieldOptions(rows,t, instance, scope);

        // in sandbox mode, we always use security context 
        let noSecurityContext = this.props.dataScope.type === 'sandbox' ? 0 : this.props.noSecurityContext;

        (new Api()).save(rows, this.props.dataScope.ref, true, noSecurityContext ).then((saved) => {
            // notify other components
            window.postMessage({action: 'saved'}, '*');
            for (let [key, val] of Object.entries(value)) {
                let i = this.state.inspect.schema.findIndex((s)=>{ return (s.name === key)});
                if (i > -1) {
                    this.setState((prevState) => {
                        prevState.inspect.schema[i].value = val;
                        return prevState;
                    })
                } 
            }
            this.runInspect();
        });

    };


    // redirect to browse a different template
    onChangeTemplate = (e) => {
        // notify other components
        //window.postMessage({action:''}, '*');
        debug('yo-ho', this.props.history);
    };

    prettyTemplateName = (templateName) => {
        return templateName.replace('_', ' ');
    };

    fullScreenToggle = (e) => {
        this.setState({fullScreenMode: !this.state.fullScreenMode});
    }

    onSaveTemplate = (type, data) => {
        debug({onSaveTemplate: data});

        let row = {};
        // construct the template config from our data ...
        let values;
        let fieldUI;
        try {
            if (data.json) {
                values = JSON.parse(data.json);
            }
        } catch (e) {
            // invalid json
            alert('Fix fieldUI json errors before saving');
            return;
        }

        try {
            if (data.fieldUI) {
                fieldUI = JSON.parse(data.fieldUI);
            }
        } catch (e) {
            // invalid json
            alert('Fix field json errors before saving');
            return;
        }

        // row is a template
        row = {
            name: this.state.inspect.id.templateName,
            value: JSON.stringify({
                template: data.twig,
                config: {
                    styl: data.stylus || null,
                    script: data.script || null,
                    muiIcon: data.muiIcon || null,
                    title: data.title || null,
                    description: data.description || null,
                    tags: data.tags || null,
                    archived: data.archived || false,
                    async: data.async || false,
                    values: values,
                    fieldUI: fieldUI || {}
                }
            }),
            t: Date.now()
        };

        // save the template
        (new Api()).save([row], this.props.dataScope.ref).then(() => {
            debug('saved template');
            // request rerender
            window.postMessage({action: 'saved'}, '*');

            // regenerate inspect
            this.runInspect();
        });
    };

    close = () => {
        window.parent.postMessage({action: 'close-revstudio'}, '*');
    };

    handleAdminClose = () => {
        this.setState({adminShow: false});
        this.setState({fullScreenMode: false});
    }


    render() {
        const {classes, id, mode, isRoot, onGoBack, event} = this.props;
        const {inspect, fullScreenMode, adminShow, hideOptions, templateInspect, loading} = this.state;

        return <Drawer 
                    className={this.state.fullScreenMode ? classes.drawerFullScreen : classes.drawer }   
                    open={true} 
                    onClose={this.handleClose} 
                    variant="permanent" 
                    anchor="left"
                    classes={{
                        paper: this.state.fullScreenMode ? classes.drawerPaperFullScreen :  classes.drawerPaper
                    }}
        >
            <div> 
                <AppBar position="sticky" elevation={0}>
                    <Toolbar className={classes.MuiToolbar}>
                        <Typography variant="h5" color="inherit" className={classes.headerTitle} data-step-1>
                            Studio
                        </Typography>
                        <div className={classes.headerClose}>
                            <CloseIcon onClick={this.close} className={classes.headerIcon} />
                            { mode !== "SIMPLE" &&
                                <div style={{background: "rgba(255,255,255,1)"}}>
                                    <Button variant="outlined" color="primary"  onClick={this.fullScreenToggle} style={{cursor: 'pointer'}}>
                                        {!fullScreenMode &&
                                        <i title="Enter fullscreen mode" className="material-icons">fullscreen</i>}
                                        {fullScreenMode &&
                                        <i title="Exit fullscreen mode" className="material-icons">fullscreen_exit</i>}
                                    </Button>
                                    <Button variant="outlined" color="primary"  onClick={() => {this.setState({adminShow: !this.state.adminShow, fullScreenMode: true})}} style={{cursor: 'pointer'}}><i
                                        className="material-icons">settings</i></Button>

                                </div>
                            }
                        </div>
                        
                    </Toolbar>
                </AppBar>
                { !loading && <div style={{height:4}}></div>}
                { loading && <LinearProgress></LinearProgress> }
                { loading && <div className={classes.panelLockOverlay}></div>}
                {! adminShow && 
                <BlockPropsStyled 
                    event={event} 
                    onGoBack={onGoBack} 
                    isRoot={isRoot} 
                    key={id} 
                    id={id} 
                    inspect={inspect} 
                    onSave={this.autoSave}
                    hideOptions={hideOptions}>
                </BlockPropsStyled>}
                {adminShow &&
                <TemplateModifyStyled 
                    fullScreenMode={fullScreenMode} 
                    id={id} 
                    inspect={inspect} 
                    templateInspect={templateInspect} 
                    templateName={this.state.inspect.id.templateName}
                    onBranchBlock={this.onBranchBlock} 
                    onClose={this.handleAdminClose} 
                    onFullScreenToggle={this.fullScreenToggle}
                    onSave={this.onSaveTemplate}>
                </TemplateModifyStyled>}
            </div>
        </Drawer>
    }
}


export default withStyles(dialogStyles)(EditModal);