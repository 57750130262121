let version = 1;

let implementations = {};

implementations.encoder_1 = function encoder_1(props) {
    return `${props.name.replace(/_/g, '-')}_v1_${props.width}_${props.height}.${props.ext}`;
};

implementations.decoder_1 = function decoder_1(fullName) {
    let [name, ext] = fullName.split('.');
    let parts = (name || '').split('_');
    if (parts.length > 0) {
        return {
            name: parts[0],
            width: parts[2],
            height: parts[3],
            version: '1',
            ext: ext || ''
        };
    }

    return {name};
};

function mediaNameEncode(props) {
    return implementations['encoder_'+version](props);
}

function mediaNameDecode(name) {
    let m = name.match(/_v(\d)_/);
    if (m) {
        let decoderFun = 'decoder_'+m[1];
        return implementations[decoderFun] ? implementations[decoderFun](name) : {name};
    } else {
        return {filename: name};
    }
}

function cleanupStr(str) {
    str = str.toLowerCase();
    // allow only certain characters in the name
    return str.replace(/[^a-zA-Z0-9()-]+/g, '')
}

function parseNameExtension(filename) {
    const parts = filename.split('.');
    const ext = parts.pop();
    const name = parts.join('.');
    return { name, ext };
}

let mediaNameHelper = {
    cleanupStr,
    parseNameExtension,
    mediaNameEncode: mediaNameEncode,
    mediaNameDecode: mediaNameDecode
};

export default mediaNameHelper;