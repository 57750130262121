import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import Creatable from 'react-select/lib/Creatable'

const styles = {
    root: {
        marginBottom: "22px"
    },

    label: {
        fontSize: "12px",
        paddingBottom: "8px",
        marginTop: "12px",
        display: "block"
    },
};

class TagField extends React.Component {

    state = {
        options: []
    }

    componentDidMount() {
        if (this.props.value) {
            if (Array.isArray( this.props.value)) {
                this.setState({
                    "options": this.props.value.map((item) => {
                        return {label: item, value: item}
                    })
                });
            }
        }
    }

    onChange = (val) => {
        this.setState({"options": val});
        this.props.onChange(val);
    }

    render() {

        const {classes, label} = this.props;
        const {options} = this.state;

        return (
            <div className={classes.root}>
                <InputLabel className={classes.label}>{label}</InputLabel>
                <Creatable  classNamePrefix={"dropdown"} onChange={this.onChange} value={options} isMulti/>
            </div>
        );
    }
}

TagField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TagField);
