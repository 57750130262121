/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import FontSelector from './FontSelector';
import SizeSlider from './SizeSlider';
import Grid from '@material-ui/core/Grid';
import ColourPicker from './ColourPicker';
import Size from './Size';

const Color = require('color');

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    fontButton: {
        padding: "4px 0",
        margin: 0,
        width: 25
    },
    fontButtonSelected: {
        padding: "4px 0",
        margin: 0,
        width: 25,
        background: '#333',
        color: '#FFF',
        '&:hover': {
            background: '#333',
            color: '#FFF'
        }
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },

});


class BasicFontSelector extends React.Component {
    
    
    state = {
        inputValue: this.props.value,
        styles: {bold:false, italic: false},
        size: 11,
        lineHeight: 12,
        letterSpace: 1,
        family: "Roboto",
        weight: "100",
        font: "",
        fontSelectValue: null,
        color: "#333"
    };

    toggleStyle = (property) => {
        let styles = this.state.styles;
        styles[property] = !styles[property];
        this.setState({styles});
    } 

    buildFont = () => {
        let {size, lineHeight, family, weight, importFont, color, letterSpace} = this.state;
        let font = `${weight} ${size} ${family},serif`;
        this.setState({font});

        let value = {
            "set": font,
            "styl": importFont,
            "data": JSON.stringify({size, lineHeight, family, weight, importFont, color, letterSpace}),
            "variants": [weight],
            "quoteless":  true,
            "variables": {lineHeight: lineHeight, family, size: size, weight, color, letterSpace}
        };


        this.showPreview();


        this.setState({fontSelectValue: {set:  family, styl: importFont, variants: [weight]} });

        this.props.onChange(value);
    }

    showPreview =  () => {
        let {importFont} = this.state;
        var fontStyle = document.createElement('style');
        fontStyle.id = "styleFontFaceContainer"
        fontStyle.appendChild(document.createTextNode(importFont));
        document.body.appendChild(fontStyle);
    }

    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {
   
            let jsonValue = JSON.parse(value);
            if (jsonValue !== null) {
                let jsonData = JSON.parse(jsonValue.data);
                this.setState({fontSelectValue: JSON.stringify({set: jsonData.family, styl: jsonData.importFont, variants: [jsonData.weight]}) });
                this.setState({size: jsonData.size});
                this.setState({lineHeight: jsonData.lineHeight});
                this.setState({letterSpace: jsonData.letterSpace});
                this.setState({family: jsonData.family});
                this.setState({weight: jsonData.weight});
                this.setState({importFont: jsonData.importFont});
                this.setState({color: jsonData.color || '#333'});
                setTimeout(() => {
                    this.showPreview();
                },1);
                
            }
        }
        
    }

    toggleColourPicker = () => {
        this.setState({showColourPicker: !this.state.showColourPicker});
    }
    
    hideColourPicker = (e) => {
        e.stopPropagation();
        this.setState({showColourPicker: false});
    }


    render() {
        const { classes, label, id } = this.props;
        const { size, fontSelectValue, color ,lineHeight, letterSpace} = this.state;
        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="fontContainer" ></div>
                   
                        <InputLabel className={classes.label}>{label}</InputLabel>
                        <Grid container spacing={2}>
                            <Grid item xs={9}>
                                <FontSelector
                                showTitle={false}
                                key={id}
                                label={label}
                                value={fontSelectValue}
                                onChange={(val) => {
                                    if (val) {
                                        let apiUrl = [];
                                        let variants = [];
                                        apiUrl.push('https://fonts.googleapis.com/css?family=');
                                        apiUrl.push(val.familyValue.data.family.replace(/ /g, '+'));
                                        if (val.selectedVariant) {
                                            apiUrl.push(':');
                                            apiUrl.push(val.selectedVariant);
                                            variants.push(val.selectedVariant);
                                        } else {

                                        }
                                        let url = apiUrl.join('');

                                        this.setState({family: val.familyValue.data.family}, () => {this.buildFont()});
                                        this.setState({weight: variants[0]}, () => {this.buildFont()});
                                        this.setState({importFont:  `@import url(${url.replace(/\+/g, "\\+")})`}, () => {this.buildFont()});
                                    }
                                }}
                                />
                            </Grid>

                         </Grid>                       
                </NoSsr>
            </div>
        );
    }
    
}

function isColor(val) {
    let success = false;
    try {
        Color(val);
        success = true;
    } catch (e) {
    }
    return success;
}
 
BasicFontSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(BasicFontSelector);
