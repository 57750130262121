import React from "react";
import ColorPicker from '../../Components/Inputs/Styles/ColourPicker';
import HtmlEditor from "../../Components/HtmlEditor";
import GridEditor from "../../Components/GridEditor";
import MediaPicker from "../../Components/MediaPicker";
import TagField from "../../Components/TagField";
import DividerField from "../../Components/DividerField";
import DividerTitleField from "../../Components/DividerTitleField";
import FontSelector from "../../Components/Inputs/Styles/FontSelector";
import FullFontSelector from "../../Components/Inputs/Styles/FullFontSelector";
import Size from "../../Components/Inputs/Styles/Size";
import BoxModel from "../../Components/Inputs/Styles/BoxModel";
import Background from "../../Components/Inputs/Styles/Background";
import ButtonComp from "../../Components/Inputs/Styles/ButtonComp";
import SpacingSelect from "../../Components/Inputs/Styles/SpacingSelect";
import HeaderLayout from "../../Components/Inputs/Styles/HeaderLayout";
import FeatureLayout from "../../Components/Inputs/Styles/FeatureLayout";
import RoundelLayout from "../../Components/Inputs/Styles/RoundelLayout";
import BorderStyle from "../../Components/Inputs/Styles/BorderStyle";
import AdvancedImage from "../../Components/Inputs/Styles/AdvancedImage";
import LargeTextField from "../../Components/Inputs/Styles/LargeTextField";
import WidgetCreator from "../../Components/Inputs/Styles/WidgetCreator";
import ComplexLabel from "../../Components/Inputs/Styles/ComplexLabel";
import BasicFontSelector from "../../Components/Inputs/Styles/BasicFontSelector";
import FontMultiWeightSelector from "../../Components/Inputs/Styles/FontMultiWeightSelector";
import FullFontStyles from "../../Components/Inputs/Styles/FullFontStyles";
import BasicFontStyles from "../../Components/Inputs/Styles/BasicFontStyles";
import SlideValue from  "../../Components/Inputs/Styles/SlideValue";
import FontWithCustomSelector from "../../Components/Inputs/Styles/FontWithCustomSelector";
import NumberField from "../../Components/Inputs/Styles/NumberField";
import WidthHeightField from "../../Components/Inputs/Styles/WidthHeightField";
import CheckboxStyle from "../../Components/Inputs/Styles/CheckboxStyle";
import RadioWithThumbs from  "../../Components/Inputs/Styles/RadioWithThumbs";
import TextOnlyField from  "../../Components/Inputs/Styles/TextOnlyField";
import CurrencySelect from "../../Components/Inputs/Styles/CurrencySelect";
import {Slider}  from '@material-ui/core'; 

const Color = require('color');

let renderTagField = (field, events) => {
    let {id, label, value,} = field;
    let {onChange} = events;

    return <TagField label={label} value={value} key={id} id={id}
    onChange={(val) => {
        onChange(id, val.map((item) => {
            return item.value
        }));
    }}/>

}
let renderMediaField = (field, events, _, event) => {
    let {id, label, value, saveRow = null} = field;
    let {onChange} = events;
    return <MediaPicker event={event} saveRow={saveRow} label={label} value={value} key={id} id={id}
    onChange={(val) => {
        onChange(id, val);
    }}/>    
}
let renderGridField = (field, events) => {
    let {id, label, value} = field;
    let {onChange} = events;

    return <GridEditor label={label} value={value} key={id}
    onChange={(grid) => {
        onChange(id, JSON.stringify({columns: grid.columns, data: grid.data}))
    }}/>    
}
let renderHtmlField = (field, events) => {
    let {id, label, value, options} = field;
    let {onChange} = events;
    let watchValueUpdate = (options || {}).watchValueUpdate;
    return <HtmlEditor
        key={id}
        value={value}
        label={label}
        watchValueUpdate={watchValueUpdate}
        onChange={(val) => onChange(id, val)}
    />;    
}
let renderDividerField = (field, events) => {
    let {id} = field;
    return <DividerField  key={id}/>
}

let renderDividerTitleField = (field, events) => {
    let {id, label, value} = field;
    return <DividerTitleField  key={id} label={label}/>
}

let renderColorField = (field, events) => {
    
    let {id, label, value, description} = field;
    let {onChange} = events;

    function isColor(val) {
        let success = false;
        try {
            Color(val);
            success = true;
        } catch (e) {
        }
        return success;
    }
    
    return <ColorPicker
                key={id}
                label={label}
                name='color'
                defaultValue={value || ''}
                placeholder='Pick a color'
                description={description}
                // value={this.state.color} - for controlled component
                onChange={(val, onFieldChange) => {
                    onChange(id, val);
                    
                }}
            />;
}


let renderFontField = (field, events) => {
    let {id, label, value} = field;
    let {onChange} = events;
    return <div key={id}>
        <FullFontSelector
            key={id}
            label={label}
            value={value}
            onChange={(val) => {
                onChange(id, JSON.stringify(val));
            }}
    />
    </div>
}

let renderStyleSizeField = (field, events) => {
    let {id, label, value, initialValue, units, icon} = field;
    let {onChange} = events;    
    return <div key={id}>
        <Size key={id} units={units} label={label} initialValue={initialValue} value={value} icon={icon} onChange={(val) => {
             onChange(id, val);    
        }} />
    </div>
}

let renderBoxModelField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
       <BoxModel label={label} value={value} onChange={(val) => {
                onChange(id, JSON.stringify(val));
            }}
        />
    </div>
}

let renderBackgroundField = (field, events, _, event) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <Background event={event} label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}

let renderButtonCompField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <ButtonComp label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}

let renderSliderField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <Slider label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}


let renderSpacingSelectField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <SpacingSelect label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}

let renderHeaderLayoutField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <HeaderLayout label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}

let renderFeatureLayouttField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <FeatureLayout label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}

let renderRoundelLayoutField = (field, events, _, event) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <RoundelLayout event={event} label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
} 


let renderBorderStyleField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <BorderStyle label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
} 

let renderAdvancedImageField = (field, events, _, event) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <AdvancedImage event={event} label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
} 

let renderLargeTextFieldField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <LargeTextField label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
} 

let renderComplexLabelField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <ComplexLabel label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
} 

let renderWidgetCreator = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <WidgetCreator label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>

} 


let renderBasicFontSelector = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <BasicFontSelector label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>

}


let renderFontMultiWeightSelector = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <FontMultiWeightSelector label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>

}

let renderFullFontStyles = (field, events) => {
    let {id, label, value, families, styles, description} = field;
    let {onChange} = events;    
    return <div key={id}>
        <FullFontStyles  families={families} styles={styles}  label={label} value={value} description={description} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>

}

let renderBasicFontStyles = (field, events) => {
    let {id, label, value} = field;

    let {onChange} = events;    
    return <div key={id}>
        <BasicFontStyles label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>

}
let renderSlideValue = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <SlideValue label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>

}

let renderFontWithCustomSelector = (field, events) => {
    let {id, label, value, description} = field;
    let {onChange} = events;    
    return <div key={id}>
        <FontWithCustomSelector label={label} value={value} id={id} description={description} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>

}

let renderNumberField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <NumberField label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}
let renderWidthHeightField = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <WidthHeightField label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}

let renderCheckboxStyle = (field, events) => {
    let {id, label, value } = field;
    let {onChange} = events;    
    return <div key={id}>
        <CheckboxStyle label={label} value={value} id={id} onChange={(val) => {
             onChange(id, JSON.stringify(val));    
        }} />
    </div>
}

let renderRadioWithThumbs = (field, events) => {
    let {id, label, value ,options, colSize, hideLabel} = field;
    colSize = colSize ? colSize : 6;
    let {onChange} = events;    
    return <div key={id}>
        <RadioWithThumbs options={options} label={label} hideLabel={hideLabel} colSize={colSize} value={value} id={id} onChange={(val) => {
             onChange(id, val);    
        }} />
    </div>
}

let renderTextOnlyField = (field, events) => {
    let {id, label, value ,options, colSize, hideLabel} = field;
    colSize = colSize ? colSize : 6;
    let {onChange} = events;    
    return <div key={id}>
        <TextOnlyField options={options} label={label} value={value} id={id} onChange={(val) => {
             onChange(id, val);    
        }} />
    </div>
}

let renderCurrencySelect = (field, events) => {
    let {id, label, value ,options, colSize, hideLabel} = field;
    colSize = colSize ? colSize : 6;
    let {onChange} = events;    
    return <div key={id}>
        <CurrencySelect options={options} label={label} value={value} id={id} onChange={(val) => {
             onChange(id, val);    
        }} />
    </div>
}


export {renderColorField, renderDividerField, renderDividerTitleField, renderGridField, renderHtmlField, renderMediaField, renderTagField, 
    renderFontField, renderStyleSizeField, renderBoxModelField, renderBackgroundField, 
    renderButtonCompField,renderSliderField,renderSpacingSelectField,renderHeaderLayoutField,
    renderFeatureLayouttField, renderRoundelLayoutField,renderBorderStyleField,renderAdvancedImageField,
    renderLargeTextFieldField, renderComplexLabelField, renderWidgetCreator,
    renderFontMultiWeightSelector, renderFullFontStyles, renderBasicFontStyles, renderSlideValue,
    renderFontWithCustomSelector, renderNumberField, renderWidthHeightField, renderCheckboxStyle, renderRadioWithThumbs, renderTextOnlyField,
    renderCurrencySelect, renderBasicFontSelector};
