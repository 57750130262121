/* Height Adjust  */

function getDocHeight(doc) {
    doc = doc || document;
    let body = doc.body, html = doc.documentElement;
    let height = Math.max(body.scrollHeight, body.offsetHeight,
        html.clientHeight, html.scrollHeight, html.offsetHeight);
    return height;
}

function sendDocHeightMsg(e) {
    let ht = getDocHeight();
    window.parent.postMessage({action: 'height-adjust', 'docHeight': ht}, '*');
}

let iFrameAdjust = {
    start: function() {
        if (window.addEventListener) {
            window.addEventListener('load', sendDocHeightMsg, false);
        } else if (window.attachEvent) { // ie8
            window.attachEvent('onload', sendDocHeightMsg);
        }
        // observer.observe(document.querySelector('html'), {attributes: true, childList: true, subtree: true});
        // onElementHeightChange(document.body, function () {
        //     sendDocHeightMsg();
        // });

    }
};

export default iFrameAdjust;
