// @flow
import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FieldWrapper } from "react-forms-processor";
import type { Field, FieldDef } from "react-forms-processor";

class MaterialCheckbox extends React.Component<Field> {
  render() {
    const {
      disabled,
      id,
      name,
      onFieldChange,
      value,
      label,
      description
    } = this.props;
    const stringValue: string | void = value ? value.toString() : undefined;
    
    return (
      //$FlowFixMe
      <>
      <div style={{display: "flex", alignItems: "center", flexDirection: "row", margin: 0}}>
        <FormControlLabel
          style={{marginRight: 0}}
          control={
            <Checkbox
              key={id}
              name={name}
              disabled={disabled}
              /*isInvalid={!isValid}*/
              value={stringValue}
              checked={value}
              onChange={evt => onFieldChange(id, evt.target.checked)}
              style={{marginRight: 0}}
            />
          }
        />
        <div className="tooltip-contain"> 
        <span className="label-with-tooltip" style={{margin:0}}>{label}</span>
        { description && <span data-text={description} className="i-tooltip">i</span> }
        </div>
      </div>
      </>
    );
  }
}

export default (props: FieldDef) => (
  <FieldWrapper {...props}>
    {/* $FlowFixMe */}
    <MaterialCheckbox />
  </FieldWrapper>
);
