/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import AsyncSelect from 'react-select/lib/Async';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';
import TextField from './TextField';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "0px",
    },
    label: {
        fontSize: "14px  !important",
        marginBottom: "16px !important",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },
    minorLabelM0: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "0px  !important"
    },
    inlineLabelField: {
        display: "flex",
        "flex-direction": "row",
        "justify-content": "left",
        "align-items": "center"
    },
    disabledSelect: {
        opacity: "0.4"
    },
    flexField: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "row",
        "margin-bottom": "8px !important",

    },
    inlineLabel: {
        "margin-bottom": "0px !important",
        "font-family": "Mulish",
        "font-size": "13px !important",
        "font-style": "italic",
        "font-weight": "400",
        "line-height": "13px",
        "letter-spacing": "0px",
        "text-align": "left"

    },
    padAdjust: {
        padding: "0px 8px 0px 0px !important"
    }
});


function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

class FontWithCustomSelector extends React.Component {
    state = {
        inputValue: this.props.value,
        suggestions: [],
        fonts: [],
        variantOptions: [],
        familyValue: null,
        selectedLightVariant: null,
        selectedRegVariant: null,
        selectedBoldVariant: null,
        fontSelectValue: null,
        useCustom: null,
        customNormalUrl: "",
        customLightUrl: "",
        customBoldUrl: ""
    };

    queryFonts(value) {
        return this.state.fonts.filter(item => {
            return item.family.toLowerCase().includes(value.toLowerCase())
        }).map(suggestion => ({
            value: suggestion.family,
            label: suggestion.family,
            data: suggestion
        }));
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({inputValue});
        return inputValue;
    };

    onFontFamilyChange = (val) => {
        var variantOptions = val.data.variants;
        for(var i=0;i<variantOptions.length;i++){
            if(variantOptions[i] == "regular"){
                variantOptions[i] = "400";
            }
            if(variantOptions[i].indexOf("italic") != -1){
                variantOptions.splice(i,1);
                i = i - 1;
            }
        }
        this.setState({
            "variantOptions": variantOptions.map(variant => ({
                value: variant,
                label: variant
            }))
        }, function() {
            this.setState({selectedLightVariant: null}, function () {
                this.setState({familyValue: val}, () => {
                    this.onAnyChange();
                });
            });
            this.setState({selectedRegVariant: null}, function () {
                this.setState({familyValue: val}, () => {
                    this.onAnyChange();
                });
            });
            this.setState({selectedBoldVariant: null}, function () {
                this.setState({familyValue: val}, () => {
                    this.onAnyChange();
                });
            });
        });

      
    } 

    onRegRegVariantChange = (val) => {
        this.setState({selectedRegVariant: val}, function () {
            this.onAnyChange();
        });
     }

    onLightVariantChange = (val) => {
        this.setState({selectedLightVariant: val}, function () {
            this.onAnyChange(); 
        });
    }
    
    onBoldVariantChange = (val) => {
        this.setState({selectedBoldVariant: val}, function () {
            this.onAnyChange();
        });
    }
        
    onUseCustomChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({useCustom: value}, function () {
            this.onAnyChange();
        });
     }
    onCustomNormalUrlChange = (e) => {
 
        this.setState({customNormalUrl: e.target.value}, function () {
            this.onAnyChange();
        });
     }

    onCustomLightUrlChange = (e) => {
        this.setState({customLightUrl: e.target.value}, function () {
            this.onAnyChange(); 
        });
    }
    
    onCustomBoldUrlChange = (e) => {
        this.setState({customBoldUrl: e.target.value}, function () {
            this.onAnyChange();
        });
    }

    onAnyChange = () => {
        let payload = {
            familyValue: this.state.familyValue, 
            selectedLightVariant: this.state.selectedLightVariant ? this.state.selectedLightVariant : "", 
            selectedRegVariant: this.state.selectedRegVariant ? this.state.selectedRegVariant : "", 
            selectedBoldVariant: this.state.selectedBoldVariant ? this.state.selectedBoldVariant : "", 
            useCustom:  this.state.useCustom ? this.state.useCustom.value : "", 
            customNormalUrl: this.state.customNormalUrl ? this.state.customNormalUrl : "", 
            customLightUrl: this.state.customLightUrl ? this.state.customLightUrl : "", 
            customBoldUrl: this.state.customBoldUrl ? this.state.customBoldUrl : ""
        };
        this.props.onChange(payload);
        this.buildFont();
    }
    

    buildFont = () => {
        let {familyValue, selectedLightVariant, selectedRegVariant, selectedBoldVariant,  useCustom,
            customNormalUrl, customLightUrl, customBoldUrl} = this.state;
        let apiUrl = [];
        let styl = "";
        let family = familyValue ? familyValue.value : "";
        let light = selectedLightVariant ? selectedLightVariant.value : "";
        let normal = selectedRegVariant ? selectedRegVariant.value : "";
        let bold = selectedBoldVariant ? selectedBoldVariant.value : "";
        useCustom = useCustom ? useCustom : "";
        customNormalUrl = customNormalUrl ?  '"'+customNormalUrl+'"' : "";
        customLightUrl = customLightUrl ? '"'+customLightUrl+'"' : "";
        customBoldUrl = customBoldUrl ? '"'+customBoldUrl+'"' : "";
 
        apiUrl.push('https://fonts.googleapis.com/css?family=');
        apiUrl.push(family.replace(/ /g, '+'));
        if (light) {
            apiUrl.push(':');
            apiUrl.push("0,"+light);
        } 
        if (normal) {
            apiUrl.push(':');
            apiUrl.push("0,"+normal);
        } 
        if (bold) {
            apiUrl.push(':');
            apiUrl.push("0,"+bold);
        } 

        if(!useCustom){
            styl = family ? `@import url(${apiUrl.join('').replace(/\+/g, "\\+")})` : "";
        }else{
            styl = ""
        }

        let value = {
            "set": family,
            "data": JSON.stringify({family, light, normal, bold, customNormalUrl, customLightUrl, customBoldUrl, useCustom}),
            "styl": styl,
            "quoteless":  true,
            "variables": {family, light, normal, bold, customNormalUrl, customLightUrl, customBoldUrl, useCustom}
        };
        
        this.props.onChange(value);
    }

    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {
            let jsonValue = null;
            let jsonData = null;

            if(typeof value === "object"){
                jsonValue = {};
                jsonValue.data = JSON.stringify(value);
            }else{
                jsonValue = JSON.parse(value);
            }

            if (jsonValue !== null) {
                jsonData = JSON.parse(jsonValue.data);

                this.setState({
                    useCustom: jsonData.useCustom ? jsonData.useCustom : false,
                    customNormalUrl: jsonData.customNormalUrl ? jsonData.customNormalUrl.replaceAll("\"", "") : "",
                    customLightUrl: jsonData.customLightUrl ? jsonData.customLightUrl.replaceAll("\"", "") : "",
                    customBoldUrl: jsonData.customBoldUrl ? jsonData.customBoldUrl.replaceAll("\"", "") : ""
                });
            } 
        }
    }

    render() {
        const {classes, value, label, description} = this.props;
        const {useCustom, customNormalUrl, customLightUrl, customBoldUrl} = this.state;
        let showCustom = useCustom ? { display: "flex"} : { display: "none"}; 
        const {fonts} = this.state;
        if (fonts.length === 0) {
            fetch("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCMKDhshrAoOW32TCPbye_nOqSBW4br658")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        fonts: data.items
                    });
                    if (data.items && value && isJson(value)) {
                        let valueJson = JSON.parse(value);
                        if(valueJson.variables.family){
                            let variantOptions = data.items.filter(item => {
                                return item.family === valueJson.variables.family
                            })[0].variants.map(variant => ({
                                value: variant,
                                label: variant
                            }))
       
                            for(var i=0;i<variantOptions.length;i++){
                                if(variantOptions[i].value == "regular"){
                                    variantOptions[i].value = "400";
                                    variantOptions[i].label = "400";
                                }
                                if(variantOptions[i].value.indexOf("italic") != -1){
                                    variantOptions.splice(i,1);
                                    i = i - 1;
                                }
                            }
        
                            this.setState({variantOptions: variantOptions});
    
                            let selectedFont = data.items.filter(item => {
                                return item.family === valueJson.variables.family
                            })[0];
                       
                            this.setState({
                                familyValue: {
                                    value: selectedFont.family,
                                    label: selectedFont.family,
                                    data: selectedFont
                                },
                                selectedLightVariant: {
                                    value: valueJson.variables.light,
                                    label: valueJson.variables.light
                                },
                                selectedRegVariant: {
                                    value: valueJson.variables.normal,
                                    label: valueJson.variables.normal
                                },
                                selectedBoldVariant: {
                                    value: valueJson.variables.bold,
                                    label: valueJson.variables.bold
                                }
                            });
                        }


                    }
                });
        }

        const loadOptions = (inputValue, callback) => {
            callback(this.queryFonts(inputValue));
        };

        return (
            <div className={classes.root}>
                <NoSsr>    
                    <div class="tooltip-contain"> 
                        <span className="field-label label-with-tooltip">{label}</span>
                        { description && <span data-text={description} className="i-tooltip">i</span> }
                    </div>    
                    { !useCustom &&
                        <Grid container spacing={2} className={classes.grid}>
                            <Grid item className={classes.gridItem} xs={12}>
                                <InputLabel className="minorLabel">Family</InputLabel>
                                <AsyncSelect 
                                    disabled={useCustom}
                                    key={value}
                                    className={classes.select}
                                    classNamePrefix={"dropdown"}
                                    cacheOptions
                                    loadOptions={loadOptions}
                                    defaultOptions
                                    value={this.state.familyValue}
                                    onInputChange={this.handleInputChange}
                                    onChange={this.onFontFamilyChange}
                                    placeholder={"Type to search..."}
                                />
                            </Grid>
                            <Grid item className={classes.gridItem} xs={12}>
                                <Grid container spacing={1} className={classes.grid}>
                                    <Grid item className={classes.gridItem} xs={4}>
                                        <InputLabel className="minorLabel">Light</InputLabel>
                                        <Select
                                            disabled={useCustom}
                                            classNamePrefix={"dropdown"}
                                            value={this.state.selectedLightVariant}
                                            options={this.state.variantOptions}
                                            onChange={this.onLightVariantChange}
                                        />
                                    </Grid>
                                    <Grid item className={classes.gridItem} xs={4}>
                                        <InputLabel className="minorLabel">Normal</InputLabel>
                                        <Select
                                            disabled={useCustom}
                                            classNamePrefix={"dropdown"}
                                            value={this.state.selectedRegVariant}
                                            options={this.state.variantOptions}
                                            onChange={this.onRegRegVariantChange}
                                        />
                                    </Grid>
                                    <Grid item className={classes.gridItem} xs={4}>
                                        <InputLabel className="minorLabel">Bold</InputLabel>
                                        <Select
                                            disabled={useCustom}
                                            classNamePrefix={"dropdown"}
                                            value={this.state.selectedBoldVariant}
                                            options={this.state.variantOptions}
                                            onChange={this.onBoldVariantChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} style={{marginTop: 16, marginBottom: 16}} className={classes.flexField}>
                        <Checkbox value={useCustom} className={classes.padAdjust} onChange={this.onUseCustomChange} checked={useCustom}/>
                        <InputLabel className={classes.inlineLabel}>Use a custom font</InputLabel>
                    </Grid>
                    <Grid container spacing={2} className={classes.grid} style={showCustom}>
                        <Grid item xs={12}>
                            <InputLabel className="minorLabel">Custom Font Import URL (NORMAL)</InputLabel>
                            <TextField style={{margin:0, minWidth: 40}} onChange={(e) => {this.setState({customNormalUrl: e.target.value})}}  onBlur={this.onCustomNormalUrlChange}  value={customNormalUrl}></TextField>  
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className="minorLabel">Custom Font Import URL (LIGHT)</InputLabel>
                            <TextField style={{margin:0, minWidth: 40}} onChange={(e) => {this.setState({customLightUrl: e.target.value})}}  onBlur={this.onCustomLightUrlChange}  value={customLightUrl}></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel className="minorLabel">Custom Font Import URL (BOLD)</InputLabel>
                            <TextField style={{margin:0, minWidth: 40}} onChange={(e) => {this.setState({customBoldUrl: e.target.value})}}  onBlur={this.onCustomBoldUrlChange}  value={customBoldUrl}></TextField>
                        </Grid>
                    </Grid>
                </NoSsr>
            </div>
        );
    }
}

FontWithCustomSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FontWithCustomSelector);
