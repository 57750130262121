
// we expect a property revuiConfig in the schema
function filterSchemaForUi(schemas, templateValues) {
    return schemas.filter(schema => {
        let {name} = schema;
        let revuiValueConfig = templateValues[name];
        // do not show children block configurable proprs -> this is handled in the edit render mode
        if (typeof revuiValueConfig !== 'object' && revuiValueConfig === "array") {
            return false;
        }
        return true;
    });
}

export default filterSchemaForUi;