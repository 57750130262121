import atob from "atob";

let debug = window.debug('app:share:jwt');

class Jwt {

    setTokenFunc = (val) => {
        this.setter(this.generateTokenState(val, this.setter));
    };

    loadJwtFromStorage = () => {
        return window.sessionStorage.getItem('token') || null;
    };

    saveJwtToStorage = (token) => {
        window.sessionStorage.setItem('token', token);
    };

    // get {} from JWT token
    parsePayload = (token) => {
        let obj = null;
        try {
            let [,body] = token.split('.');
            obj = JSON.parse(atob(body));
        } catch (e) {
            debug('error parsing json');
        }
        return obj;
    };

    // generate app state to pass around with token info and setter function
    generateTokenState = (jwtToken, setter) => {
        this.setter = setter;
        if (jwtToken) {
            this.saveJwtToStorage(jwtToken);
        }
        let data = this.loadJwtFromStorage();
        return {
            token: {
                data: data,
                obj: this.parsePayload(data),
                // token set function to call from children components
                set: this.setTokenFunc
            }
        }
    };

}

export default Jwt;