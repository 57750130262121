import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
// import TextField from "@material-ui/core/es/TextField/TextField";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
    root: {},
    slider: {
        padding: '22px 0px',
    },
    label: {
        fontSize: "12px",
        paddingBottom: "8px",
        display: "block"
    },
    grid: {
        width: "100%",
    },

    gridInput: {
        maxWidth: "100%"
    }

});

class GridEditor extends React.Component {
    state = {
        columns: 2,
        columnIterator: [0, 1],
        columnData: {
            0: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            1: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            2: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            3: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            4: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            5: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            6: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            7: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            8: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            9: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            10: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0},
            11: {d: 6, xs: 0, s: 0, m: 0, l: 0, xl: 0}
        }
    };

    onColumnChange = event => {
        this.setState({[event.target.name]: event.target.value});
        this.setState({columnIterator: Array.apply(null, {length: event.target.value}).map(Number.call, Number)}, () => {
            this.onChange();
        });
    };

    onHandleBreakPointChange = (col, event, bp) => {
        let colData = this.state.columnData
        colData[col][bp] = event.target.value;
        this.setState({columnData: colData}, () => {
            this.onChange();
        });
    };

    onChange = () => {
        this.props.onChange({columns: this.state.columns, data: this.state.columnData});
    };

    componentDidMount() {

        let {value} = this.props;

        if (value) {
            let valueJson = JSON.parse(value);


            this.setState({columnData: valueJson.data}, () => {
                this.setState({columns: valueJson.columns});
                this.setState({columnIterator: Array.apply(null, {length: valueJson.columns}).map(Number.call, Number)});
            });
        }
    }

    render() {
        const {classes} = this.props;


        const {columns, columnIterator} = this.state;

        const columnOptions = [];
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(col => {
            columnOptions.push({value: col, label: col});
        });

        return (
            <div className={classes.root}  class="grid-editor">
                <InputLabel className={classes.label}><strong>Grid Options</strong></InputLabel>


                <InputLabel className={classes.label}>How many columns?</InputLabel>
                <Select style={{marginBottom: 22}} onChange={this.onColumnChange} value={columns}
                        inputProps={{name: 'columns', id: 'columns',}}>
                    {
                        columnOptions.map((col) => {
                            return <MenuItem key={col.value} value={col.value}>{col.label}</MenuItem>
                        })
                    }
                </Select>

                {
                    columnIterator.map((col) => (

                        <Grid key={col} container spacing={8} className={classes.grid}>
                            <Grid item xs={1}>
                                {col === 0 && <strong
                                    style={{paddingTop: 26, display: "block", fontSize: 12, width: "60px"}}>Col {col + 1}</strong> }
                                {col > 0 && <strong
                                    style={{paddingTop: 6, display: "block", fontSize: 12, width: "60px"}}>Col {col + 1}</strong> }
                            </Grid>
                            <Grid item xs={1}>
                                {col === 0 && <InputLabel className={classes.label}>Default</InputLabel>}
                                <TextField value={this.state.columnData[col].d} type="number"
                                           className={classes.gridInput}
                              
                                           onChange={(e) => this.onHandleBreakPointChange(col, e, 'd')}/>
                            </Grid>
                            <Grid item xs={1}>
                                {col === 0 && <InputLabel className={classes.label}>X Small</InputLabel>}
                                <TextField value={this.state.columnData[col].xs} type="number"
                                    
                                           onChange={(e) => this.onHandleBreakPointChange(col, e, 'xs')}/>
                            </Grid>
                            <Grid item xs={1}>
                                {col === 0 && <InputLabel className={classes.label}>Small</InputLabel>}
                                <TextField value={this.state.columnData[col].s} type="number"
                                
                                           onChange={(e) => this.onHandleBreakPointChange(col, e, 's')}/>
                            </Grid>
                            <Grid item xs={1}>
                                {col === 0 && <InputLabel className={classes.label}>Medium</InputLabel>}
                                <TextField value={this.state.columnData[col].m} type="number"
                                           onChange={(e) => this.onHandleBreakPointChange(col, e, 'm')}/>
                            </Grid>
                            <Grid item xs={1}>
                                {col === 0 && <InputLabel className={classes.label}>Large</InputLabel>}
                                <TextField value={this.state.columnData[col].l} type="number"
                                           onChange={(e) => this.onHandleBreakPointChange(col, e, 'l')}/>
                            </Grid>
                            <Grid item xs={1}>
                                {col === 0 && <InputLabel className={classes.label}>X Large</InputLabel>}
                                <TextField value={this.state.columnData[col].xl} type="number"
                                           onChange={(e) => this.onHandleBreakPointChange(col, e, 'xl')}/>
                            </Grid>

                        </Grid>
                    ))
                }


            </div>
        );
    }
}

GridEditor.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GridEditor);
