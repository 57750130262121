/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {RadioGroup, Radio, Slider, Checkbox} from '@material-ui/core';


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },

});


class FeatureLayout extends React.Component {
    
    state = {
        inputValue: this.props.value
    };

    buildLayout = () => {

        let {layout} = this.state;

        let value = {
            "set": layout,
            "data": JSON.stringify({layout}),
            "quoteless":  false,
            "variables": {layout}
        };

        this.props.onChange(value);
    }

    handleLayoutChange = (e, newValue) => {
        this.setState({layout: newValue}, () => {
            this.buildLayout();
        });
    }


    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {
            try {
                let jsonValue = JSON.parse(value);

                if (jsonValue !== null) {
                    let jsonData = JSON.parse(jsonValue.data);
                    this.setState({layout: jsonData.layout });
                }
            } catch (e) {
                this.setState({layout: value });
            }

        }
        
    }

    render() {
        const { classes, label, id } = this.props;
        const { layout } = this.state;

        let rowDir = {
            flexDirection: "row"
        }


        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="FeatureLayoutContainer" >
                        <InputLabel className={classes.label}>{label}</InputLabel>
                          
                            <Grid container spacing={2}>
                                <RadioGroup
                                    aria-label="FeatureLayout"
                                    name="FeatureLayout"
                                    value={layout}
                                    onChange={this.handleLayoutChange}
                                    style={rowDir}
                                >
                                    <label className={layout == 'layout-one' ? 'layout-radio layout-one-graphic selected'  : 'layout-radio layout-one-graphic '}>
                                        <span className={"logo"}>IMAGE</span>
                                        <span className={"gfx-block gfx-block-long"}>TITLE</span>
                                        <span className={"gfx-btn"}>GET DEAL</span>
                                        <FormControlLabel className="layout-input" value="layout-one" control={<Radio />} label="Image Top" />
                                    </label>
                                    <label className={layout == 'layout-two' ? 'layout-radio layout-two-graphic selected'  : 'layout-radio layout-two-graphic '}>
                                        <span className={"gfx-block gfx-block-long"}>TITLE</span>
                                        <span className={"logo"}>IMAGE</span>
                                        <span className={"gfx-btn"}>GET DEAL</span>
                                        <FormControlLabel className={"layout-input"}  value="layout-two" control={<Radio />} label="Title Top" />
                                    </label>
                                    <label className={layout == 'layout-three' ? 'layout-radio layout-three-graphic selected'  : 'layout-radio layout-three-graphic '}>
                                        <span className={"gfx-block gfx-block-long"}>TITLE</span>
                                        <span className={"gfx-btn"}>GET DEAL</span>
                                        <FormControlLabel className={"layout-input"}  value="layout-three" control={<Radio />} label="Background Image" />
                                        <span className={"gfx-text"}>image background</span>
                                    </label>
                                </RadioGroup>
                                                        
                            </Grid>   
                        </div>
                </NoSsr>
            </div>
        );
    }
    
}



FeatureLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FeatureLayout);
