/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import AsyncSelect from 'react-select/lib/Async';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "0px",
    },
    label: {
        fontSize: "14px  !important",
        marginBottom: "16px !important",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },
    minorLabelM0: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "0px  !important"
    },
    inlineLabelField: {
        display: "flex",
        "flex-direction": "row",
        "justify-content": "left",
        "align-items": "center"
    },

    select: {
       
    },
    grid: {
       

    },
    gridItem: {
      
    }

});


function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

class FontMultiWeightSelector extends React.Component {
    state = {
        inputValue: this.props.value,
        suggestions: [],
        fonts: [],
        variantOptions: [],
        familyValue: null,
        selectedLightVariant: null,
        selectedRegVariant: null,
        selectedBoldVariant: null,
        fontSelectValue: null
    };

    queryFonts(value) {
        return this.state.fonts.filter(item => {
            return item.family.toLowerCase().includes(value.toLowerCase())
        }).map(suggestion => ({
            value: suggestion.family,
            label: suggestion.family,
            data: suggestion
        }));
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({inputValue});
        return inputValue;
    };

    onFontFamilyChange = (val) => {
        var variantOptions = val.data.variants;
        for(var i=0;i<variantOptions.length;i++){
            if(variantOptions[i] == "regular"){
                variantOptions[i] = "400";
            }
            if(variantOptions[i].indexOf("italic") != -1){
                variantOptions.splice(i,1);
                i = i - 1;
            }
        }
        this.setState({
            "variantOptions": variantOptions.map(variant => ({
                value: variant,
                label: variant
            }))
        }, function() {
            this.setState({selectedLightVariant: null}, function () {
                this.setState({familyValue: val}, () => {
                    this.onAnyChange({familyValue: val});
                });
            });
            this.setState({selectedRegVariant: null}, function () {
                this.setState({familyValue: val}, () => {
                    this.onAnyChange({familyValue: val});
                });
            });
            this.setState({selectedBoldVariant: null}, function () {
                this.setState({familyValue: val}, () => {
                    this.onAnyChange({familyValue: val});
                });
            });
        });

      
    } 

    onRegRegVariantChange = (val) => {
        this.setState({selectedRegVariant: val}, function () {
            this.onAnyChange({selectedRegVariant: val});
        });
     }

    onLightVariantChange = (val) => {
        this.setState({selectedLightVariant: val}, function () {
            this.onAnyChange({selectedLightVariant: val}); 
        });
    }
    
    onBoldVariantChange = (val) => {
    this.setState({selectedBoldVariant: val}, function () {
        this.onAnyChange({selectedBoldVariant: val});
    });
    }



    onAnyChange = () => {
        let payload = {
            familyValue: this.state.familyValue, 
            selectedLightVariant: this.state.selectedLightVariant ? this.state.selectedLightVariant : "", 
            selectedRegVariant: this.state.selectedRegVariant ? this.state.selectedRegVariant : "", 
            selectedBoldVariant: this.state.selectedBoldVariant ? this.state.selectedBoldVariant : ""
        };
        this.props.onChange(payload);
        this.buildFont();
    }

    buildFont = () => {
        let {familyValue, selectedLightVariant, selectedRegVariant, selectedBoldVariant} = this.state;
        let family = familyValue.value;
        let light = selectedLightVariant ? selectedLightVariant.value : "";
        let normal = selectedRegVariant ? selectedRegVariant.value : "";
        let bold = selectedBoldVariant ? selectedBoldVariant.value : "";

        let apiUrl = [];
        apiUrl.push('https://fonts.googleapis.com/css?family=');
        apiUrl.push(family.replace(/ /g, '+'));
        if (light) {
            apiUrl.push(':');
            apiUrl.push("0,"+light);
        } 
        if (normal) {
            apiUrl.push(':');
            apiUrl.push("0,"+normal);
        } 
        if (bold) {
            apiUrl.push(':');
            apiUrl.push("0,"+bold);
        } 
        let url = apiUrl.join('');

        let value = {
            "set": family,
            "data": JSON.stringify({family, light, normal, bold}),
            "styl": `@import url(${url.replace(/\+/g, "\\+")})`,
            "quoteless":  true,
            "variables": {family, light, normal, bold}
        };
        
        this.props.onChange(value);
    }

    render() {
        const {classes, value, label, showTitle, customFont} = this.props;
    
        const {fonts} = this.state;
        if (fonts.length === 0) {
            fetch("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCMKDhshrAoOW32TCPbye_nOqSBW4br658")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        fonts: data.items
                    });
                    if (data.items && value && isJson(value)) {
                        let valueJson = JSON.parse(value);
                        if(valueJson.variables.family){
                            let variantOptions = data.items.filter(item => {
                                return item.family === valueJson.variables.family
                            })[0].variants.map(variant => ({
                                value: variant,
                                label: variant
                            }))
       
                            for(var i=0;i<variantOptions.length;i++){
                                if(variantOptions[i].value == "regular"){

                                    variantOptions[i].value = "400";
                                    variantOptions[i].label = "400";
                                }
                                if(variantOptions[i].value.indexOf("italic") != -1){
                                    variantOptions.splice(i,1);
                                    i = i - 1;
                                }
                            }
        
                            this.setState({variantOptions: variantOptions});
    
                            let selectedFont = data.items.filter(item => {
                                return item.family === valueJson.variables.family
                            })[0];
                       
                            this.setState({
                                familyValue: {
                                    value: selectedFont.family,
                                    label: selectedFont.family,
                                    data: selectedFont
                                },
                                selectedLightVariant: {
                                    value: valueJson.variables.light,
                                    label: valueJson.variables.light
                                },
                                selectedRegVariant: {
                                    value: valueJson.variables.normal,
                                    label: valueJson.variables.normal
                                },
                                selectedBoldVariant: {
                                    value: valueJson.variables.bold,
                                    label: valueJson.variables.bold
                                }
                            });
                        }


                    }
                });
        }

        const loadOptions = (inputValue, callback) => {
            callback(this.queryFonts(inputValue));
        };


        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel className={classes.label}>{label}</InputLabel>
                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item className={classes.gridItem} xs={4}>
                            <InputLabel className="minorLabel">Family</InputLabel>
                            <AsyncSelect 
                                key={value}
                                className={classes.select}
                                classNamePrefix={"dropdown"}
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions
                                value={this.state.familyValue}
                                onInputChange={this.handleInputChange}
                                onChange={this.onFontFamilyChange}
                            />
                        </Grid>
                        <Grid item className={classes.gridItem} xs={8}>
                            <Grid container spacing={1} className={classes.grid}>
                                <Grid item className={classes.gridItem} xs={4}>
                                    <InputLabel className="minorLabel">Light</InputLabel>
                                    <Select
                                        classNamePrefix={"dropdown"}
                                        value={this.state.selectedLightVariant}
                                        options={this.state.variantOptions}
                                        onChange={this.onLightVariantChange}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem} xs={4}>
                                    <InputLabel className="minorLabel">Normal</InputLabel>
                                    <Select
                                        classNamePrefix={"dropdown"}
                                        value={this.state.selectedRegVariant}
                                        options={this.state.variantOptions}
                                        onChange={this.onRegRegVariantChange}
                                    />
                                </Grid>
                                <Grid item className={classes.gridItem} xs={4}>
                                    <InputLabel className="minorLabel">Bold</InputLabel>
                                    <Select
                                        classNamePrefix={"dropdown"}
                                        value={this.state.selectedBoldVariant}
                                        options={this.state.variantOptions}
                                        onChange={this.onBoldVariantChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </NoSsr>
            </div>
        );
    }
}

FontMultiWeightSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FontMultiWeightSelector);
