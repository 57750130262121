import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Creatable from 'react-select/lib/Creatable'
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = {
    root: {
        width: 500,
        padding: 20
    },

    label: {
        fontSize: "12px",
        paddingBottom: "8px",
        display: "block"
    },
};

class BlockSettings extends React.Component {

    state = {
        options: [],
        values: {
            muiIcon: 'web_asset',
            title: '',
            description: '',
            renderVersion: '',
            styleCompiler: '',
            tags: [],
            archived: false,
            async: false,
        }
    } 

    componentDidMount() {
        let v = JSON.parse(JSON.stringify(this.props.value));

        if (v.hasOwnProperty('tags') && v.tags) {
            v.tags = v.tags.map(function (item) {
                return {label: item, value: item}
            });
        }
        this.setState({'values': v});
    }

    handleChange = (event) => {
        let newValues = this.state.values;
        newValues[event.target.name] = event.target.value;
        this.setState({"values": newValues});
        this.props.onChange(event.target.name, event.target.value);
    }

    handleChecked = (event) => {
        let newValues = this.state.values;
        newValues[event.target.name] = event.target.checked;
        this.setState({"values":  newValues});
        this.props.onChange(event.target.name, event.target.checked);
    }

    onTagChange = (val) => {
        let values = this.state.values;
        values.tags = val;
        this.setState(values);
        this.props.onChange('tags', val.map(function (item) {
            return item.value
        }));
    }

    render() {

        const {classes} = this.props;
        const {values} = this.state;

        return (
            <div className={classes.root}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputLabel>Title</InputLabel>
                                <TextField type="text" placeholder={"Title"} name="title" value={values.title}
                                        onChange={this.handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Description</InputLabel>
                                <TextField fullWidth type="text" placeholder={"Description"} name="description" value={values.description}
                                        onChange={this.handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Render Version</InputLabel>
                                <TextField fullWidth type="text" placeholder={"e.g. golang"} name="renderVersion" value={values.renderVersion}
                                        onChange={this.handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Style Compiler</InputLabel>
                                <TextField fullWidth type="text" placeholder={"css or scss"} name="styleCompiler" value={values.styleCompiler}
                                        onChange={this.handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Material UI Icons <small><a target={"_blank"}
                                                                        href={"https://material.io/tools/icons/?style=baseline"}>https://material.io/tools/icons/?style=baseline</a>
                                </small></InputLabel>
                                <TextField type="text" placeholder={"Material Icon"} name="muiIcon" value={values.muiIcon}
                                        onChange={this.handleChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Tags</InputLabel>
                                <Creatable onChange={this.onTagChange} value={values.tags} isMulti/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox value="true" name="archived" checked={values.archived}  onChange={this.handleChecked} />} label="Archived" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox value="true" name="async" checked={values.async}  onChange={this.handleChecked} />} label="Async Loading" />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>






            </div>
        );
    }
}

BlockSettings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BlockSettings);
