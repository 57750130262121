import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/lab/Slider';
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = {
    root: {
        
    },
    slider: {
        padding: '22px 0px',
    },
    label: {
        fontSize: "12px",
        paddingBottom: "8px",
        display: "block"
    },
};

/*
Handles a value with units.
*/
class SimpleSlider extends React.Component {

    render() {

        function clamp(value, min, max) {
            if (value < min) {
              return min;
            }
            if (value > max) {
              return max;
            }
            return value;
          }

        const { classes, onChange, value, unit, id, label, min, max, step, availableUnits } = this.props;

        // clamp value within the limits
        let clampedValue = clamp(value, min, max);
        if (clampedValue !== value) {
            onChange(clampedValue + unit);
        }

        return (
            <div className={classes.root}>
                <InputLabel className={classes.label}>{label} - <strong>{value + unit}</strong>
                
                </InputLabel>
                <Select
                    value={unit}
                    onChange={e => {
                        onChange(value + e.target.value)}}
                    // inputProps={{
                    //     name: 'age',
                    //     id: 'age-simple',
                    // }}
                    >
                        {(availableUnits || [unit]).map(u => <MenuItem key={u} value={u}>{u}</MenuItem>)}
                    </Select>
                <Slider
                    key={id}
                    min={min}
                    max={max}
                    step={step}
                    classes={{ container: classes.slider }}
                    value={value}
                    aria-labelledby="label"
                    onChange={(e,value) => onChange(value + unit)}
                />
            </div>
        );
    }
}

SimpleSlider.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleSlider);
