/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import ColourPicker from './ColourPicker';
import Size from './Size';
import { MenuItem, Slider, Checkbox} from '@material-ui/core';
import SlideValue from './SlideValue';
import Select from 'react-select';
import NumberField from './NumberField';

const Color = require('color');


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "4px  !important",
        opacity: 0.7
    },
    fullWidth: {
        width: "100%"
    },

});


class FullFontStyles extends React.Component {

    state = {
        inputValue: this.props.value,
        family: null,
        weight: null,
        size: null,
        lineHeight: null,
        letterSpace: null,
        styles:[
            "family",
            "weight",
            "size",
            "lineHeight",
            "letterSpace"
        ],
        families:[{
            value: "primary",
            label: "Primary"
        },
        {
            value: "secondary",
            label: "Secondary"
        }],
        weights:[ {
            value: "Light",
            label: "Light"
        },
        {
            value: "Normal",
            label: "Normal"
        },
        {
            value: "Bold",
            label: "Bold"
        }]
    };
    

    
    onFamilyChange = (val) => {
        this.setState({family: val}, function () {
            this.onAnyChange({family: val}); 
        });
    }
    
    onWeightChange = (val) => {
        this.setState({weight: val}, function () {
            this.onAnyChange({weight: val});
        });
    }

    onAnyChange = () => {
        let payload = {
            family: this.state.family, 
            weight: this.state.weight ? this.state.weight : "", 
            size: this.state.size ? this.state.size : "", 
            lineHeight: this.state.lineHeight ? this.state.lineHeight : "", 
            letterSpace: this.state.letterSpace ? this.state.letterSpace : ""};
        this.props.onChange(payload);
        this.buildStyles();
    }

    buildStyles = () => {
        let {size, lineHeight, family, weight, color, letterSpace} = this.state;
        family = family ? family.value : "";
        weight = weight ? weight.value : "";

        let value = {
            "set": family, 
            "data": JSON.stringify({size, lineHeight, family, weight, color, letterSpace}),
            "quoteless":  true,
            "variables": {family, size, weight, color, letterSpace, lineHeight}
        };

        this.props.onChange(value);
    }
    
 
    componentDidMount = () => {
        let { value, families, styles} = this.props;


        if(typeof families  !== 'undefined'){
            this.setState({
                families: families
            })
        }

        if(typeof styles  !== 'undefined'){           
            this.setState({
                styles: styles
            })
        }
            
        if (value !== null && value !== undefined) {
            let jsonValue = null;
            let jsonData = null;

            if(typeof value === "object"){
                jsonValue = {};
                jsonValue.data = JSON.stringify(value);
            }else{
                jsonValue = JSON.parse(value);
            }

            if (jsonValue !== null) {
                jsonData = JSON.parse(jsonValue.data);
                let family = this.state.families.find(x => x.value === jsonData.family);
                let weight = this.state.weights.find(x => x.value === jsonData.weight);
                this.setState({
                    family: this.state.styles.indexOf('family') !== -1 ? family : "",
                    weight: this.state.styles.indexOf('weight') !== -1  ? weight : "",
                    size: this.state.styles.indexOf('size') !== -1  ? jsonData.size : "",
                    letterSpace: this.state.styles.indexOf('letterSpace') !== -1  ? jsonData.letterSpace : "",
                    lineHeight: this.state.styles.indexOf('lineHeight') !== -1  ? jsonData.lineHeight : ""                 
                });
            } 
        }
    }

    render() {
        const { classes, label, id, description} = this.props;
        const { families, weights, family, weight, size, letterSpace, lineHeight, styles} = this.state;

        const units = ['px','em','rem','pt','%'];

        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="fontContainer" ></div>             
                        <div class="tooltip-contain"> 
                            <span className="field-label label-with-tooltip">{label}</span>
                            { description && <span data-text={description} className="i-tooltip">i</span> }
                        </div>   
                        <Grid container spacing={2}>
                            {styles.indexOf('family') !== -1 && (
                                <Grid item xs={12}>
                                    <InputLabel className={classes.minorLabel}>Family</InputLabel>
                                    <Select 
                                        value={family} 
                                        id="icon" 
                                        label={"Family"}
                                        classNamePrefix={"dropdown"}
                                        className={classes.fullWidth}
                                        options={families}
                                        isSearchable={false}
                                        onChange={(v) => {this.setState({family: v}, () => {this.buildStyles()}); }}
                                    />
                                </Grid>
                            )}
                            {styles.indexOf('weight') !== -1 && (
                                <Grid item xs={6}>
                                <InputLabel className={classes.minorLabel}>Weight</InputLabel>
                                <Select 
                                    value={weight} 
                                    id="icon" label={"Weight"}
                                    classNamePrefix={"dropdown"}
                                    className={classes.fullWidth}
                                    options={weights}
                                    isSearchable={false}
                                    onChange={this.onWeightChange}
                                />
                            </Grid>
                            )}
                            {styles.indexOf('size') !== -1 && (
                                <Grid item xs={6}>
                                    <InputLabel className={classes.minorLabel}>Font size</InputLabel>
                                    <Size unit={"px"} units={units}  value={size} onChange={(v) => {this.setState({size: v}, () => {this.buildStyles()}); }}></Size>
                                </Grid>
                            )}
                            {styles.indexOf('lineHeight') !== -1 && (
                                <Grid item xs={6}>
                                    <InputLabel className={classes.minorLabel}>Line height</InputLabel>
                                    <Size unit={"px"} units={units} value={lineHeight} onChange={(v) => {this.setState({lineHeight: v}, () => {this.buildStyles()}); }}></Size>
                                </Grid>
                            )}
                            {styles.indexOf('letterSpace') !== -1 && (
                                <Grid item xs={6} >
                                    <InputLabel className={classes.minorLabel}>Letter spacing</InputLabel>
                                    <Size unit={"px"} units={units} value={letterSpace} onChange={(v) => {this.setState({letterSpace: v}, () => {this.buildStyles()}); }}></Size>
                                </Grid>
                            )}
                        </Grid>  
                </NoSsr>
            </div>
        );
    }
}

function isColor(val) {
    let success = false;
    try {
        Color(val);
        success = true;
    } catch (e) {
    }
    return success;
}

FullFontStyles.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FullFontStyles);

