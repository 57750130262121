import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import NoSsr from '@material-ui/core/NoSsr';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ColourPicker from './ColourPicker';
import TextField from './TextField';
import {Checkbox, Select, MenuItem} from '@material-ui/core';

const Color = require('color');

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },
    fullWidth: {
        width: "100%",
        'ul':{
            height: 200,
            overflow: "scroll",
        }
    },

});



class ComplexLabel extends React.Component {
    state = {
        inputValue: this.props.value
    };

    buildLabel = () => { 

        let {icon, labelText, backColor, textColor} = this.state;
        let color = lightOrDark(backColor) == "light" ? "#ffffff" : "#000000";
        let labelHtml = `<span class='offer-label' style='background-color: ${backColor}; color: ${color}'><i class='fas ${icon}'></i> <span style='color: ${textColor};' class='lebel'>${labelText}</span></span>`;

        let value = {
            "set": labelHtml,
            "data": JSON.stringify({icon, labelText, backColor, textColor}),
            "quoteless":  false,
            "variables": {icon, labelText, backColor, textColor}
        };
        this.props.onChange(value);
    }

    onTextChange = (e) => {
        this.setState({labelText: e.target.value}, () => {
            this.buildLabel();
        });
    }

    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {
            try {
                let jsonValue = JSON.parse(value);

                if (jsonValue !== null) {
                    let jsonData = JSON.parse(jsonValue.data);
                    this.setState({
                        icon: jsonData.icon,
                        labelText: jsonData.labelText,
                        backColor: jsonData.backColor,
                        textColor: jsonData.textColor

                    });
                }
            } catch (e) {
                this.setState({layout: value });
            } 
        }     
    }

    render() {
        let {classes, label} = this.props;      
        const { icon, labelText, backColor, textColor } = this.state;      
        let labelStyle = {
            color: textColor,
            backgroundColor: backColor,
            padding: "8px 16px",
            display: "inline-block"
        }
        let textStyle = {
            color: textColor
        }
        let iconStyle = {
            marginRight: "8px"
        }
        let warningStyle = {
            marginTop: "-10px",
            display: "block",
            fontSize: "12px",
            color:"#d95555"
        }

        let contrastWarning = (backColor && textColor) && lightOrDark(backColor) == lightOrDark(textColor) ? "Colours may be hard to read" : "";

        let textWarning = labelText && labelText.length > 15 ? "*Try keeping labels short" : "";
        let warningInput = labelText && labelText.length > 15 ? {  border:"1px solid #d95555" } : {};


        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="RoundelContainer" >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                 { label && <InputLabel>{label}</InputLabel>}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <InputLabel className={classes.minorLabel}>Icon</InputLabel> 
                                <Select className={classes.fullWidth} onChange={(e) => {this.setState({icon: e.target.value}, () => {this.buildLabel()}); }}
                                    value={icon} id="icon" label={"Icon image"} defaultValue={this.props.value || ''}>
                                    <MenuItem value={""}>None</MenuItem>
                                    <MenuItem value={"fa-award"}><i class='fas fa-award'></i>&nbsp;Award</MenuItem>
                                    <MenuItem value={"fa-clock"}><i class='fas fa-clock'></i>&nbsp;Clock</MenuItem>
                                    <MenuItem value={"fa-dollar-sign"}><i class='fas fa-dollar-sign'></i>&nbsp;Dollar Sign</MenuItem>
                                    <MenuItem value={"fa-euro-sign"}><i class='fas fa-euro-sign'></i>&nbsp;Euro Sign</MenuItem>
                                    <MenuItem value={"fa-gem"}><i class='fas fa-gem'></i>&nbsp;Gem</MenuItem>
                                    <MenuItem value={"fa-gift"}><i class='fas fa-gift'></i>&nbsp;Gift</MenuItem>
                                    <MenuItem value={"fa-gifts"}><i class='fas fa-gifts'></i>&nbsp;Gifts</MenuItem>
                                    <MenuItem value={"fa-heart"}><i class='fas fa-heart'></i>&nbsp;Heart</MenuItem>
                                    <MenuItem value={"fa-hourglass"}><i class='fas fa-hourglass'></i>&nbsp;Hour Glass</MenuItem>
                                    <MenuItem value={"fa-piggy-bank"}><i class='fas fa-piggy-bank'></i>&nbsp;Piggy bank</MenuItem>
                                    <MenuItem value={"fa-pound-sign"}><i class='fas fa-pound-sign'></i>&nbsp;Pound Sign</MenuItem>
                                    <MenuItem value={"fa-shopping-bag"}><i class='fas fa-shopping-bag'></i>&nbsp;Shopping bag</MenuItem>
                                    <MenuItem value={"fa-star"}><i class='fas fa-star'></i>&nbsp;Star</MenuItem>
                                    <MenuItem value={"fa-tag"}><i class='fas fa-tag'></i>&nbsp;Tag</MenuItem>
                                    <MenuItem value={"fa-trophy"}><i class='fas fa-trophy'></i>&nbsp;Trophy</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel className={classes.minorLabel}>Label Text </InputLabel>
                                <TextField style={warningInput} onChange={(e) => {this.setState({labelText: e.target.value})}} onBlur={this.onTextChange}  value={labelText}></TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        <InputLabel className={classes.minorLabel}>Label colour</InputLabel>
                                        <ColourPicker
                                            defaultValue={backColor || ''}
                                            onChange={(val, backColor) => {
                                                if (val && isColor(val)) {
                                                    this.setState({backColor: val}, () => {this.buildLabel()});
                                                } 
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel className={classes.minorLabel}>Text colour</InputLabel>
                                        <ColourPicker
                                            defaultValue={textColor || ''}
                                            onChange={(val, textColor) => {
                                                if (val && isColor(val)) {
                                                    this.setState({textColor: val}, () => {this.buildLabel()});
                                                } 
                                            }}
                                        />
                                    </Grid>
                                    </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel className={classes.minorLabel}>Preview</InputLabel>
                               <span class='offer-label' style={labelStyle}><i style={iconStyle} class={'fas ' + icon}></i> <span style={textStyle} class='label'>{labelText}</span></span>
                            </Grid>

                            <Grid item xs={2}>
                            </Grid> 
                            <Grid item xs={3}>
                                <span style={warningStyle}>{ textWarning }</span>
                            </Grid>
                            <Grid item xs={3}>
                                <span style={warningStyle}>{ contrastWarning }</span>
                            </Grid>
                            <Grid item xs={3}></Grid>
                        </Grid>
                    </div>
                </NoSsr>
            </div>
        )
    }
}


function lightOrDark(color) {
    if(color){
        var r, g, b, hsp;
        if (color.match(/^rgb/)) {
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            r = color[1];
            g = color[2];
            b = color[3];
        } 
        else {
            color = +("0x" + color.slice(1).replace( 
            color.length < 5 && /./g, '$&$&'));
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }
        hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
        );
        if (hsp>150) {
            return 'light';
        } 
        else {
            return 'dark';
        }
    }else{
        return 'dark';
    }
}

function isColor(val) {
    let success = false;
    try {
        Color(val);
        success = true;
    } catch (e) {
    }
    return success;
}



ComplexLabel.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};


export default withStyles(styles, {withTheme: true})(ComplexLabel);

