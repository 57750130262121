/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "22px",
        zIndex: 99999,
        maxWidth: "300px"
    },
    label: {
        fontSize: "12px",
        paddingBottom: "8px",
        display: "block"
    }
});


class StyleSelect extends React.Component {
    
    
    state = {
        inputValue: this.props.value,
    };


    render() {
        const {classes, value, label, options, onChange} = this.props;

        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel>{ label }</InputLabel>
                    <Select
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        style={{minWidth: 200}}
                        >
                        {
                            options.map(option => (
                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))
                        }
                    </Select>
                </NoSsr>
            </div>
        );
    }
}

StyleSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(StyleSelect);
