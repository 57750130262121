import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const styles = {
    hr: {
      margin: "20px 0",
      borderColor: "#eee"
    }
};

class DividerField extends React.Component {
    render() {
        const {classes} = this.props;
        return (
            <hr className={classes.hr}/>
        );
    }
}

DividerField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DividerField);
