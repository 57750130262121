import React, {Fragment, useState, useEffect} from "react";
import './PreviewView.css';
import {Button} from "@material-ui/core";
import {Tooltip, AppBar, Toolbar} from '@material-ui/core';
import Api from "../Share/api";
import CookieManager from '../Components/CookieManager';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import EditModalSidebar from './EditModalSidebar';
import Tour from 'reactour';
import RefreshIcon from '@material-ui/icons/Refresh';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import StorageIcon from '@material-ui/icons/Storage';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import PhoneIphone from '@material-ui/icons/PhoneIphone';
import TabletMac from '@material-ui/icons/TabletMac';
import classNames from 'classnames';

const debug = window.debug('app:rev-ui:PreviewViewSimple');
const drawerWidth = 368;

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
        backgroundColor: '#FFF',
        height: "69px",
        boxShadow: 'none'
      },
      previewHolder: {
          display: "flex",
          justifyContent: "center",
          padding: 8
      },
      previewPanelContain: {
          display: "flex",
      },
      previewPanel: {
          width: "100%",
          background: "rgba(19, 54, 79, 0.4)",
          height: "100vh"
      },
      iframe: {
          transition: "all 0.4s",
          border: "1px solid white",
          height: "100vh",
          '&.desktop': {
              width: "100%",
              maxHeight: "calc(100vh - 88px)"
          },
          '&.tablet' : {
              width: "768px",
              maxHeight: "calc(100vh - 88px)"
          },
          '&.mobile' : {
              width: "390px",
              maxHeight: "812px",
              height: "calc(100vh - 88px)"
          },
          borderRadius: 16
  
    },
    toolbarRight: {
        justifyContent: "right",
        flex: 1,
        display: 'flex'
    },
    responsiveSelectors:{
        display: "flex",
        alignItems: "center",
        background: '#13364F',
        padding: "8px 16px 8px 32px",
        marginRight: 32,
        borderRadius: 8
    },
    tourButton: {
        color: '#13364F',
        cursor: 'pointer'
    },
    responsiveButton: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: 'pointer',
        borderRadius: 4,
        marginLeft: "12px",
        fontSize: "14px",
        padding: "4px 16px",
        border: "1px solid transparent",
        opacity: 0.6,
        '&.selected': {
            opacity: 1,
            border: "1px solid #97D3D5"
        }
    },
    responsiveButtonIcon: {
        color: '#fff',
        fontSize: "16px"
    },
    toolbarCenter: {
        justifyContent: "center",
        flex: 1,
        display: 'flex'
    }
  }));


/**
 * Show the preview of the ui
 * @param props
 * @returns {*}
 * @constructor
 */
function PreviewView(props) {

    let [inspect, setInspect] = useState(null);
    let [cookieManagerOpen, setCookieManagerOpen] = useState(false);
    let [currentEditInstance, setCurrentEditInstance] = useState({instance: props.instance});
    let [isTourOpen, setIsTourOpen] = useState(false);
    let [isSideBarRoot, setIsSideBarRoot] = useState(true);
    let [responsiveMode, setResponsiveMode] = useState("desktop");

  
    const steps = [
        {
          selector: '*[data-step-1]',
          content: 'Hey! Welcome to RevUI Studio!  This is your first time right? Let us show you around.',
        },
        {
            selector: '*[data-step-2]',
            content: 'This is where the magic happens.  You can change the UI using this panel.  We have tried to put in as many options for you, but not so many that it gives you a headache.',
          },
        {
            selector: '*[data-step-3]',
            content: 'Here is a preview of what the campaign will look like.  If you move your mouse around it you will notice you can edit certain sections. When you click the pencil button, it will change the panel on the left to the options for this area.',
          },
          {
            selector: '*[data-step-4]',
            content: 'Once you\'re done editing, click here. Don\'t worry, you can get straight back in if you want and even after you have created the campaign you can get back here and make some more tweaks!',
          },
          {
            selector: '*[data-step-end]',
            content: 'That\'s it. Enjoy. Happy RevLifting',
          },
    ]

    // data control state
    // let [dataControlModel, setDataControlModel] = useState(props.dataControlModel);
    let [previewSrc, setPreviewSrc] = useState("");

    let onMessageReceivedFromIframe = function(e) {
        let m = e.data;
        if (m.action === 'edit-block') {
            setIsSideBarRoot(props.instance === m.id.instance);
            setCurrentEditInstance(m.id);
        }
    }


    useEffect(() => {
        window.addEventListener('message', onMessageReceivedFromIframe, false);
        window.parent.postMessage({action: 'revstudio-loaded'}, '*')
    },[])


    if (!inspect && props.dataControlModel) {
        (new Api()).templateInspect(props.instance, true, props.dataControlModel.current.ref).then((data) => {
            let inspect = data.instanceInspect[0].inspect[0];
            setInspect(inspect);
            if (inspect.schema.length === 1 && inspect.schema[0].id === "primary_template_repeater") {
                // we only have one item in the schema and it's the primary repeater, set the edit instance to this instance
                try {
                    let templates = JSON.parse(inspect.schema[0].value);
                    if (templates.length > 0) {
                        setCurrentEditInstance({instance: templates[0]});
                    }
                } catch (e) {
                    // console.log("Template repeater has invalid JSON");
                }
            }
        });
    }

    function getPreviewSrc({urlRoot, props}) {

        let src = urlRoot + '/render';
        const q = new URLSearchParams();
        q.append('instance', props.instance);
        if(props.campaignUuid){
            q.append('campaign', props.campaignUuid);
        }
        if(props.offerIds){
            q.append('offerIds', props.offerIds);
        }
        q.append('editMode', 1);
        q.append('layoutMode', 1);

        if (global.env.dataScopeRef) {
            q.append('dataScopeRef', global.env.dataScopeRef);
        }
        else if (props.dataControlModel.publish.state === 1) {
            q.append('data', JSON.stringify(props.dataControlModel.publish.data));
            if (props.dataControlModel.publish.publishRef) {
                q.append('dataScopeRef', props.dataControlModel.publish.publishRef);
            }
        } else {
            if (props.dataControlModel.current.ref) {
                q.append('dataScopeRef', props.dataControlModel.current.ref);
            }
        }
        
        if (props.dataControlModel.time.enabled && props.dataControlModel.time.value) {
            q.append('timeTravel', props.dataControlModel.time.value);
        }

        if (window.localStorage.getItem('revui_cookies')) {
           q.append('cookies', btoa(window.localStorage.getItem('revui_cookies')));
        }

        // make re-render the iframe on every render
        q.append('r', props.previewRefresh);
        q.append('jwt', global.env.jwt);
        if (global.env.globalVariables) {
            q.append('globalVariables', global.env.globalVariables)
        }
        src += '?' + q.toString();

        return src;
    }

    let src = getPreviewSrc({urlRoot: global.env.api_endpoint, props});
    if (previewSrc !== src) {
        setPreviewSrc(src);
    }

    let reloadPreview = () => {
        document.getElementById('preview-frame').src = document.getElementById('preview-frame').src
    }

    function close() {
        window.parent.postMessage({action: 'close-revstudio', context: 'DONE'}, '*');
    }

    function closeTour() {
        setIsTourOpen(false);
    }

    function openTour() {
        setIsTourOpen(true);
    }

    function onGoBack() {
        setCurrentEditInstance({instance: props.instance});
        setIsSideBarRoot(true);
    }

    const classes = useStyles();


    return <div className={classes.previewPanelContain}>
         <Tour
        accentColor={"#EA4E8F"}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeTour} />
        { cookieManagerOpen && <CookieManager onClose={() => {setCookieManagerOpen(false)}}/>}
        { currentEditInstance && <EditModalSidebar event={props.event} onGoBack={onGoBack} isRoot={isSideBarRoot}  mode={global.env.mode} id={currentEditInstance} onSidebarClose={() => {}} onSidebarOpening={()=>{}} dataScope={props.dataControlModel.current} noSecurityContext={(inspect && inspect.instanceSecurityContext) ? 0 : 1}/> }
        <div className={classes.previewPanel}>
        <AppBar className={classes.appBar} position="static">
            <Toolbar>
                <div className={classes.toolbarLeft}>
                <Button onClick={openTour}><HelpOutlineIcon/>&nbsp;&nbsp;Help</Button>
                {/* <Tooltip title="Cookie Manager" aria-label="Cookie Manager"> */}{/* </Tooltip> */}
                <Button  onClick={(e) => {setCookieManagerOpen(!cookieManagerOpen)}}><StorageIcon/>&nbsp;&nbsp;Cookie manager</Button>
                <Button onClick={(e) => {reloadPreview()}}><RefreshIcon/>&nbsp;&nbsp;Refresh preview panel</Button>
                </div>
                

                <div className={classes.toolbarRight}>
                    <div className={classes.responsiveSelectors}>
                        <span style={{fontSize: 14}}>Preview as: </span>
                        <span className={classNames(classes.responsiveButton, responsiveMode === "desktop" && 'selected')} onClick={() => {setResponsiveMode('desktop')}}><DesktopWindowsIcon className={classNames(classes.responsiveButtonIcon, responsiveMode === "desktop" && 'selected'  )}/> <span>Desktop</span> </span> 
                        <span className={classNames(classes.responsiveButton, responsiveMode === "tablet" && 'selected')} onClick={() => {setResponsiveMode('tablet')}}><TabletMac className={classNames(classes.responsiveButtonIcon, responsiveMode === "mobile" && 'selected')}/> <span>Tablet</span> </span> 
                        <span className={classNames(classes.responsiveButton, responsiveMode === "mobile" && 'selected')} onClick={() => {setResponsiveMode('mobile')}}><PhoneIphone  className={classNames(classes.responsiveButtonIcon, responsiveMode === "mobile" && 'selected')}/> <span>Mobile</span> </span> 
                    </div>
                </div>
                <Button data-step-4 variant="contained" color="secondary" onClick={close}>Done Editing</Button>

            </Toolbar>
        </AppBar>
        <RenderTopBar model={props.dataControlModel} onChange={props.onDataControlModelChange}></RenderTopBar>
        

        <div className={classes.previewHolder}>
            <iframe data-step-3 title="preview" id="preview-frame" name="preview-frame" className={classNames(classes.iframe, responsiveMode)} src={previewSrc} frameBorder={0} width={"100%"}></iframe>
        </div>
    </div>
    </div>
}

function RenderTopBar({model, onChange}) {

    let msg = [];
    if (model.publish) {
        let {state, request} = model.publish;
        if (state === 1) {
            msg.push('Approve the publish preview.');
            msg.push(<Fragment>
                <Button style={{margin:'8px'}} variant="contained" color="primary" onClick={e => {
                    (new Api()).dataPublish({...request, persist:1}).then((data) => {
                        if (data.publish.persisted) {
                            // success
                            onChange({
                                publish: {state: 2},
                                current: data.publish.switchCurrent
                            });
                        }
                    });
                }
                }>Accept</Button>
                <Button style={{margin:'8px'}} variant="contained" color="primary" onClick={e => onChange({publish:{state:0}})}>Cancel</Button>
            </Fragment>);
        } else if (state === 2) {
            msg.push('Publish successful.',<Button onClick={e => onChange({publish:{state:0}})}>Ok</Button>);
        }
    }

    return <Fragment>
        {msg.length > 0 && <div style={{color:'black', padding:'8px', margin: '12px 40px', backgroundColor:'#ffffea', border: '1px solid #ffe6b9'}}>{msg}</div>}
    </Fragment>;
}




export default PreviewView;