import React, {useState, useEffect} from "react";
import { AppBar, Toolbar, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from '../Share/api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import TemplateModifyStyled from '../Components/TemplateModify';

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

function Admin(props) {

    let [instances, setInstances] = useState([]);
   // let [editorShow, setEditorShow] = useState(false);
    
    
    const classes = useStyles();
    
    
    useEffect(() => {
        let fetchData = async () => {
            let browse = await (new Api()).instanceBrowseByTag('usable');
            let instances = browse.map((instance) => {
                let label = instance.values.find((v) => { return v.name === '_label';  } );
                if (label) {
                    return {
                        "label": label.value,
                        "instance": instance.instance
                    }
                } else {
                    return null;
                }
                
            });

            console.log(instances);

            instances.sort((a,b) => {
                return a.label > b.label ? 1 : -1;
            });
            setInstances(instances);
        }
        fetchData();
    }, []);


    return  <>
        <AppBar position="static">
            <Toolbar>
                <Typography component="h2" variant="h5">RevUI Admin</Typography>
            </Toolbar>
        </AppBar>
        
        <Paper className={classes.paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Instance</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Edit</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {instances.map((instance) => (
                    <TableRow key={instance.instance}>
                        <TableCell>{instance.instance}</TableCell>
                        <TableCell>{instance.label}</TableCell>
                        <TableCell>a</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Paper>

        {/* editorShow &&  <TemplateModifyStyled fullScreenMode={true} id={id} inspect={inspect} templateInspect={templateInspect} templateName={this.state.inspect.id.templateName}
                                   onBranchBlock={this.onBranchBlock} onClose={this.handleAdminClose} onFullScreenToggle={this.fullScreenToggle}
                onSave={this.onSaveTemplate}></TemplateModifyStyled>*/} 
        
    </>
}

export default Admin;