
const liveBranch = 'live';
const branchPrefix = 'BB__'
const branchHelper = {
    isBranch: (dataScopeReference) => {
        return branchHelper.branchNameFromRef(dataScopeReference) !== null;
    },
    branchNameFromRef: (dataScopeReference) => {
        let test = dataScopeReference || '';
        if (!test || test === liveBranch) {
            return liveBranch;
        } else if (test.substring(0, 4) === branchPrefix) {
            return test.substring(4, test.length);
        }
        return null;
    },
    isLive: (name) => name === liveBranch
};

module.exports = branchHelper;