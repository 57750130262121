// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "../../../../Components/Inputs/Styles/TextField";
import { FieldWrapper } from "react-forms-processor";
import type { FieldDef } from "react-forms-processor";

const styles = theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    marginBottom: "0px",
    outline: "none !important",
    width: "100%",
    paddingLeft: "0px"
  },
  menu: {
    width: 200
  }
});

class MaterialUiTextField extends React.Component {

  
  render() {
    // $FlowFixMe - HOC adds this class
    const classes = this.props.classes;

    const {

      description,
      disabled,
      errorMessages,
      id,
      isValid,
      name,
      onFieldChange,
      placeholder,
      required,
      value,
      label
    } = this.props;

    return (
      <TextField
        autoComplete="off"
        key={id}
        name={name}
        label={label}
        className={classes.textField}
        placeholder={placeholder}
        description={description ? description : ""}
        helpertext={isValid ? description : errorMessages}
        disabled={disabled}
        required={required}
        error={!isValid ? "true" : "false"}
        onChange={(evt: any) => onFieldChange(id, evt.target.value)}
        value={value === null ? '' : value}
        multiline={this.props.type === "textarea" ? "true" : "false"}
      />
    );
  }
}

const StyledMaterialUiTextField = withStyles(styles)(MaterialUiTextField);

export default (props: FieldDef) => (
  <FieldWrapper {...props}>
    {/* $FlowFixMe */}
    <StyledMaterialUiTextField />
  </FieldWrapper>
);
