import {withStyles} from '@material-ui/core/styles';
import React, {useState} from "react";
import RevTextEditor from "../Components/RevTextEditor";
import Grid from '@material-ui/core/Grid';
import '../Views/EditModel.css';
import BlockSettings from "../Components/BlockSettings";
import { AppBar, Tab, Tabs, Typography, Select, MenuItem, Button} from '@material-ui/core';

let modalBodyClasses = {
    modalBody: {
        padding: "20px"
    },
    select: {
        background: '#FFF',
        padding: "0 20px"
    }
};

function createSnippets(snippets) {
    return (Array.isArray(snippets) ? snippets : [snippets])
        .map(({ name, code }) =>
            [
                'snippet ' + name,
                code
                    .split('\n')
                    .map(c => '\t' + c)
                    .join('\n'),
            ].join('\n')
        )
        .join('\n') 
}

function TemplateModify(props) {


    let debug = window.debug('app:BlockModifyTemplate');
    debug({blockModify: props});

    let [modifyTab, setModifyTab] = useState(0);
    let [editorValues, setEditorValues] = useState(null);
    let [forceEdit, setForceEdit] = useState(false);
    let [styleVars, setStyleVars] = useState(null);
    let [editorTheme, setEditorTheme] = useState('dracula');
    let [stylusSnippets, setStylusSnippets] = useState('dracula');

    if (!editorValues) {
        editorValues = {};
        editorValues.twig = props.inspect.config.template;
        editorValues.stylus = props.inspect.config.config.styl;
        editorValues.script = props.inspect.config.config.script;
        editorValues.json = JSON.stringify(props.inspect.config.config.values);
        editorValues.muiIcon = props.inspect.config.config.muiIcon;
        editorValues.title = props.inspect.config.config.title;
        editorValues.description = props.inspect.config.config.description;
        editorValues.tags = props.inspect.config.config.tags;
        editorValues.archived = props.inspect.config.config.archived;
        editorValues.renderVersion = props.inspect.config.config.renderVersion;
        editorValues.renderVersion = props.inspect.config.config.styleCompiler;
        editorValues.fieldUI = JSON.stringify(props.inspect.config.config.fieldUI);
    }

    if (!styleVars && props.templateInspect && props.templateInspect.helpers && props.templateInspect.helpers.styleVars) {
        setStyleVars(props.templateInspect.helpers.styleVars);
        let snippets = [];
        props.templateInspect.helpers.styleVars.forEach((item) => {
            item.vars.forEach((v) => {
                snippets.push({ name: v, code: v});
            })
        });
        setStylusSnippets(createSnippets(snippets));
       
    } 

    function onBranch() {
        if (props.onBranchBlock) {
            let newBranchName = window.prompt('New branch name?', Math.round(Math.random() * 99999));
            props.onBranchBlock(newBranchName);
        }
    }

    function onForceEdit() {
        if (!forceEdit) {
            setForceEdit(true);
        }
    }

    function handleChange(event, value) {
        setModifyTab(value);
    }

    function onChange(what, newVal) {
        let v = editorValues;
        v[what] = newVal;
        setEditorValues(v);

        //console.log(what, newVal, editorValues);
    }

    forceEdit = true;

    // const { classes} = props;
    if (forceEdit || (props.inspect && props.inspect.id.branch)) {
        // show template edit control
        return <div >
            <AppBar position="static" style={{backgroundColor: "#98D4D5", color: "#043456"}}>
                <Tabs value={modifyTab} onChange={handleChange}>
                    <Tab label="Template"/>
                    <Tab label="Styles"/>
                    <Tab label="Node"/>
                    <Tab label="Fields"/>
                    <Tab label="FieldUI"/>
                    <Tab label="Settings"/>
                </Tabs>
            </AppBar>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <AppBar position="fixed" style={{
                        top: '0',
                        padding: "12px 0",
                        height: "70px",
                        backgroundColor: "#13364F"
                    }}>
                        <div style={{width: "100%", display: "flex"}}>
                           <div style={{float: "left", display:"flex"}}>
                                {!props.standalone &&
                                <Button variant="outlined" color="secondary" onClick={props.onFullScreenToggle} style={{marginLeft: 24, cursor: 'pointer'}}>
                                {!props.fullScreenMode &&
                                <i title="Enter fullscreen mode" className="material-icons">fullscreen</i>}
                                {props.fullScreenMode &&
                                <i title="Exit fullscreen mode" className="material-icons">fullscreen_exit</i>}
                                </Button>
                                }
                                <Button variant="outlined" color="secondary" onClick={props.onClose} style={{cursor: 'pointer'}}><i
                                    className="material-icons">close</i></Button>
                                <Typography  style={{marginLeft: "24px", paddingTop: "8px"}} variant="h5" color="inherit"><strong>{ props.templateName }</strong> admin</Typography>
                                </div>
                           
                            <div style={{float:"right", display:"flex", flex: 1, justifyContent: 'flex-end', paddingRight: "24px"}} >
                                <Select style={{marginRight: "48px"}} className={props.classes.select} id="blah" label="Theme" onChange={(e) => {setEditorTheme(e.target.value)}} value={editorTheme}>
                                    <MenuItem value="dracula">Dark</MenuItem>
                                    <MenuItem value="xcode">Light</MenuItem>
                                    <MenuItem value="terminal">Terminal</MenuItem>
                                </Select>
                                {
                                    props.onBranchBlock
                                    && <Button style={{marginRight: "12px"}} variant="contained" color="primary"
                                            onClick={onBranch}>Branch & edit</Button>
                                }
                                <Button  variant="contained" color="secondary"
                                            onClick={(e) => props.onSave('template', editorValues)}>Save</Button></div>
                        </div>
                    </AppBar>
                </Grid>

                <Grid item xs={12}>
                        {modifyTab === 0 && <RevTextEditor mode={"twig"} value={editorValues.twig} theme={editorTheme} onChange={(v) => {
                            onChange('twig', v)
                        }}></RevTextEditor>}
                        {modifyTab === 1 &&
                        <RevTextEditor mode={"stylus"} snippets={stylusSnippets} value={editorValues.stylus} theme={editorTheme} onChange={(v) => {
                            onChange('stylus', v)
                        }}></RevTextEditor>}
                        {modifyTab === 2 &&
                        <RevTextEditor mode={"javascript"} value={editorValues.script} theme={editorTheme} onChange={(v) => {
                            onChange('script', v)
                        }}></RevTextEditor>}
                        {modifyTab === 3 && <RevTextEditor mode={"json"} value={editorValues.json} theme={editorTheme} onChange={(v) => {
                            onChange('json', v)
                        }}></RevTextEditor>}
                        {modifyTab === 4 && <RevTextEditor mode={"json"} value={editorValues.fieldUI} theme={editorTheme} onChange={(v) => {
                            onChange('fieldUI', v)
                        }}></RevTextEditor>}
                        {modifyTab === 5 && <BlockSettings onChange={(n, v) => {
                            onChange(n, v)
                        }} value={editorValues}></BlockSettings>}
                </Grid>
            </Grid>        
        </div>
    }
    return <div className={props.classes.modalBody}>
        <Button variant="contained" color="primary" onClick={onBranch}>Branch & edit</Button>
        <Button variant="contained" color="primary" onClick={onForceEdit}>Edit reusable template</Button>
    </div>;
}

let TemplateModifyStyled = withStyles(modalBodyClasses)(TemplateModify);

export default TemplateModifyStyled;