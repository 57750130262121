/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import { InputLabel, Button, Grid, Card, CardContent, CardActions, Typography, Select, MenuItem} from '@material-ui/core';
import Size from './Size';
import ColorPicker from 'material-ui-color-picker';


const styles = theme => ({
    
});


class BoxModel extends React.Component {
    state = {
        inputValue: this.props.value,
        margin: {
            l: "0px", r:"0px", t:"0px", b:"0px"
        },
        padding: {
            l: "0px", r:"0px", t:"0px", b:"0px"
        },
        border: {
            l: "0px", r:"0px", t:"0px", b:"0px", color: '#333', style: 'solid'
        },        
        editor: false,
        editorProperty: null
    };

    componentDidMount = () => {
        let { inputValue } = this.state;

        if (inputValue) {
            let val = JSON.parse(inputValue);
            if (val) {
                let data = JSON.parse(val.data);
                this.setState({
                    margin: data.margin || this.state.margin, 
                    padding: data.padding || this.state.padding, 
                    border: data.border || this.state.border, 
                });
            }
        }
        
    }

    showEditor = (e, property) => {
        e.stopPropagation();
        this.setState({editor: true, editorProperty: property});
    }

    updateProperty = (property1, property2, value) => {
        let property = this.state[property1];
        property[property2] = value;
        this.setState({[property1]: property});
    }

    onChange = () => {
        let { margin, padding, border} = this.state;
        let value = {
            "set": `'${margin.l}'`,
            "data": JSON.stringify({margin,padding,border}),
            "quoteless":  true,
            "variables": {
                marginLeft: `${margin.l}`,
                marginRight: `${margin.r}`,
                marginBottom: `${margin.b}`,
                marginTop: `${margin.t}`,
                paddingLeft: `${padding.l}`,
                paddingRight: `${padding.r}`,
                paddingBottom: `${padding.b}`,
                paddingTop: `${padding.t}`,
                borderLeft: `${border.l}`,
                borderRight: `${border.r}`,
                borderBottom: `${border.b}`,
                borderTop: `${border.t}`,
                borderColor: `${border.color}`,
                borderStyle: `${border.style}`

            }
        };
        this.props.onChange(value);
    }

    render() {
        let { editor, editorProperty, margin, padding, border } = this.state;
        const {classes,  label} = this.props;

        return (
            <div className={classes.root}>
                <NoSsr>
                
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <InputLabel>{label}</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <div class="w3-boxmodel">
                                { editor && <div class="boxmodel-editor">
                                    <Card style={{width: "100%"}}>
                                        <CardContent>
                                            <Grid item xs={12}>
                                                <Typography variant="h5">{editorProperty.toUpperCase()}</Typography>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                { editorProperty === "border" && 
                                                 <Grid item xs={6}>  
                                                    <InputLabel style={{marginTop: 18}}  id="border-style">Style</InputLabel>
                                                    <Select onChange={(e) => { this.updateProperty(editorProperty, 'style', e.target.value) }} value={border.style} id="border-style" label={"Border Style"}>
                                                        <MenuItem value={"solid"}>Solid</MenuItem>
                                                        <MenuItem value={"dashed"}>Dashed</MenuItem>
                                                        <MenuItem value={"dotted"}>Dotted</MenuItem>
                                                    </Select>
                                                 </Grid>
                                                }
                                                { editorProperty === "border" && 
                                                 <Grid item xs={6}>
                                                    <ColorPicker onChange={(v) => { this.updateProperty(editorProperty, 'color', v) }} value={border.color}  label="Border Color"></ColorPicker>
                                                 </Grid>
                                                 
                                                }
                                               
                                                <Grid item xs={6}>
                                                    <Size label={"Top " + editorProperty} initialValue={this.state[editorProperty].t} value={this.state[editorProperty].t} onChange={(v) => { this.updateProperty(editorProperty, 't', v) }}></Size>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Size label={"Right " + editorProperty} initialValue={this.state[editorProperty].t}  value={this.state[editorProperty].r} onChange={(v) => { this.updateProperty(editorProperty, 'r', v) }}></Size>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Size label={"Bottom " + editorProperty} initialValue={this.state[editorProperty].t}  value={this.state[editorProperty].b} onChange={(v) => { this.updateProperty(editorProperty, 'b', v) }}></Size>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Size label={"Left " + editorProperty}  initialValue={this.state[editorProperty].t}  value={this.state[editorProperty].l} onChange={(v) => { this.updateProperty(editorProperty, 'l', v) }}></Size>
                                                </Grid>
                                               
                                            </Grid>
                                                
                                        </CardContent>
                                        <CardActions>
                                            <Button color="primary" variant="contained"  onClick={() => { this.setState({editor: false}); this.onChange()}}>Save</Button>
                                        </CardActions>
                                    </Card>    
                                </div>}
                                <div class="margin" onClick={(e) => {this.showEditor(e, 'margin')}}>
                                    <div class="ml label">{margin.l}</div>
                                    <div class="mr label">{margin.r}</div>
                                    <div class="mt label">{margin.t}</div>
                                    <div class="mb label">{margin.b}</div>
                                    <div class="border w3-theme"  onClick={(e) => {this.showEditor(e, 'border')}}>
                                        <div class="bl label">{border.l}</div>
                                        <div class="br label">{border.r}</div>
                                        <div class="bt label">{border.t}</div>
                                        <div class="bb label">{border.b}</div>
                                        <div class="padding"  onClick={(e) => {this.showEditor(e, 'padding')}}>
                                            <div class="pl label">{padding.l}</div>
                                            <div class="pr label">{padding.r}</div>
                                            <div class="pt label">{padding.t}</div>
                                            <div class="pb label">{padding.b}</div>
                                            <div class="content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </NoSsr>
            </div>
        );
    }
}

BoxModel.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(BoxModel);
