import React, { useState, useEffect } from "react";
import Api from "../Share/api";
import { Form } from "react-forms-processor";
import { fieldRenderer } from "../Share/formFieldRenderer";
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Button, Typography, Snackbar } from '@material-ui/core';
import debounce from "../Share/debounce";
import attachHelper from "../Share/attachHelper";
import filterSchemaForUi from "../Share/filterSchemaForUi";
import UserError from '../Share/userError';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Collapse} from 'react-collapse';

const debug = require('debug')('app:rev-ui:schemaAttachEditor');

const useStyles = {
    card: {
        minWidth: 275,
        border: "1px solid #eee",
        padding: "8px",
        marginBottom: "8px",
    },
    cardGridItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    menuItem: {
        background: "#fcfcfc",
        color: "#13364F",
        /*boxShadow: "0 0 6px -2px #333",*/
        fontSize: "16px",
        cursor: "pointer",
        padding: 18,
        '&:hover': {
            background: "#13364F",
            color: '#98D4D5'
        },
        transition: "all 0.2s",
        boxShadow: "0 0 4px 0 rgba(152,212,213,0.36)"
    },
    rightArrow: {
        float: "right",
        color: '#73BBBD'
    },
    backButton: {
        cursor: "pointer",
        marginBottom: 12
    },
    fieldContainer: {
        marginTop: 20
    },
    fieldCard: {
        marginBottom: 12
    },
    introBody: {
        paddingBottom: 24
    }
};


// prepare templateInspect api response for use in the ui -> organize all schemas under a template key
const convertDataForUi = function (data) {
    let index = {};
    if (!data) {
        throw new UserError('convertDataForUi called with wrong data');
    }
    for (let templateData of data.templatesSchemas) {

        index[templateData.templateName] = {
            templateName: templateData.templateName,
            instanceLevelVars: [],
            templateLevelVars: filterSchemaForUi(templateData.schemas, templateData.configValues)
        };
    }

    // do the instance level schemas
    for (let rootInstances of data.instanceInspect) {
        for (let inspect of rootInstances.inspect) {
            for (let schema of inspect.schema) {
                schema = filterSchemaForUi([schema], inspect.config.config.values);
                if (schema.length) {
                    // attach to templates
                    index[inspect.row.name].instanceLevelVars.push({ instance: inspect.instance, schema: schema[0] });
                }
            }
        }
    }

    return index;
};

/**
 * onSave ... for saving that is on the parent
 * templateData ... all data for the template
 * propName ... rendered template level schema -> so we know the prop
 */
function BlockProps({ onSave, saveRow, templateData, propName }) {
    const TEMPLATE_OPT = '__template';
    // track state of the select control
    let [selected, setSelected] = useState(null);

    // don't render junk
    if (templateData && propName) {

        // generate select element options
        let options = [];
        let templateOptSchema = templateData.templateLevelVars.filter(x => x.name === propName).reduce((o, x) => o = x, null);

        if (!templateOptSchema) {
            //throw new Exception('Can not find the template level opt for prop ' + propName);
        }
        options.push({ label: 'template level', value: TEMPLATE_OPT, data: { schema: templateOptSchema } });
        for (let instanceSchema of templateData.instanceLevelVars) {
            options.push({ label: `instance: ${instanceSchema.instance}`, value: instanceSchema.instance, data: { instance: instanceSchema.instance, schema: instanceSchema.schema } });
        }

        // set the initial component state
        // default to template level value
        useEffect(() => { setSelected(options[0].value); }, []);

        // construct form schema variable
        let defaultFields = [];
        if (selected !== null) {
            defaultFields = [options
                .filter(x => x.value === selected)
                .reduce((o, x) => o = x, null)
                .data.schema
            ];
            defaultFields[0].saveRow = saveRow; // give to the option to perist the value manually
        }
        
        // we can not rerender a html every time it triggers an onChange and consequently a rerender. So We now supper
        // a property that can instagate a rerender irespectively of the value
        // FIXED - not needed as we recreate the form on "selected" change - add prop "key" to component
        // if (defaultFields.length) {
        //     defaultFields[0].options = Object.assign(defaultFields[0].options || {}, {watchValueUpdate: selected});
        // }

        return <div>

            {/*<strong>{ propName }</strong><br/>*/}
            <Grid container>
                <Grid item xs={12}>
                    {/*<Select
                            value={selected}
                            onChange={onSelectedChange}
                            >
                            {options.map((opt) => <MenuItem key={opt.label} value={opt.value}>{opt.label}</MenuItem>)}
                        </Select>*/}

                    <Form
                        defaultFields={defaultFields}
                        renderer={fieldRenderer}
                        key={selected}
                        onChange={value => {
                            onSave('prop', value, selected !== TEMPLATE_OPT && selected, templateData);
                        }}
                    >
                    </Form>
                </Grid>
            </Grid>

        </div>;
    }
    return <div style={{ padding: "22px" }}>No configurable properties</div>;
}

class SchemaAttachedEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            externalId: props.match.params.externalId,
            instances: props.match.params.instances.split(','),
            attached: props.match.params.attached,
            editableProperties: null,
            attachValues: null,
            showPropertySavedSnackbar: false,
            currentMenuPath: '',
            dataToSave: []
        };

        this.autoTempSave = debounce(this.autoTempSave, 500);
    }

    /**
     * A WAY TO SAVE 1
     * value: {prop: value}
     * instance: instance to save against, string
     * templateData: converted templateInspect data under one template
     */
    autoTempSave = (type, valueObj, instanceString, templateData) => {
        window.parent.postMessage({ revui: { handle: 'form-dirty' } }, '*');

        let row = this.generateSaveRow(type, valueObj, instanceString, templateData);

        this.setState((state, props) => { 
            return { dataToSave: [...state.dataToSave, row] }; });
    };

    generateSaveRow = (type, valueObj, instanceString, templateData) => {
        debug(this.props);
        let [propName, value] = Object.entries(valueObj)[0];

        // generate the row properties to save
        let name = attachHelper.generateAttachName({ attachName: this.state.attached, attachId: this.state.externalId, propName });
        let saveValue = value;
        let t = Date.now();
        let scope, instance;
        if (instanceString) {
            // TODO to remove - never used
            // instance level value
            // scope = null; // not required
            instance = instanceString;
        } else {
            // securityContext level
            scope = this.props.securityContext;
            instance = scope;
        }
        return { name, value: saveValue, instance, scope, t };
    };

    saveAction = () => {
        const { dataToSave } = this.state;
        const informParentWeAreSaved = () => {
            window.parent.postMessage({ action: 'saved' }, '*');
        };
        if (dataToSave && dataToSave.length) { // we have something to save
            (new Api()).save(dataToSave, "live").then((saved) => {
                informParentWeAreSaved();
                this.reloadData();
                this.setState({
                    showPropertySavedSnackbar: true,
                    dataToSave: []
                });
            });
        } else {
            informParentWeAreSaved();
        }
    };

    generateManualSaveRow = (propName) => {
        return this.generateSaveRow('', { value: [propName, ""] }, null, null);
    };

    componentDidMount() {
        this.reloadData();


        var target = document.querySelector("body");
        var observer = new MutationObserver(() => { this.sendHeightToParent(); });
        var config = {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true
        };
        observer.observe(target, config);


        // listen for save action request from parent window
        let self = this;
        window.addEventListener('message', function (e) {
            if (e.data === 'force-save') self.saveAction();
        });
    }

    getDocHeight = () => {
        var body = document.body,
            html = document.documentElement;
        return Math.max(body.scrollHeight, body.clientHeight,
            html.clientHeight, html.scrollHeight, html.clientHeight);
    };

    sendHeightToParent() {
        window.parent.postMessage({ revui: { handle: 'height', height: this.getDocHeight() } }, '*');
    }

    reloadData = () => {
        (new Api()).templateInspect(this.state.instances, false, "live", null, this.state.attached, this.state.externalId)
            .then(data => this.setState({ editableProperties: convertDataForUi(data) }, () => {
                window.parent.postMessage({ revui: { handle: 'loaded' } }, '*');
                this.sendHeightToParent();
            }));
    };

    handleTabChange = (event, newValue) => {
        this.setState({ tabIndex: newValue }, () => { this.sendHeightToParent(); });
    };


    setMenuItem = (menu, path, value) => {
        var schema = menu;
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {};
            schema = schema[elem];
        }

        schema[pList[len - 1]] = value;
    };

    getMenuItem = (menu, path) => {
        var schema = menu;
        if (path.startsWith('.')) {
            path = path.substring(1, path.length);
        }
        var pList = path.split('.');
        var len = pList.length;
        for (var i = 0; i < len - 1; i++) {
            var elem = pList[i];
            if (!schema[elem]) schema[elem] = {};
            schema = schema[elem];
        }
        let menuItem = schema[pList[len - 1]];
        return menuItem;
    };

    onMenuBack = (e) => {
        let currentMenuPath = this.state.currentMenuPath;
        let newPath = currentMenuPath.split('.');
        newPath.pop();
        if (newPath.length > 1) {
            newPath.pop();
            this.setState({ currentMenuPath: newPath.join('.') });
        } else {
            this.setState({ currentMenuPath: '' });
        }
    };

    menuTitle = (currentMenuPath) => {
        let path = currentMenuPath.split('.');
        return path[path.length - 1];
    };


    getidForCollapse = (key) => {
        return "collpase-"+key.replace(/[^a-z0-9]/gi, '');
    }

    checkConditions = (property, properties) => {

    }

    render() {
   

        let { editableProperties, currentMenuPath, showPropertySavedSnackbar } = this.state;
        let { classes } = this.props;

        function sortByWeight( a, b ) {
            a.weight = a.weight ? a.weight : 0;
            b.weight = b.weight ? b.weight : 0;
            return a.weight > b.weight;
        }    

        function sortByGroupWeight( a, b ) {
            var weightA = a[0].split("_").length == 2 ? a[0].split("_")[0] : 0;
            var weightB = b[0].split("_").length == 2 ? b[0].split("_")[0] : 0;
            return weightA < weightB;
        }   
          

        let collapsableStates = {}
        
        setTimeout(function(){
            this.sendHeightToParent();
        }.bind(this),2000)

        let menu = {};
        if (editableProperties) {


            Object.entries(editableProperties).forEach(([key, templateData]) => {
                if (templateData.templateLevelVars.length > 0) {
                    //Sort by weightings set in field.json of template
                    templateData.templateLevelVars.sort(sortByWeight);

                    templateData.templateLevelVars.forEach((property) => {     
                     
                        this.state.dataToSave.forEach((dirtyProperty)=>{
                            let dirtyPropName = dirtyProperty.name.split("_")[dirtyProperty.name.split("_").length -1];
                            if(dirtyPropName == property.id){
                                property.dirtyValue =  dirtyProperty.value;
                            }
                        })


                        //Conditional Fields
                        //Loop through all other properties to find those with conditionals based on this property            
                        templateData.templateLevelVars.forEach((prop, index) => {
                            if(prop.condition){
                                if(prop.condition.id == property.id){
                                    if(prop.condition.action == "enable"){
                                        templateData.templateLevelVars[index].disable = (property.value != prop.condition.value) && (property.dirtyValue != prop.condition.value);
                                    }
                                    console.log("prop.disable "+prop.id, templateData.templateLevelVars[index].disable)
                                }
                            }
                        })


                        let currentItems = {
                            "noTitleGroup": []
                        };

                        if (!property.hasOwnProperty('attachGroup')) {
                            property.attachGroup = ['uncategorised']; // be lenient and make a default group
                        }

                        if (property.hasOwnProperty('attachGroup')) {
                            if (!Array.isArray(property.attachGroup)) {
                                property.attachGroup = [property.attachGroup]; // be lenient, be nice
                            }

                            let joinSuffix = property.attachGroupType ? "_"+property.attachGroupType : "";            
                            let path = "group." + property.attachGroup.join('.title'+joinSuffix+'.') + '.properties';

                            property.weight = property.weight ? property.weight : 0;
                            currentItems = this.getMenuItem(menu, path);

                            if (currentItems === undefined) {
                                this.setMenuItem(menu, path, []);
                                currentItems = [];
                            } 

                            property.templateData = templateData;

                            if (currentItems.filter((a) => { return a.id === property.id; }).length === 0) {
                                currentItems.push(property);
                            }
                            
                            collapsableStates[property.attachGroup[property.attachGroup.length - 1]] = {
                                opened: "true"
                            }
                            
                            this.setMenuItem(menu, path, currentItems);
                        }

                        
                        return property;
                    });
                }
            });
        }

        return <div className='schema-editor' style={{ padding: 2 }}>
            {/* Render menu items */}
            {currentMenuPath !== '' && <><Button className={classes.backButton} variant="outlined" color="secondary" onClick={this.onMenuBack}>&lt; Back</Button> <Typography className="current-menu__title" variant="h5" style={{ marginBottom: 40 }}>{this.menuTitle(currentMenuPath.split("_")[currentMenuPath.split("_").length - 1])}</Typography></>}
            {this.getMenuItem(menu, currentMenuPath + '.group') && Object.entries(this.getMenuItem(menu, currentMenuPath + '.group')).sort(sortByGroupWeight).map(([key, menuItem]) => {
                return <div key={key} className={classes.menuItem} onClick={() => { this.setState({ currentMenuPath: (currentMenuPath === '' ? 'group.' : (currentMenuPath + '.group.')) + key }); }}> {key.split("_")[key.split("_").length - 1]} <div className={classes.rightArrow}><ArrowForwardIosIcon></ArrowForwardIosIcon></div></div>;
            })}

            {/* If the property has a second attachGroup (denoted by .title) then render this as a list with a heading  */}
            {this.getMenuItem(menu, currentMenuPath + '.title') && Object.entries(this.getMenuItem(menu, currentMenuPath + '.title')).map(([key, menuItem]) => {
                return <Grid item xs={12} key={key} className="title-group">
                    <h3 className="title-group__title">{key.split("_")[key.split("_").length - 1]}</h3>
                    {this.getMenuItem(menu, currentMenuPath + '.title.' +key+ '.properties') && Object.entries(this.getMenuItem(menu, currentMenuPath + '.title.' +key+ '.properties')).map(([k, property]) => {
                        return <Grid item xs={12} key={k}>
                            {!property.disable && <Card style={{ marginBottom: 12 }}>
                            <CardContent>
                                    <BlockProps saveRow={this.generateManualSaveRow(property.id)} onSave={this.autoTempSave} {...this.props} templateData={property.templateData} propName={property.id} />                    
                                </CardContent>
                            </Card>}
                        </Grid>;
                    })}
            </Grid>;
            })} 

            {/* If the property has a second attachGroup (denoted by .title_collapse) then render this as a collapsable list with a heading  */}
            {this.getMenuItem(menu, currentMenuPath + '.title_collapse') && Object.entries(this.getMenuItem(menu, currentMenuPath + '.title_collapse')).map(([key, menuItem]) => {
                return <Grid item xs={12} key={key} className="title-group">
                    <h3 className="collapse-toggle collapsed title-group__title" data-bs-toggle="collapse" role="button" aria-expanded="false"  href={"#"+this.getidForCollapse(key)} data-target={"#"+this.getidForCollapse(key)}>{key.split("_")[key.split("_").length - 1]} <span className="minus">-</span><span className="plus">+</span></h3>
                    <div id={this.getidForCollapse(key)} className="collapse">
                        {this.getMenuItem(menu, currentMenuPath + '.title_collapse.' +key+ '.properties') && Object.entries(this.getMenuItem(menu, currentMenuPath + '.title_collapse.' +key+ '.properties')).map(([k, property]) => {
                            return <Grid item xs={12} key={k}>
                                {!property.disable && <Card style={{ marginBottom: 12 }}>
                                    <CardContent>
                                       <BlockProps saveRow={this.generateManualSaveRow(property.id)} onSave={this.autoTempSave} {...this.props} templateData={property.templateData} propName={property.id} />
                                    </CardContent>
                                </Card>}
                            </Grid>;
                        })}
                    </div>
            </Grid>;
            })} 

            {/* Render list of properties */}
            {this.getMenuItem(menu, currentMenuPath + '.properties') && Object.entries(this.getMenuItem(menu, currentMenuPath + '.properties')).map(([key, property]) => {
                return <Grid item xs={12} key={key}>
                    {!property.disable && <Card style={{ marginBottom: 12 }}>
                        <CardContent>
                            <BlockProps saveRow={this.generateManualSaveRow(property.id)} onSave={this.autoTempSave} {...this.props} templateData={property.templateData} propName={property.id} />
                        </CardContent>
                    </Card>}
                </Grid>;
            })}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={showPropertySavedSnackbar}
                autoHideDuration={1500}
                onClose={() => { this.setState({ showPropertySavedSnackbar: false }); }}
                message="Property updated"
            />
            {/* {editableProperties && <Fab  variant="extended" onClick={this.saveAction} disabled={!dataToSave || !dataToSave.length}>
                <SaveIcon/>
                Save BBo
            </Fab>} */}

        </div>;
    }

}

export { convertDataForUi, BlockProps };

export default withStyles(useStyles)(SchemaAttachedEditor);