/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import ColourPicker from './ColourPicker';
import Size from './Size';
import { MenuItem, Slider, Checkbox} from '@material-ui/core';
import SlideValue from './SlideValue';
import Select from 'react-select';


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },
    fullWidth: {
        width: "100%"
    },

});


class BasicFontStyles extends React.Component {
    
    state = {
        inputValue: this.props.value,
        family: null,
        weight: null,
        families:[{
            value: "primary",
            label: "Primary"
        },
        {
            value: "secondary",
            label: "Secondary"
        }],
        weights:[ {
            value: "wlight",
            label: "Light"
        },
        {
            value: "wnormal",
            label: "Normal"
        },
        {
            value: "wbold",
            label: "Bold"
        }]
    };

    
    onFamilyChange = (val) => {
        this.setState({family: val}, function () {
            this.onAnyChange({family: val}); 
        });
    }
    
    onWeightChange = (val) => {
        this.setState({weight: val}, function () {
            this.onAnyChange({weight: val});
        });
    }

    onAnyChange = () => {
        let payload = {
            family: this.state.family, 
            weight: this.state.weight ? this.state.weight : "", 
            size: this.state.size ? this.state.size : ""
        }
        this.props.onChange(payload);
        this.buildStyles();
    }


    buildStyles = () => {
        let {size, family, weight}  = this.state;
        family = family ? family.value : "";
        weight = weight ? weight.value : "";

        let value = {
            "set": family, 
            "data": JSON.stringify({size, family, weight}),
            "quoteless":  true,
            "variables": {family, size, weight }
        };

        this.props.onChange(value);
    }
    

    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {

            let jsonValue = JSON.parse(value);

            if (jsonValue !== null) {
                let jsonData = JSON.parse(jsonValue.data);

                let family = this.state.families.find(x => x.value === jsonData.family);
                let weight = this.state.weights.find(x => x.value === jsonData.weight);

                this.setState({
                    family: family,
                    weight: weight,
                    size: jsonData.size         
                });
            } 
        }
    }

    render() {
        const { classes, label, id } = this.props;
        const { family, weight, size} = this.state;

      

        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="fontContainer" ></div>
                        <InputLabel className={classes.label}>{label}</InputLabel>
                        <Grid container spacing={3}>
                            <Grid item xs={5}>
                                <InputLabel className="minorLabel">Family</InputLabel>
                                <Select 
                                    value={family} 
                                    id="icon" label={"Family"}
                                    classNamePrefix={"dropdown"}
                                    className={classes.fullWidth}
                                    options={this.state.families}
                                    isSearchable={false}
                                    onChange={(v) => {this.setState({family: v}, () => {this.buildStyles()}); }}
                                />
                                </Grid>
                            <Grid item xs={4}>
                                <InputLabel className="minorLabel">Weight</InputLabel>
                                <Select 
                                    value={weight} 
                                    id="icon" label={"Weight"}
                                    classNamePrefix={"dropdown"}
                                    className={classes.fullWidth}
                                    options={this.state.weights}
                                    isSearchable={false}
                                    onChange={this.onWeightChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel className="minorLabel">Font Size</InputLabel>
                                <Size initialValue={"100%"} value={size} onChange={(v) => {this.setState({size: v}, () => {this.buildStyles()}); }}></Size>
                            </Grid>
                        </Grid>  
                </NoSsr>
            </div>
        );
    }
}


BasicFontStyles.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(BasicFontStyles);

