/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import ColourPicker from './ColourPicker';
import {Select, MenuItem, Slider, Checkbox} from '@material-ui/core';

const Color = require('color');

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },

});


class ButtonComp extends React.Component {
    
    state = {
        inputValue: this.props.value
    };

    buildGradient = () => {

        let {direction, colorStart, colorEnd, textColor, stop, rounding, useGrad} = this.state;
        let gradient  = `${direction}, ${colorStart} ${stop}%, ${colorEnd}`;
    //    this.setState({direction});
        
        let roundingUnit = rounding+"px"

        let value = {
            "set": gradient,
            "data": JSON.stringify({direction, colorStart, colorEnd, textColor, stop, rounding, roundingUnit, useGrad}),
            "quoteless":  true,
            "variables": {direction, colorStart, colorEnd, textColor, stop, rounding, roundingUnit, useGrad}
        };

        this.props.onChange(value);
    }


    handleSliderChange = (e, newValue) => {
        this.setState({stop: newValue}, () => {
            this.buildGradient();
        });
    }

    handleRoundingChange = (e, newValue) => {
        this.setState({rounding: newValue}, () => {
            this.buildGradient();
        });
    }

    onUseGradChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({useGrad: value}, () => {
            this.buildGradient();
        });
    }


    onDirectionChange = (e) => {
        this.setState({direction: e.target.value}, () => {
            this.buildGradient();
        });
   }

    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {
            let jsonValue = JSON.parse(value);

            if (jsonValue !== null) {
                let jsonData = JSON.parse(jsonValue.data);
                this.setState({colorStart: jsonData.colorStart });
                this.setState({colorEnd: jsonData.colorEnd });
                this.setState({direction: jsonData.direction });
                this.setState({stop: jsonData.stop });
                this.setState({rounding: jsonData.rounding });
                this.setState({useGrad: jsonData.useGrad });
                this.setState({textColor: jsonData.textColor });
            }
        }
        
    }

    render() {
        const { classes, label, id } = this.props;
        const { direction, colorStart, colorEnd, textColor, stop, rounding, useGrad} = this.state;

        let min = 0;
        let max = 100;
        let minRnd = 0;
        let maxRnd = 40;

        let gradStyle = {
            width: '70%',
            borderRadius: rounding,
            textAlign: 'center', 
            padding: '10px',
            backgroundImage: useGrad ? 'linear-gradient('+direction+','+colorStart+' '+stop+'%,'+colorEnd+')' : '',
            backgroundColor: useGrad ? '' : colorStart
        }

    

        let btnStyle = {
            color: textColor,
            fontFamily: "sans-serif",
            fontSize: '16px'
        }

        let gradShow = useGrad ? { display: "block"} : { display: "none"}; 
        let gradHide = useGrad ? { display: "none"} : { display: "block"}; 


        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="fontContainer" ></div>
                        <InputLabel className={classes.label}>{label}</InputLabel>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel className="minorLabel">PREVIEW</InputLabel> 
                                <div style={gradStyle}><span style={btnStyle}>BUTTON</span></div>
                            </Grid>

                            <Grid item xs={3}>
                                <InputLabel className="minorLabel">Button Rounding</InputLabel> 
                                <Slider
                                    value={rounding}
                                    step={1}
                                    min={minRnd}
                                    max={maxRnd}
                                    onChange={this.handleRoundingChange}
                                    onBlur={this.onChange}
                                    aria-labelledby="input-slider"
                                    defaultValue={0}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <InputLabel className="minorLabel" >Use a gradient?</InputLabel>
                                <Checkbox value={useGrad} onChange={this.onUseGradChange} checked={this.state.useGrad}/>
                            </Grid>

                            <Grid item xs={4}>
                                <InputLabel className="minorLabel">Text Colour</InputLabel>
                                <ColourPicker
                                        defaultValue={textColor || ''}
                                        onChange={(val, onFieldChange) => {
                                            if (val && isColor(val)) {
                                                this.setState({textColor: val}, () => {this.buildGradient()});
                                            }
                                        }}
                                    /> 
                            
                                </Grid>

                            <Grid item xs={4} style={gradHide}>
                                <InputLabel className="minorLabel">Colour</InputLabel>
                                <ColourPicker
                                        defaultValue={colorStart || ''}
                                        onChange={(val, colorStart) => {
                                            if (val && isColor(val)) {
                                                this.setState({colorStart: val}, () => {this.buildGradient()});
                                            }
                                        }}
                                    /> 
                            </Grid>
                            <Grid item xs={4} style={gradShow}>
                                    <InputLabel className="minorLabel">Gradient Direction</InputLabel> 
                                    <Select value={direction} onChange={this.onDirectionChange} > 
                                        <MenuItem value={"to right"}>left to right</MenuItem>
                                        <MenuItem value={"to left"}>right to left</MenuItem>
                                        <MenuItem value={"to bottom"}>top to bottom</MenuItem>
                                        <MenuItem value={"to top"}>bottom to top</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={4} style={gradShow}>
                                    <InputLabel className="minorLabel">Gradient stop point</InputLabel> 
                                    <Slider
                                        value={stop}
                                        step={1}
                                        min={min}
                                        max={max}
                                        onChange={this.handleSliderChange}
                                        onBlur={this.onChange}
                                        aria-labelledby="input-slider"
                                        defaultValue={0}
                                    />
                                </Grid>
                         
                                <Grid item xs={4} style={gradShow}>
                                    <InputLabel className="minorLabel">Start Colour</InputLabel>
                                    <ColourPicker
                                            defaultValue={colorStart || ''}
                                            onChange={(val, onFieldChange) => {
                                                if (val && isColor(val)) {
                                                    this.setState({colorStart: val}, () => {this.buildGradient()});
                                                }
                                            }}
                                        /> 
                                
                                </Grid>
                                <Grid item xs={4} style={gradShow}>
                                    <InputLabel className="minorLabel">End Colour</InputLabel>
                                    <ColourPicker
                                            defaultValue={colorEnd || ''}
                                            onChange={(val, colorEnd) => {
                                                if (val && isColor(val)) {
                                                    this.setState({colorEnd: val}, () => {this.buildGradient()});
                                                }
                                            }}
                                        /> 
                                
                                </Grid>

                        </Grid>   
                </NoSsr>
            </div>
        );
    }
    
}

function isColor(val) {
    let success = false;
    try {
        Color(val);
        success = true;
    } catch (e) {
    }
    return success;
}

ButtonComp.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(ButtonComp);
