
let debug = window.debug('app:rev-ui:sataScopeHelper');
let branchHelper = require('./branchHelper');

let dataScopeHelper = {
    parseRef:function(ref) {
        let branch = null;
        let sandbox = null;
        let isLive = false;
        if (branchHelper.isBranch(ref)) {
            branch = branchHelper.branchNameFromRef(ref);
            isLive = branchHelper.isLive(branch);
        } else {
            sandbox = ref;
        }
        return {branch, sandbox, isLive};
    }
};

export default dataScopeHelper;