/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {RadioGroup, Radio, Slider, Checkbox} from '@material-ui/core';


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },

});


class SpacingSelect extends React.Component {
    
    state = {
        inputValue: this.props.value
    };

    buildSpacing = () => {

        let {spacing, custom, customVal} = this.state;

        let value = {
            "set": spacing,
            "data": JSON.stringify({spacing, custom, customVal}),
            "quoteless":  true,
            "variables": {spacing, custom, customVal}
        };

        this.props.onChange(value);
    }

    handleSpacingChange = (e, newValue) => {
        this.setState({spacing: newValue}, () => {
            this.buildSpacing();
        });
    }

    onUseCustomChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({useGrad: value}, () => {
            this.buildSpacing();
        });
    }


    onCustomValChange = (e) => {
        this.setState({direction: e.target.value}, () => {
            this.buildSpacing();
        });
   }

    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {
            let jsonValue = JSON.parse(value);

            if (jsonValue !== null) {
                let jsonData = JSON.parse(jsonValue.data);
                this.setState({spacing: jsonData.spacing });
                this.setState({custom: jsonData.custom });
                this.setState({customVal: jsonData.customVal });
            }
        }
        
    }

    render() {
        const { classes, label, id } = this.props;
        const { spacing, custom, customVal} = this.state;

        let rowDir = {
            flexDirection: "row"
        }


        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="spacingContainer" >
                        <InputLabel className={classes.label}>{label}</InputLabel>
                          
                            <Grid container spacing={2}>
                                <RadioGroup
                                    aria-label="Spacing"
                                    name="spacing"
                                    value={spacing}
                                    onChange={this.handleSpacingChange}
                                    style={rowDir}
                                >
                                    <label className={spacing == 'compact' ? 'spacing-radio compact-graphic selected'  : 'spacing-radio compact-graphic '}>
                                        <h3 style={{marginBottom:'22px'}} >Compact</h3>
                                        <span class="gfx-block"></span>
                                        <span class="gfx-block"></span>
                                        <span class="gfx-block"></span>
                                        <FormControlLabel class="spacing-input" value="compact" control={<Radio />} label="Compact" />
                                    </label>
                                    <label className={spacing == 'normal' ? 'spacing-radio normal-graphic selected'  : 'spacing-radio normal-graphic '}>
                                        <h3>Normal</h3>
                                        <span class="gfx-block"></span>
                                        <span class="gfx-block"></span>
                                        <span class="gfx-block"></span>
                                        <FormControlLabel class="spacing-input"  value="normal" control={<Radio />} label="Normal" />
                                    </label>
                                    <label className={spacing == 'super' ? 'spacing-radio super-graphic selected'  : 'spacing-radio super-graphic '}>
                                        <h3 style={{marginBottom:'18px'}}> Super</h3>
                                        <span class="gfx-block"></span>
                                        <span class="gfx-block"></span>
                                        <span class="gfx-block"></span>
                                        <FormControlLabel class="spacing-input"  value="super" control={<Radio />} label="Super" />
                                    </label>
                                </RadioGroup>
                                                        
                            </Grid>   
                        </div>
                </NoSsr>
            </div>
        );
    }
    
}



SpacingSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(SpacingSelect);
