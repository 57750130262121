/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import {Select, MenuItem, TextField, InputLabel, Grid} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NumberField from './NumberField';

const styles = theme => ({
    fieldSize: {
        "& :before, & :after": {
            display: "none"
        }
    },
    staticUnit: {
        fontSize: "14px",
        width: "100%",
        color: "#13364F !important",
        fontWeight: "600",
        lineHeight: "25px",
        textAlign: "center"
    },
    inputContain: {
        flexGrow: 1, 
        flexBasis: "50%", 
        display: "flex", 
        alignItems: "center"
    },
    icon: {
        minWidth: 22,
        height: 22,
        marginRight: 8
    }
});


class Size extends React.Component {
    state = {
        inputValue: this.props.value,
        size: null,
        unit: null,
        units: this.props.units
    };

    componentDidMount = () => {
        let { inputValue } = this.state;
        let units = this.props.units && this.props.units.length > -1 ? this.props.units : ['px'];

        if (typeof inputValue === 'string' || inputValue instanceof String) {
            inputValue = inputValue.replace("null", units[0]);    
            if (this.props.initialValue && inputValue === null){
                inputValue = this.props.initialValue
            }
            let size = inputValue.match(/(\d+)/) ? inputValue.match(/(\d+)/)[0] : "";
            let unit = size && inputValue.substring(size.toString().length);
            unit = unit == "" ? units[0] : unit;
            unit = size && size.length > 0 ? unit : "";
            this.setState({size, unit});
        }

    }

    onChange = () => {
        let { size, unit} = this.state;
        unit = unit === "" && this.props.units ? this.props.units[0] : unit;
        unit = size && size.length > 0 ? unit : "";

        this.props.onChange(`${size}${unit}`);
    }

    onSizeChange = (e) => {
        this.setState({size: e.target.value}, () => {
            this.onChange();
        });
    }

    onUnitChange = (e) => {
        this.setState({unit: e.target.value}, () => {
            this.onChange();
        });
        
    }

    render() {
        const {classes, label, icon} = this.props; //value
        let {size, unit, units} = this.state; 
        if(size === "auto" || unit === "auto"){
            unit = "";
            size = "auto";
        }

        const unitOptions = !units ? [
            "px",
            "em",
            "rem",
            "vh",
            "vw",
            "%",
            "auto",

        ] : units;
        
        //Default units to something
        return (
            <div className={classes.root}>
                <NoSsr>
                    <div>
                        {label && <InputLabel>{label}</InputLabel>}
                        <Grid container  spacing={0} style={{ padding: "0px 0px 0px 8px" ,border: "1px solid #D9DFE3", borderRadius: 4}}>
                            <Grid item className={classes.inputContain}>
                                { icon && <img className={classes.icon} src={icon} />}
                                <TextField onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) {  event.preventDefault(); }}} className={classes.fieldSize} disabled = { unit == "auto" ? true : false}  style={{borderRight: "1px solid #D9DFE3", marginBottom: 0}} onChange={(e) => {this.setState({size: e.target.value})}} onBlur={this.onSizeChange} value={size} placeholder={label} disableUnderline></TextField>
                            </Grid>
                            <Grid item style={{display: "flex", justifyContent: "center", width: 56}}>
                                { unitOptions && unitOptions.length == 1 && <span className={classes.staticUnit}>{unitOptions[0]}</span>}
                                { unitOptions && unitOptions.length > 1 && 
                                    <Select disableUnderline 
                                    value={unit ? unit : unitOptions[0]} 
                                    onChange={this.onUnitChange} 
                                    style={{margin: 0, width: "100%", paddingLeft: 8}}
                                    IconComponent={ExpandMore}
                                    >
                                        {unitOptions.map((unit) => (
                                            <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                                        ))}
                                    </Select> 
                                }
                            </Grid>
                        </Grid>
                    </div>
                </NoSsr>
            </div>
        );
    }
}

Size.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Size);
