/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import {Select, MenuItem, TextField, InputLabel, Grid} from '@material-ui/core';

const styles = theme => ({
    
});


class WidthHeightField extends React.Component {
    state = {
        inputValue: this.props.value,
        width: 20,
        height: 20
    };

    onChange = () => {
        let { width, height } = this.state;
        let value = {
            "set": "",
            "data": JSON.stringify({width,height}),
            "quoteless":  true,
            "variables": {
                width: width + "px",
                height: height + "px"

            }
        };
        this.props.onChange(value);
    }
    onWidthChange = (e) => {
        this.setState({width: e.target.value}, () => {
            this.onChange();
        });
    }
    onHeightChange = (e) => {
        this.setState({height: e.target.value}, () => {
            this.onChange();
        });
    }

    componentDidMount = () => {
        let { inputValue } = this.state;
        if (inputValue) {
            let val = JSON.parse(inputValue);
            if (val) {
                let data = JSON.parse(val.data);
                this.setState({
                    width: data.width || this.state.width, 
                    hieght: data.hieght || this.state.hieght
                });
            }
        }
        
    }

    render() {
        const {classes,  label} = this.props; //value
        let {width, height} = this.state; 


        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel>{label}</InputLabel>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <InputLabel style={{"marginTop":7}} className="minorLabel">Width (px)</InputLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField type="number" style={{margin:0}} onChange={(e) => {this.setState({width: e.target.value})}} onBlur={this.onWidthChange} value={width}></TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={5}>
                                    <InputLabel style={{"marginTop":7}} className="minorLabel">Height (px)</InputLabel>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField style={{margin:0}} type="number" style={{margin:0}} onChange={(e) => {this.setState({height: e.target.value})}} onBlur={this.onHeightChange} value={height}></TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </NoSsr>
            </div>
        );
    }
}

WidthHeightField.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(WidthHeightField);
