import React from "react";
import AceEditor from "react-ace";


import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-searchbox";
import "ace-builds/src-min-noconflict/ext-emmet";

const languages = [
    "javascript",
    "json",
    "twig",
    "stylus",
    "css"
  ];
  
  const themes = [
    "dracula",
    "xcode",
    "tomorrow",
    "terminal",
  ];

languages.forEach(lang => {
    require(`ace-builds/src-noconflict/mode-${lang}`);
    require(`ace-builds/src-noconflict/snippets/${lang}`);
});
themes.forEach(theme => require(`ace-builds/src-noconflict/theme-${theme}`));

const ace = window.ace;

const headerHeight = 64+48;
class RevTextEditor extends React.Component{

    state = {
        editorHeight: docHeight() - headerHeight,
        value: ''
    }

    editor = null;

    constructor(props) {
        super(props);
        this.rootRef = React.createRef();

    }

    registerSnippets = (editor, session, mode, snippetText) => {
        editor.setOptions({
            enableBasicAutocompletion: true,
            enableSnippets: true,
        })
        var snippetManager = ace.require('ace/snippets').snippetManager
        var id = session.$mode.$id || ''
        //TODO Not sure what's going on here, this fixes it for now. 
        if(!snippetManager.files.hasOwnProperty("ace/mode/stylus")){
            snippetManager.files["ace/mode/stylus"] = {};
        }
        var m = snippetManager.files[id]

        console.log(id)
        console.log(snippetManager.files)
        console.log(m)
        m.scope = mode
        m.snippetText = snippetText
        m.snippet = snippetManager.parseSnippetFile(snippetText, m.scope)
        snippetManager.register(m.snippet, m.scope)
    }

   

    componentDidMount() {
        this.setState({value: this.formatCode(this.props.value) || ''});
        let editor = this.editor.editor;
        
        editor.container.style.lineHeight = 1.4
        window.addEventListener('resize', this.handleResize);
        
        if (this.props.snippets) {
            console.log({snippets: this.props.snippets});
            this.registerSnippets(
                editor,
                editor.session,
                this.props.mode,
                this.props.snippets
            )
        }
        

    }


    handleResize = () => {
        this.setState({editorHeight: docHeight() - headerHeight});
    }

    formatCode = (v) => {
        if (this.props.mode === 'twig') {
            return v;
            //return window.html_beautify(v);
        } else if (this.props.mode === 'javascript' || this.props.mode === 'json'){
            return window.js_beautify(v);
        } else {
            return v;
        }
    };

    onChange = (value) => {


        this.setState({value});
        this.props.onChange(value);
    }

    render() {

        let { editorHeight, value } = this.state;
    
        return <AceEditor
                ref={(editor)=>this.editor=editor}
                mode={this.props.mode}
                theme={this.props.theme}
                name=""
                splits={2}
                orientation={"beside"}
                width="100%"
                height= {`${editorHeight}px`}
                onChange={(value) => { this.onChange(value); }  }
                value={value}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                setOptions={{
                    useWorker: false,
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 4,
                    lineHeight: "20px",
                    enableEmmet: true
                }}
                editorProps={{ $blockScrolling: true }} 
                
            />
    }
}

function docHeight() {
    var body = document.body,
    html = document.documentElement;
    return Math.max( body.scrollHeight, body.offsetHeight, 
        html.clientHeight, html.scrollHeight, html.offsetHeight );
}

export default RevTextEditor;