/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ColourPicker from './ColourPicker';
import Size from './Size';
import MediaPicker from '../../MediaPicker';
import FullFontSelector from './FullFontSelector';
import TextField from './TextField';
import {RadioGroup, Radio, Slider, Checkbox, Select, MenuItem} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { BlockPicker } from 'react-color';

const Color = require('color');


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    minorLabel: {
        marginBottom: "8px  !important",
        "font-family": "Mulish",
        "font-size": "13px",
        "font-weight": "600",
        "line-height": "13px",
        "letter-spacing": "0px",
        "text-align": "left"
    },

});


class RoundelLayout extends React.Component {
    
    state = {
        inputValue: this.props.value,
        type:"image"
    };

    buildLayout = () => {

        let {layout, shape, backgroundColor, width, height, xdist, ydist, bannertext, type, imageSize, image, icon, iconSize, iconColor, pulsing} = this.state;
        image = "";
        let value = {
            "set": layout,
            "data": JSON.stringify({layout,shape, backgroundColor, width, height, image: `${image}`, xdist, ydist, bannertext, type, imageSize, icon, iconSize, iconColor, pulsing}),
            "quoteless":  false,
            "variables": {layout, shape, backgroundColor, width, height, xdist, ydist, image: `${image}`, bannertext, type, imageSize, icon, iconSize, iconColor, pulsing}
        };
        this.props.onChange(value);
    }
    handleShapeChange = (e, newValue) => {
        this.setState({shape: newValue}, () => {
            this.buildLayout();
        });
    }   

    handleTypeChange = (e, newValue) => {
        this.setState({type: newValue}, () => {
            this.buildLayout();
        });
    }   

    onImageChange = (val) => {
        this.setState({image: val}, () => {
            this.buildLayout();
        });
   }
   onImageSizeChange = (val) => {
       this.setState({imagesize: val}, () => {
           this.buildLayout();
       });
  }
   
    handleLayoutChange = (e, newValue) => {
        this.setState({layout: newValue}, () => {
            this.buildLayout();
        });
    }

    onTextChange = (e) => {
        this.setState({bannertext: e.target.value}, () => {
            this.buildLayout();
        });
    }
    onUsePulsingChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({pulsing: value}, () => {
            this.buildLayout();
        });
    }

    
    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {
            try {
                let jsonValue = JSON.parse(value);

                if (jsonValue !== null) {
                    let jsonData = JSON.parse(jsonValue.data);
                    this.setState({
                        layout: jsonData.layout,
                        shape: jsonData.shape,
                        backgroundColor: jsonData.backgroundColor,
                        height: jsonData.height,
                        width: jsonData.width,
                        xdist: jsonData.xdist,
                        ydist: jsonData.ydist,
                        image: jsonData.image,
                        bannertext: jsonData.bannertext,
                        type: jsonData.type,
                        imagesize: jsonData.imagesize,
                        icon: jsonData.icon,
                        iconColor: jsonData.iconColor,
                        iconSize: jsonData.iconSize,
                        pulsing: jsonData.pulsing
                    });
                }
            } catch (e) {
                this.setState({layout: value });
            }

        }
        
    }

    render() {
        const { classes, label, id, event } = this.props;
        const { layout, shape, backgroundColor, width, height, xdist, ydist, image, bannertext,type, imageSize, icon, iconColor, iconSize, pulsing} = this.state;

        let rowDir = {
            flexDirection: "row"
        }

      
        let showBannerText = shape == "shape-banner" && type == "create" ? { display: "flex"} : { display: "none"}; 

        let showCreate = type == "create" ? { display: "flex"} : { display: "none"}; 
        let hideCreate = type == "create" ? { display: "none"} : { display: "flex"}; 


        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="RoundelContainer" >
                        <InputLabel className={classes.label}>{label}</InputLabel>

                        <Grid item xs={12}>
                            <RadioGroup
                                aria-label="type"
                                name="type"
                                value={type}
                                onChange={this.handleTypeChange}
                                style={rowDir}
                            >
                                <label>
                                    <FormControlLabel  value="image" id="image" control={<Radio />} label="Image Upload" />
                                </label>
                                <label>
                                    <FormControlLabel   value="create" id="create" control={<Radio />} label="Creator" />
                                </label>
                            </RadioGroup>      
                        </Grid>
                        <Grid container spacing={2} style={hideCreate}>
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        <MediaPicker event={event} value={image} id={id} onChange={this.onImageChange} />
                                    </Grid>      
                                    <Grid item xs={6}>
                                        <InputLabel style={{marginTop: 18}}  id="border-style">Image width</InputLabel>
                                        <Size  value={imageSize} onChange={(v) => {this.setState({imageSize: v}, () => {this.buildLayout()}); }}></Size>
                                    </Grid>   
                                </Grid>              
                            </Grid>
                        </Grid>


                        <Grid container spacing={2} style={showCreate}>
                            <Grid item xs={12}>
                                <InputLabel className="minorLabel">Shape</InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup
                                    aria-label="RoundelShape"
                                    name="RoundelShape"
                                    value={shape}
                                    onChange={this.handleShapeChange}
                                    style={rowDir}
                                >
                                    <label className={shape == 'shape-circle' ? 'layout-radio layout-one-graphic selected'  : 'layout-radio layout-circle-graphic '}>
                                        <span className={"gfx-circle gfx-shape"}></span>
                                        <FormControlLabel className="layout-input" value="shape-circle" control={<Radio />} label="Circle" />
                                    </label>
                                    <label className={shape == 'shape-square' ? 'layout-radio layout-two-graphic selected'  : 'layout-radio layout-square-graphic '}>
                                        <span className={"gfx-square gfx-shape"}></span>
                                        <FormControlLabel className="layout-input" value="shape-square" control={<Radio />} label="Square" />
                                    </label>
                                    <label className={shape == 'shape-triangle' ? 'layout-radio layout-three-graphic selected'  : 'layout-radio layout-triangle-graphic '}>
                                        <span className={"gfx-triangle"}></span>
                                        <FormControlLabel className="layout-input" value="shape-triangle" control={<Radio />} label="Triangle" />
                                    </label>
                                    <label className={shape == 'shape-quarter-circle' ? 'layout-radio layout-three-graphic selected'  : 'layout-radio layout-quarter-circle-graphic '}>
                                        <span className={"gfx-quarter-circle"}></span>
                                        <FormControlLabel className="layout-input" value="shape-quarter-circle" control={<Radio />} label="quarter circle" />
                                    </label>
                                    <label className={shape == 'shape-banner' ? 'layout-radio layout-two-graphic selected'  : 'layout-radio layout-banner-graphic '}>
                                        <span className={"gfx-banner gfx-shape"}></span>
                                        <FormControlLabel className="layout-input" value="shape-banner" control={<Radio />} label="Banner" />
                                    </label>
                                </RadioGroup>         
                            </Grid>         
                        </Grid>         
                        <Grid container spacing={2} style={showBannerText}>
                            <Grid item xs={12}>
                                <InputLabel className="minorLabel">Banner Text</InputLabel> 
                            </Grid>
                            <Grid item xs={12}>
                                <TextField style={{margin:0, minWidth: 40}} onChange={(e) => {this.setState({bannertext: e.target.value})}} onBlur={this.onTextChange}  value={bannertext}></TextField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={showCreate}>
                            <Grid item xs={12}>
                                <InputLabel className="minorLabel">Position</InputLabel>
                            </Grid>     
                            <Grid item xs={12}>              
                             <RadioGroup
                                aria-label="RoundelLayout"
                                name="RoundelLayout"
                                value={layout}
                                onChange={this.handleLayoutChange}
                                style={rowDir}
                            >
                                <label className={layout == 'layout-top-left' ? 'layout-radio layout-top-left-graphic selected'  : 'layout-radio layout-top-left-graphic '}>
                                    <span className={"gfx-top-left gfx-layout " + shape}></span>
                                    <FormControlLabel className="layout-input" value="layout-top-left" control={<Radio />} label="Top Left" />
                                </label>
                                <label className={layout == 'layout-bottom-left' ? 'layout-radio layout-bottom-left-graphic selected'  : 'layout-radio layout-bottom-left-graphic '}>
                                    <span className={"gfx-bottom-left gfx-layout " + shape}></span>
                                    <FormControlLabel className="layout-input" value="layout-bottom-left" control={<Radio />} label="Bottom Left" />
                                </label>
                                <label className={layout == 'layout-top-right' ? 'layout-radio layout-two-graphic selected'  : 'layout-radio layout-top-right-graphic '}>
                                    <span className={"gfx-top-right gfx-layout " + shape}></span>
                                    <FormControlLabel className="layout-input" value="layout-top-right" control={<Radio />} label="Top Right Top" />
                                </label>
                                <label className={layout == 'layout-bottom-right' ? 'layout-radio layout-bottom-right-graphic selected'  : 'layout-radio layout-bottom-right-graphic '}>
                                    <span className={"gfx-bottom-right gfx-layout " + shape}></span>
                                    <FormControlLabel className="layout-input" value="layout-bottom-right" control={<Radio />} label="Bottom Right" />
                                </label>
                            </RadioGroup>     
                            </Grid>                      
                        </Grid>  
                        <Grid container spacing={2} style={showCreate}>
                            <Grid item xs={4}>
                                <InputLabel className="minorLabel">Trigger Icon</InputLabel> 
                                <Select onChange={(e) => {this.setState({icon: e.target.value}, () => {this.buildLayout()}); }}
                                    value={icon} id="icon" label={"Icon image"}>
                                    <MenuItem value={"fa-tag"}>Tag</MenuItem>
                                    <MenuItem value={"fa-trophy"}>Trophy</MenuItem>
                                    <MenuItem value={"fa-star"}>Star</MenuItem>
                                    <MenuItem value={"fa-gift"}>Gift</MenuItem>
                                    <MenuItem value={"fa-gifts"}>Gifts</MenuItem>
                                    <MenuItem value={"fa-piggy-bank"}>Piggy bank</MenuItem>
                                    <MenuItem value={"fa-shopping-bag"}>Shopping bag</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel className="minorLabel">Icon Colour</InputLabel>
                                <ColourPicker
                                    defaultValue={iconColor || ''}
                                    onChange={(val, iconColor) => {
                                        if (val && isColor(val)) {
                                            this.setState({iconColor: val}, () => {this.buildLayout()});
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel className="minorLabel">Icon Size</InputLabel>
                                <Size  value={iconSize} onChange={(v) => {this.setState({iconSize: v}, () => {this.buildLayout()}); }}></Size>
                            </Grid>
                        </Grid>              
                
                        <Grid container spacing={2} style={showCreate}>
                            <Grid item xs={12}>
                                <InputLabel className="minorLabel">Styles</InputLabel> 
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <InputLabel className="minorLabel">Width</InputLabel>
                                        <Size  value={width} onChange={(v) => {this.setState({width: v}, () => {this.buildLayout()}); }}></Size>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel className="minorLabel">Height</InputLabel>
                                        <Size  value={height} onChange={(v) => {this.setState({height: v}, () => {this.buildLayout()}); }}></Size>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel className="minorLabel">X distance</InputLabel>
                                        <Size  value={xdist} onChange={(v) => {this.setState({xdist: v}, () => {this.buildLayout()}); }}></Size>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <InputLabel className="minorLabel">Y distance</InputLabel>
                                        <Size  value={ydist} onChange={(v) => {this.setState({ydist: v}, () => {this.buildLayout()}); }}></Size>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel className="minorLabel">Background Colour</InputLabel>
                                        <ColourPicker
                                            defaultValue={backgroundColor || ''}
                                            onChange={(val, backgroundColor) => {
                                                if (val && isColor(val)) {
                                                    this.setState({backgroundColor: val}, () => {this.buildLayout()});
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InputLabel className="minorLabel">Pulsing</InputLabel>
                                        <Checkbox value={pulsing} onChange={this.onUsePulsingChange} checked={this.state.pulsing}/>
                                    </Grid>


                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </NoSsr>
            </div>
        );
    }
    
}


function isColor(val) {
    let success = false;
    try {
        Color(val);
        success = true;
    } catch (e) {
    }
    return success;
}



RoundelLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(RoundelLayout);
