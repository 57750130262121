/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import MediaPicker from '../../MediaPicker';
import Size from './Size';
import ColorPicker from 'material-ui-color-picker';
import {Select,  MenuItem, InputLabel, Grid, Checkbox} from '@material-ui/core';
const styles = theme => ({
    
});

// CSS SYNTAX
//background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;

function stripQuotes(image) {
    return image.replace(/'/g,'');
}

class AdvancedImage extends React.Component {
    state = {
        inputValue: this.props.value,
        image: '',
        size: '60px'
    };
    componentDidMount = () => {
        let { inputValue } = this.state;

        if (inputValue) {
            let val = JSON.parse(inputValue);

            this.setState(
                {
                    image: val.variables.image,
                    size: val.variables.size
                }
            )
        }

    }
    


    onChange = () => {
         let {image,size} = this.state;
        image = stripQuotes(image);
         let value = {
            "set": `src='${image}' style='width:${size};'`,
            "quoteless":  true,
            "variables": {image, size}
         };
         this.props.onChange(value);
    }


    onSizeChange = (e) => {
         this.setState({size:  e.target.value}, () => {
             this.onChange();
         });
         
    }

    onImageChange = (val) => {
        this.setState({image: val}, () => {
            this.onChange();
        });
   }
   
    render() {
        const {classes, label, id, event} = this.props; //value
        let {image, size} = this.state;
        image = stripQuotes(image);
        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel>{label}</InputLabel>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <MediaPicker event={event} value={image} id={id} onChange={this.onImageChange} />
                        </Grid>      
                        <Grid item xs={6}>
                            <InputLabel style={{marginTop: 18}}  id="border-style">Width</InputLabel>
                            <Size  value={size} onChange={(v) => {this.setState({size: v}, () => {this.onChange()}); }}></Size>
                        </Grid>   
                    </Grid>                
                </NoSsr>
            </div>
        );
    }
}

AdvancedImage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(AdvancedImage);
