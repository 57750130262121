/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import {Select, MenuItem, TextField, InputLabel, Grid} from '@material-ui/core';

const styles = theme => ({
    sizeBox: {
        "margin-left": "8px",
        "padding-right": "32px"
    },
    sizeUnit: {
        "position": "absolute",
        "top": "9px",
        "right": "-28px",
        "font-size": "12px"
    },
    relative: {
        "position": "relative"
    },
    resize:{
        "font-size": "13px"
    }
});


class NumberField extends React.Component {
    state = {
        inputValue: this.props.value,
        size: this.props.initialValue.match(/(\d+)/) ? this.props.initialValue.match(/(\d+)/)[0] : 100,
        min: this.props.min ? this.props.min : 0,
        max: this.props.max ? this.props.max : 100,
        unit: this.props.unit ? this.props.unit : ""
    };

    componentDidMount = () => {
        let { inputValue } = this.state;
        if (typeof inputValue === 'string' || inputValue instanceof String) {
            if (this.props.initialValue && inputValue === null){
                inputValue = this.props.initialValue
            }
            let size = inputValue.match(/(\d+)/) ? inputValue.match(/(\d+)/)[0] : "";
            this.setState({size: size});
        }

    }

    onChange = () => {
        let { size} = this.state;
   
        this.props.onChange(`${size}${this.props.unit}`);
    }

    handleSliderChange = (e, newValue) => {
        this.setState({size: newValue});
    }

    render() {
        const {classes,  label} = this.props; //value
        let {size, min, max, unit} = this.state; 

        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel>{label}</InputLabel>
                    <Grid container spacing={0}>                       
                        <Grid item xs={12} className={classes.relative}>
                            <TextField 
                            type="number" 
                            value={size}  
                            InputProps={{
                                classes: {
                                input: classes.resize,
                                },
                            }}
                            onChange={(e) => {this.setState({size: e.target.value}, () => {this.onChange()})}} 
                            className={classes.sizeBox}/>
                            <span className={classes.sizeUnit}>{unit}</span>
                        </Grid>
                    </Grid>
                </NoSsr>
            </div>
        );
    }
}

NumberField.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(NumberField);
