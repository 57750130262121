import React, {Fragment, useState, useEffect} from "react";
import './PreviewView.css';
import {Tooltip, AppBar, Toolbar, Typography, NativeSelect, Button, Switch, FormControlLabel} from '@material-ui/core';
import Api from "../Share/api";
import CookieManager from '../Components/CookieManager';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import EditModalSidebar from './EditModalSidebar';
import Tour from 'reactour';
import RefreshIcon from '@material-ui/icons/Refresh';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import StorageIcon from '@material-ui/icons/Storage';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import classNames from 'classnames';
import sandboxHelper from '../Share/sandboxHelper';
import dataScopeHelper from '../Share/dataScopeHelper';

const drawerWidth = 368;
const debug = window.debug('app:rev-ui:PreviewViewFull');

const useStyles = makeStyles((theme) => createStyles({
    appBar: {
      backgroundColor: '#FFF',
      height: "69px",
      boxShadow: 'none'
    },
    previewHolder: {
        display: "flex",
        justifyContent: "center"
    },
    previewPanelContain: {
        display: "flex",
    },
    previewPanel: {
        width: `100%`,
        background: "rgba(19, 54, 79, 0.4)",
        height: "100vh"
    },
    iframe: {
        transition: "width 200ms",
        height: `calc(100vh - 79px)`,
        border: "2px dashed rgba(2,50,85,0.6)",
        '&.desktop': {
            width: "100%"
        },
        '&.mobile' : {
            width: "390px",
            maxHeight: "844px"
        }

    },
    toolbarRight: {
        justifyContent: "right",
        flex: 1,
        display: 'flex'
    },
    tourButton: {
        color: '#13364F',
        cursor: 'pointer'
    },
    responsiveButton: {
        color: '#aaa',
        cursor: 'pointer',
        fontSize: "42px",
        marginRight: "12px",
        '&.selected': {
            color: '#EA4E8F'
        }
    },
    toolbarCenter: {
        justifyContent: "center",
        flex: 1,
        display: 'flex'
    }
  }));


/**
 * Show the preview of the ui
 * @param props
 * @returns {*}
 * @constructor
 */
function PreviewView(props) {

    let [inspect, setInspect] = useState(null);
    let [cookieManagerOpen, setCookieManagerOpen] = useState(false);
    let [currentEditInstance, setCurrentEditInstance] = useState({instance: props.instance});
    let [isTourOpen, setIsTourOpen] = useState(false);
    let [isSideBarRoot, setIsSideBarRoot] = useState(true);
    let [responsiveMode, setResponsiveMode] = useState("desktop");
    
    let [editMode, setEdit] = useState(props.editMode || false);
    let [dataControlInspect, setDataControlInspect] = useState(null);
    let [layoutMode, setLayoutMode] = useState(true);
    let [dataControlModel, setDataControlModel] = useState(props.dataControlModel);


    const steps = [
        {
          selector: '*[data-step-1]',
          content: 'Hey! Welcome to RevUI Studio!  This is your first time right? Let us show you around.',
        },
        {
            selector: '*[data-step-2]',
            content: 'This is where the magic happens.  You can change the UI using this panel.  We have tried to put in as many options for you, but not so many that it gives you a headache.',
          },
        {
            selector: '*[data-step-3]',
            content: 'Here is a preview of what the campaign will look like.  If you move your mouse around it you will notice you can edit certain sections. When you click the pencil button, it will change the panel on the left to the options for this area.',
          },
          {
            selector: '*[data-step-4]',
            content: 'Once you\'re done editing, click here. Don\'t worry, you can get straight back in if you want and even after you have created the campaign you can get back here and make some more tweaks!',
          },
          {
            selector: '*[data-step-end]',
            content: 'That\'s it. Enjoy. Happy RevLifting',
          },
    ]

        
    let [previewSrc, setPreviewSrc] = useState("");


    let onMessageReceivedFromIframe = function(e) {
        let m = e.data;
        if (m.action === 'edit-block') {
            setIsSideBarRoot(props.instance === m.id.instance);
            setCurrentEditInstance(m.id);
        }
    }


    useEffect(() => {
        window.addEventListener('message', onMessageReceivedFromIframe, false);
        window.parent.postMessage({action: 'revstudio-loaded'}, '*')
    },[]);


    useEffect(() => {
        if (!props.dataControlModel) {
            return;
        }

        setDataControlModel(props.dataControlModel);

        (new Api()).dataInspect(props.instance).then((data) => {
            setDataControlInspect(data);
            // update dataControlModel metadata from mongo
            setDataControlModel(decorateDataControlModel(props.dataControlModel, data));
        });

        (new Api()).templateInspect(props.instance, true, props.dataControlModel.current.ref).then((data) => {
            let inspect = data.instanceInspect[0].inspect[0];
            setInspect(inspect);
            
            if (inspect.schema.length === 1 && inspect.schema[0].id === "primary_template_repeater") {
                // we only have one item in the schema and it's the primary repeater, set the edit instance to this instance
                try {
                    let templates = JSON.parse(inspect.schema[0].value);
                    if (templates.length > 0) {
                        setCurrentEditInstance({instance: templates[0]});
                    }
                } catch (e) {
                    console.log("Template repeater has invalid JSON");
                }
            }
        });
    }, [props.dataControlModel]); // Only rerun if this changes


    function decorateDataControlModel(dataControlModel, dataInspect) {
        if (dataControlModel.current.type !== 'sandbox') { // we are only decorating sandboxes
            return dataControlModel;
        }
        if (dataInspect && dataInspect.inspect && dataInspect.inspect.sandboxes && dataInspect.inspect.sandboxes.length) {
            for (let sandboxItem of dataInspect.inspect.sandboxes) {
                if (sandboxItem && sandboxItem.row && sandboxItem.row.sandbox === dataControlModel.current.ref) {
                    dataControlModel.current.meta = sandboxItem.row;
                }
            }
        }
        return dataControlModel;
    }

    function getPreviewSrc({urlRoot, props}) {

        let src = urlRoot + '/render';
        const q = new URLSearchParams();
        q.append('instance', props.instance);
        q.append('campaign', props.campaignUuid);
        q.append('editMode', 1);
       // q.append('layoutMode', 1);

        // Turned the layout mode selection option back on, but not the the edit mode switch (mike p)
        if (layoutMode) {
            q.append('layoutMode', 1);
        }
        if (global.env.dataScopeRef) {
            q.append('dataScopeRef', global.env.dataScopeRef);
        }
        else if (dataControlModel.publish.state === 1) {
            q.append('data', JSON.stringify(dataControlModel.publish.data));
            if (dataControlModel.publish.publishRef) {
                q.append('dataScopeRef', dataControlModel.publish.publishRef);
            }
        } else {
            if (dataControlModel.current.ref) {
                q.append('dataScopeRef', dataControlModel.current.ref);
            }
        }
        
        if (dataControlModel.time.enabled && dataControlModel.time.value) {
            q.append('timeTravel', dataControlModel.time.value);
        }

        if (window.localStorage.getItem('revui_cookies')) {
           q.append('cookies', btoa(window.localStorage.getItem('revui_cookies')));
        }

        // make re-render the iframe on every render
        q.append('r', props.previewRefresh);
        q.append('jwt', global.env.jwt);
        if (global.env.globalVariables) {
            q.append('globalVariables', global.env.globalVariables)
        }
        src += '?' + q.toString();

        return src;
    }

    let src = getPreviewSrc({urlRoot: global.env.api_endpoint, props});
    if (previewSrc !== src) {
        setPreviewSrc(src);
    }

    let reloadPreview = () => {
        document.getElementById('preview-frame').src = document.getElementById('preview-frame').src
    }

    function close() {
        window.parent.postMessage({action: 'close-revstudio', context: 'DONE'}, '*');
    }

    function closeTour() {
        setIsTourOpen(false);
    }

    function openTour() {
        setIsTourOpen(true);
    }

    function onGoBack() {
        setCurrentEditInstance({instance: props.instance});
        setIsSideBarRoot(true);
    }

    const classes = useStyles();

    function showTemplatePickDialog(props) {
        props.history.push('/popup/browse-custom');
    }

    let fullMode = global.env.mode === "FULL";
    let styleFlex = {
        display: "flex"
    }

    return <div className={classes.previewPanelContain}>
         <Tour
        accentColor={"#EA4E8F"}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={closeTour} />
        { cookieManagerOpen && <CookieManager onClose={() => {setCookieManagerOpen(false)}}/>}
        { currentEditInstance && <EditModalSidebar event={props.event} onGoBack={onGoBack} isRoot={isSideBarRoot}  mode={global.env.mode} id={currentEditInstance} onSidebarClose={() => {}} onSidebarOpening={()=>{}} dataScope={dataControlModel.current} noSecurityContext={(inspect && inspect.instanceSecurityContext) ? 0 : 1}/> }
        <div className={classes.previewPanel}>
        <AppBar className={classes.appBar} position="static">
            <Toolbar>
                <HelpOutlineIcon className={classes.tourButton} color={"secondary"} onClick={openTour}/>

                { false && fullMode && 
                
                        <Tooltip title="Toggle between edit mode and preview mode.
                Edit mode allows you to add new and change components.
                When turned off you see the page as a visitor would see">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={editMode}
                                        onChange={event => setEdit(event.target.checked)}
                                        value="editMode"
                                        color="secondary"
                                    />}
                                label={<Typography style={{color: "#13364F"}}>Edit</Typography>}

                            />



                        </Tooltip>
                    }

                    {fullMode && <Tooltip
                        title="Enable this to show the controls and borders around each component"><FormControlLabel
                        style={{marginLeft: "10px", marginRight: "20px"}}
                        control={
                            <Switch

                                checked={layoutMode}
                                onChange={event => setLayoutMode(event.target.checked)}
                                value="layoutMode"
                                color="secondary"
                            />}
                        label={<Typography style={{color: "#13364F"}}>Grid</Typography>}
                    /></Tooltip>}
                 
                    {fullMode && window.location.href.match(/\?adminhack/) && <Tooltip title="Pick any template to edit. Changes will impact any pages that use them if they are not safeguarded against changes">
                        <Button variant="contained" color="secondary" onClick={() => showTemplatePickDialog(props)} style={{marginLeft:'8px'}}>
                        <i className="material-icons">build</i> Any
                        </Button>
                    </Tooltip>
                    }
                    { fullMode &&  
                        <div style={{position:'',display:'inline-block'}}>
                            <DataControlSelect
                                key={props.instance + "DataControlSelect"}
                                dataControlInspect={dataControlInspect}
                                model={dataControlModel}
                                onChange={props.onDataControlModelChange}
                            ></DataControlSelect>
                            <DataControlAction
                                key={props.instance  + "DataControlAction"}
                                model={dataControlModel}
                                onChange={props.onDataControlModelChange}
                                scope={props.instance}
                                dataControlInspect={dataControlInspect}
                            >
                            </DataControlAction>
                        </div>
                    }

                <div className={classes.toolbarCenter}>
                    
                </div>

                <div className={classes.toolbarRight}>
                <Tooltip title="Preview desktop" aria-label="Preview desktop"><DesktopWindowsIcon onClick={() => {setResponsiveMode('desktop')}} className={classNames(classes.responsiveButton, responsiveMode === "desktop" && 'selected'  )}/></Tooltip>
                    <Tooltip title="Preview mobile" aria-label="Preview mobile"><SmartphoneIcon onClick={() => {setResponsiveMode('mobile')}} className={classNames(classes.responsiveButton, responsiveMode === "mobile" && 'selected')}/></Tooltip>
                    <Tooltip title="Cookie Manager" aria-label="Cookie Manager"><Button variant="contained" color="secondary" onClick={(e) => {setCookieManagerOpen(!cookieManagerOpen)}}><StorageIcon/></Button></Tooltip>
                    <Tooltip title="Refresh the preview panel" aria-label="Refresh the preview panel"><Button onClick={(e) => {reloadPreview()}}><RefreshIcon/></Button></Tooltip>
                    <Button data-step-4 variant="contained" color="secondary" onClick={close}>Done Editing</Button>
                </div>
            </Toolbar>
        </AppBar>
        <RenderTopBar model={dataControlModel} onChange={props.onDataControlModelChange}></RenderTopBar>
        

        <div className={classes.previewHolder}>
            <iframe data-step-3 title="preview" id="preview-frame" name="preview-frame" className={classNames(classes.iframe, responsiveMode)} src={previewSrc} frameBorder={0} width={"100%"}></iframe>
        </div>
    </div>
    </div>
}


function DataControlSelect(props) {

    let {dataControlInspect, model, onChange} = props;

    function niceSandboxName(sandboxMagicRow) { // include user and branch if needed
        return `sandbox ${sandboxMagicRow.sandbox} (${sandboxMagicRow.user || 'anonymous'} ${sandboxMagicRow.branch ? 'from ' + sandboxMagicRow.branch : 'live'})`;
    }

    function niceSandboxTooltip(sandboxMagicRow) {
        return (new Date(sandboxMagicRow.t)).toString();
    }

    
    function sandboxes() { // list sandboxes persisted to db
        let opts = [];
        if (dataControlInspect && dataControlInspect.inspect) {
            for (let sandboxMagicRow of dataControlInspect.inspect.sandboxes) {
                if (!sandboxMagicRow) {
                    continue;
                }
                sandboxMagicRow = sandboxMagicRow.row;
                let sandboxUuid = sandboxMagicRow.sandbox;
                let val = {type: 'sandbox', ref: sandboxUuid};
                opts.push(<option style={{backgroundColor:'#13364F'}} key={sandboxUuid} value={selectVal(val)} title={niceSandboxTooltip(sandboxMagicRow)}>
                    {niceSandboxName(sandboxMagicRow)}
                </option>);
            }
        }

        return opts;
    }

    function branches() {
        let opts = [];
        if (dataControlInspect && dataControlInspect.inspect) {
            for (let ref of dataControlInspect.inspect.branches) {
                opts.push(<option style={{backgroundColor:'#13364F'}} key={ref} value={selectVal({type:'branch',ref})}>{ref}</option>);
            }
        }

        return opts;
    }

    function selectVal(v) {
        return JSON.stringify(v);
    }


    debug({nativeSelectVal: selectVal(model.current)});

    let selectValValue = Object.assign({}, model.current);
    delete selectValValue.meta; // we must remove this to match correctly only on type and ref

    return <NativeSelect
        style={{margin:'4px 25px 0 0', backgroundColor: '#13364F', color: '#FFF'}}
        value={selectVal(selectValValue)}
        onChange={event => {
            let current;
            try {
                current = JSON.parse(event.target.value);
            } catch (error) {
                // do nothing
                debug({error});
                return;
            }
            // update to selected branch/sandbox
            debug('dataScope change', current);
            if (current.type === 'branch') {
                // current.warnLive = true;
            }
            let time = {enabled:false, value: null};
            onChange({current, time});
        }}
        >
        <optgroup label="Branches" style={{background:'#13364F'}}>
            <option style={{backgroundColor:'#13364F'}} value={selectVal({type: 'branch', ref: 'live'})}>LIVE</option>
            {branches()}
        </optgroup>
        <optgroup label="Sandboxes" style={{backgroundColor:'#13364F'}} >
            {sandboxes()}
        </optgroup>
    </NativeSelect>

    
}

function DataControlAction({model, onChange, scope, dataControlInspect}) {

    let publish = function() {
        let pub;
        if (!model.publish) {
            pub = {state:0,data:null};
        } else {
            pub = model.publish;
        }

        let {state} = pub;

        let {ref, type} = model.current;

        if (!type === 'sandbox') {
            debug('only sandboxes can be published');
            return;
        }


        if (state === 0) {
            // publish pressed for the first time
            // generate a preview
            let publishRequest = {scope, fromRef: ref};
            (new Api()).dataPublish(publishRequest).then((publishData) => {
                let data = publishData.publish;
                onChange({
                    // update to the approval state
                    publish: {
                        state: 1,
                        data: data.data,
                        request: publishRequest,
                        publishRef: data.branch
                    },
                    // switch to the the requested publish branch
                    current: data.switchCurrent,
                    time:{enabled:false, value: null}
                });
            });
        }
    };

    let sandboxOff = async function(){
        let {ref} = model.current;
        let {branch, isLive, sandbox, sandboxBranch} = dataScopeHelper.parseRef(ref);
        debug({jwtinspect:{branch, isLive, sandbox, sandboxBranch}});

        // new sandbox magic row is created in the sandbox table
        let api = new Api();
        let {sandboxUuid, sandboxRow} = await api.sandboxCreate(scope, branch);
        onChange({  // onChange from App.js
            current:{
                type: 'sandbox',    // type
                ref: sandboxUuid,
                meta: sandboxRow    // metadata ? needed here? We load it async anyway
            },
            time:{enabled:false, value: null} // reset timetravel
        });
    };

    return <Fragment>
        {
            (
                model.current.type === 'sandbox'
                || (model.current.type === 'branch' && model.current.ref && model.current.ref !== 'live')
            )
            && model.publish.state === 0
                ? <Tooltip title="Push the changes to the branch">
                    <Button style={{margin:'8px'}} variant="contained" color="secondary" onClick={publish}>
                        Publish ...
                    </Button>
                    </Tooltip>
                : null
        }
        {
           model.current.type === 'branch' && model.publish.state === 0
                ? <Tooltip title="Start a sandbox. You need to publish your sandbox for it to be available live.">
                    <Button style={{margin:'8px'}} variant="contained" color="secondary" onClick={sandboxOff}>
                        <i className="material-icons">call_split</i>
                    </Button>
                </Tooltip>
                : null
        }
    </Fragment>
}


function RenderTopBar({model, onChange}) {

    let msg = [];
    if (model.publish) {
        let {state, request} = model.publish;
        if (state === 1) {
            msg.push('Approve the publish preview.');
            msg.push(<Fragment>
                <Button style={{margin:'8px'}} variant="contained" color="primary" onClick={e => {
                    (new Api()).dataPublish({...request, persist:1}).then((data) => {
                        if (data.publish.persisted) {
                            // success
                            onChange({
                                publish: {state: 2},
                                current: data.publish.switchCurrent
                            });
                        }
                    });
                }
                }>Accept</Button>
                <Button style={{margin:'8px'}} variant="contained" color="primary" onClick={e => onChange({publish:{state:0}})}>Cancel</Button>
            </Fragment>);
        } else if (state === 2) {
            msg.push('Publish successful.',<Button onClick={e => onChange({publish:{state:0}})}>Ok</Button>);
        }
    }

    return <Fragment>
        {msg.length > 0 && <div style={{color:'black', padding:'8px', margin: '12px 40px', backgroundColor:'#ffffea', border: '1px solid #ffe6b9'}}>{msg}</div>}
    </Fragment>;
}




export default PreviewView;