import React from 'react';
import { SketchPicker } from 'react-color';
import {withStyles} from '@material-ui/core/styles';
import TextField from './TextField';
import OutsideAlerter from "../../OutsideAlerter";
import {InputLabel} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

const styles = theme => ({
    root: {
        display: "flex",
        position: "relative"
    },
    preview: {
        width: "16px",
        height: "16px",
        borderRadius: "16px",
        marginRight: "8px",
        position: "absolute",
        top: "50%",
        left: "8px",
        transform: "translateY(-50%)",
        border: "1px solid rgba(0,0,0,0.2)"
    },
    text: {
       flexGrow: 1,
       border: "0px",
       paddingLeft: "24px",
       border: "1px solid #D9DFE3",
       borderRadius: "4px"
    },
    clearButton:{
        position: "absolute",
        right: 2,
        top:2,
        background: "rgba(255,255,255,0.5)",
        border: "none"
    },
    clearIcon: {
        color: "#13364F",
        opacity: 0.6,
        fontSize: 16
    },
    textField:{
        input: {
            border: "0px !important",
            outline: "none !important"
        },
    },
    label: {
        marginBottom: "8px"
    }
});


class ColourPicker extends React.Component {

    state = {
        showColourPicker: false,
        value: '#333333',
        previewHex: '#FFF'
    }

    componentDidMount = () => {
        this.setState({value: this.props.defaultValue});
    }


    toggleColourPicker = () => {
        this.setState({showColourPicker: !this.state.showColourPicker});
    }

    onChange = (colour) => {
        var colourVal = colour ? colour.hex : "";
        if(colour.rgb.a < 1){
            colourVal = "rgba("+colour.rgb.r+","+colour.rgb.g+","+colour.rgb.b+","+colour.rgb.a+")";
        }
        this.setState({value: colourVal});
    }
    
    clearSavedValue = () => {
        this.setState({value: ""});
        this.props.onChange(null);
    }

    onChangeComplete = (colour) => {
        var colourVal = colour ? colour.hex : "";
        if(colour.rgb.a < 1){
            colourVal = "rgba("+colour.rgb.r+","+colour.rgb.g+","+colour.rgb.b+","+colour.rgb.a+")";
        }
        this.props.onChange(colourVal);
    }

    closePicker = () => {
        this.setState({showColourPicker: false});
    }

    render() {

        let {classes, label, description} = this.props;
        let {showColourPicker, value} = this.state;

        return (
            <>
            {/* { label && <InputLabel className={classes.label}>{ label }</InputLabel>} */}
            <div className="tooltip-contain"> 
                { label && <span className="field-label label-with-tooltip">{label}</span> }
                { description && <span data-text={description} className="i-tooltip">i</span> }
            </div>
            <div className={classes.root}>
                <div className={classes.preview}  style={{background: value}} onClick={this.toggleColourPicker} ></div>
                <div className={classes.text}><TextField disableBorder className={classes.textField} readOnly onFocus={this.toggleColourPicker} value={value}></TextField></div>
                <button className={classes.clearButton} title="Clear selection" onClick={this.clearSavedValue}><Clear className={classes.clearIcon}></Clear></button>
            </div>
            { showColourPicker && <div style={{position: "absolute",zIndex: "999"}}><OutsideAlerter onClick={this.closePicker}><SketchPicker color={value} onChange={this.onChange} onChangeComplete={this.onChangeComplete} /></OutsideAlerter></div> }
            
            </>
        )
    }
}

export default withStyles(styles, {withTheme: true})(ColourPicker);;
