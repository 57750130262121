import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({});

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.primary.main,
    '&[data-value="USD"]': {
      color: '#6c757d',
    },
    '&[data-value="EUR"]': {
      color: '#007bff',
    },
    '&[data-value="JPY"]': {
      color: '#17a2b8',
    },
    '&[data-value="GBP"]': {
      color: '#28a745',
    },
    '&[data-value="AUD"]': {
      color: '#dc3545',
    },
    '&[data-value="CAD"]': {
      color: '#ffc107',
    },
  },
}));

const CurrencySelect = (props) => {
  const classes = useStyles();

  const handleSelect = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <Select
      value={props.value}
      onChange={handleSelect}
      displayEmpty
      autoWidth
      variant={props.variant || "outlined"}
      margin={props.margin || "normal"}
    >
      <MenuItem value="" disabled>
        Select Currency
      </MenuItem>
      <MenuItem value="USD" className={classes.menuItem}>
        $ - US Dollar
      </MenuItem>
      <MenuItem value="EUR" className={classes.menuItem}>
        € - Euro
      </MenuItem>
      <MenuItem value="JPY" className={classes.menuItem}>
        ¥ - Japanese Yen
      </MenuItem>
      <MenuItem value="GBP" className={classes.menuItem}>
        £ - British Pound
      </MenuItem>
      <MenuItem value="AUD" className={classes.menuItem}>
        A$ - Australian Dollar
      </MenuItem>
      <MenuItem value="CAD" className={classes.menuItem}>
        C$ - Canadian Dollar
      </MenuItem>
    </Select>
  );
};

export default withStyles(styles, {withTheme: true})(CurrencySelect);

