import React from "react";
import {renderer as materialUiRenderer} from "./form-render-mui";
import ColorPicker from '../Components/Inputs/Styles/ColourPicker';
import Slider from "../Components/SimpleSlider";
import FontSelector from "../Components/Inputs/Styles/FontSelector";
import BoxShadow from '../Components/Inputs/Styles/BoxShadow'; 
import StyleSelect from '../Components/Inputs/Styles/StyleSelect'; 
import { BasicStyleOptions } from './basicStyleOptions';

import {
    renderColorField, 
    renderDividerField,
    renderGridField, 
    renderHtmlField, 
    renderMediaField, 
    renderTagField, 
    renderFontField, 
    renderStyleSizeField, 
    renderBoxModelField,
    renderBackgroundField,
    renderButtonCompField,
    renderSliderField,
    renderSpacingSelectField,
    renderHeaderLayoutField,
    renderFeatureLayouttField,
    renderRoundelLayoutField,
    renderBorderStyleField,
    renderAdvancedImageField,
    renderLargeTextFieldField,
    renderComplexLabelField,
    renderWidgetCreator,
    renderBasicFontSelector,
    renderFontMultiWeightSelector,
    renderFullFontStyles,
    renderBasicFontStyles,
    renderSlideValue,
    renderFontWithCustomSelector,
    renderDividerTitleField,
    renderNumberField,
    renderWidthHeightField,
    renderCheckboxStyle,
    renderRadioWithThumbs,
    renderTextOnlyField,
    renderCurrencySelect
} 
from './field-renderers'
 
const Color = require('color');
const debug = window.debug('app:rev-ui:share:formFieldRenderer');

let styleFieldRenderer = function renderer(field, onChange, onFieldFocus, onFieldBlur) {
    const {id, type, label, value} = field;
    if ( BasicStyleOptions.hasOwnProperty(type)) {
       return <StyleSelect
            key={id}
            label={label}
            name='background-repeat'
            value={value || "no-repeat"}
            onChange={(val) => {
                onChange(id, val);
            }}
            options={BasicStyleOptions[type]}
        ></StyleSelect>
        }
    
    switch (type) {
        case 'font-family':
            return <FontSelector
                key={id}
                label={label}
                value={value}
                onChange={(val) => {
                    if (val) {
                        let apiUrl = [];
                        let variants = [];
                        apiUrl.push('https://fonts.c.com/css?family=');
                        apiUrl.push(val.familyValue.data.family.replace(/ /g, '+'));
                        if (val.selectedVariant) {
                            apiUrl.push(':');
                            apiUrl.push(val.selectedVariant);
                            if(Array.isArray(val.variantSelected)){
                                val.variantSelected.forEach((value, key, map) => {
                                    if (value) {
                                        apiUrl.push(key);
                                        apiUrl.push('&');
                                    }
                                });
                            }else{
                                variants.push(val.selectedVariant);
                            }
                        } else {

                        }
                        console.log("apiUrl",apiUrl)
                        let url = apiUrl.join('');

                        let value = {
                            "set": val.familyValue.data.family,
                            "styl": `@import url(${url.replace(/\+/g, "\\+")})`,
                            "variants": variants
                        };
                        onChange(id, JSON.stringify(value));
                    }
                }}
            />
        case 'max-width' :
        case 'min-width' :
            // precedence for properties:
            // 1) style comment JSON config
            // 1) config here
            // 
            // precedence for unit:
            // 1) default style value unit if any
            // 2) 'defaultUnit' prop from style config
            // 3) 'px'
            //
            // precedence for value:
            // 1) default style value if any
            // 2) 'defaultValue' for unit from style config
            // 3) config per unit here


            // unit defaults - can be overriden
            let unitDefaults = {
                px:{min: 200, max: 5000, defaultValue: 200},
                '%':{min: 20, max: 100, defaultValue:50}
            };
            // override with per style prop definition
            if (field.revuiConfig.config.unitDefaults) {
                unitDefaults = Object.assign(unitDefaults, field.revuiConfig.config.unitDefaults);
            }

            // override default value and unit per style prop definition
            let {defaultUnit} = Object.assign(
                {defaultUnit: 'px'},
                field.revuiConfig.config
            );

            // split the value and unit of the current style prop value
            let {value:val, unit} = splitValueUnit(value);
            
            // figure out the unit to use
            if (!unit) {
                unit = defaultUnit;
            }

            // override value with default if no value
            if (!val) {
                val = unitDefaults[unit].defaultValue;
            }

            // create props for the slider
            let sliderProps = Object.assign(
                {value:val, unit},
                {...(unitDefaults[unit])}
            );

            return <Slider
                key={id}
                step={1}
                label={label}
                {...sliderProps} // min, max, value, defaultUnit
                field={field}
                availableUnits={Object.keys(unitDefaults)}
                onChange={(val) => {
                    if (val) {
                        onChange(id, val);
                    }
                }}
            />
        case 'background-color':
        case 'color':
            let divStyle = {
                width: 25,
                height: 25, 
                border: "1px solid #333",
                borderRadius: "4px",
                backgroundColor: value
            }
            return <div> <div style={divStyle}></div>
                <ColorPicker
                key={id}
                label={label}
                name='color'
                defaultValue={value || ''}
                placeholder='Pick a color'
                // value={this.state.color} - for controlled component
                onChange={(val, onFieldChange) => {
                    debug({val});
                    if (val && isColor(val)) {
                        onChange(id, val);
                    }
                }}
            /> </div>;
        case 'box-shadowXX':

            //console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
            return <BoxShadow 
                label={label}
                onChange={(val, onFieldChange) => {
                    onChange(id, val);
                }
            }
            
            ></BoxShadow>
        default:
            // default style inputs to text
            if (type !== 'text') {
                field.type = 'text';
            }
            // fallback to thirdParty field renderer
            // return <div>Whoops</div>;
            return materialUiRenderer(field, onChange, onFieldFocus, onFieldBlur);
    }

};


let typeHandlers = {
    'tags': renderTagField,
    'media': renderMediaField,
    'grid': renderGridField,
    'html': renderHtmlField,
    'divider': renderDividerField,
    'color': renderColorField,
    'background-color': renderColorField,
    'font': renderFontField,
    'style-size': renderStyleSizeField,
    'box-model': renderBoxModelField,
    'background': renderBackgroundField,
    'button-comp': renderButtonCompField,
    'slider': renderSliderField,
    'spacing-select':renderSpacingSelectField,
    'header-layout':renderHeaderLayoutField,
    'feature-layout':renderFeatureLayouttField,
    'roundel-layout': renderRoundelLayoutField,
    'border-style': renderBorderStyleField,
    'advanced-image': renderAdvancedImageField,
    'textarea': renderLargeTextFieldField,
    'complex-label': renderComplexLabelField,
    'widget-creator': renderWidgetCreator,
    'basic-font': renderBasicFontSelector,
    'font-multi': renderFontMultiWeightSelector,
    'font-styles': renderFullFontStyles,
    'basic-font-styles': renderBasicFontStyles,
    'slide-value': renderSlideValue,
    'font-custom': renderFontWithCustomSelector,
    'divider-title': renderDividerTitleField,
    'number': renderNumberField,
    'width-height': renderWidthHeightField,
    'checkbox-style': renderCheckboxStyle,
    'radio-with-thumbs': renderRadioWithThumbs,
    'text-only': renderTextOnlyField,
    'currency-select': renderCurrencySelect
};

let fieldRenderer = function renderer(field, onChange, onFieldFocus, onFieldBlur, event) {
    let {type, value, defaultValue } = field;
    let events = {
        onChange,
        onFieldFocus,
        onFieldBlur
    }
    value = defaultValue;
    if (typeHandlers.hasOwnProperty(type)) {
        return typeHandlers[type](field, events, value, event);
    } else {
        return materialUiRenderer(field, onChange, onFieldFocus, onFieldBlur);
    }
};

let createEventedFieldRenderer = function(event) { // add event to renderer function
    return function(...params) {
        return fieldRenderer(...params, event);
    }
};

function isColor(val) {
    let success = false;
    try {
        Color(val);
        success = true;
    } catch (e) {
    }
    return success;
}

function splitValueUnit(value) {
    let ret = {value: null, unit: null};
    if (value && !Number(value)) {
        // has a unit
        let numberMatch = value.match(/[-+\d.]+/);
        let unitMatch = value.match(/\D*?$/);
        ret.value = (numberMatch && Number(numberMatch[0])) || parseInt(value);
        ret.unit = (unitMatch && unitMatch[0]);
    } else if (value) {
        ret.value = Number(value);
    }
    return ret; 
}

export {styleFieldRenderer, fieldRenderer, createEventedFieldRenderer};