/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import {InputLabel, Grid, Slider} from '@material-ui/core';
import TextField from './TextField';

const styles = theme => ({
    
});


class SizeSlider extends React.Component {
    state = {
        inputValue: this.props.value,
        size: 11,
        unit: 'px'
    };

    componentDidMount = () => {

        let { inputValue } = this.state;
        if (typeof inputValue === 'string' || inputValue instanceof String) {
            if (this.props.initialValue && inputValue === null){
                inputValue = this.props.initialValue
            }
            let size = parseFloat(inputValue.match(/(\d+)/)[0]);
            let unit = inputValue.substring(size.toString().length);
            this.setState({size, unit: "px"}, () => {
            });
        } 
    }

    onChange = () => {
        let { size, unit} = this.state;
        this.props.onChange(`${size}${unit}`);
    }

    onSizeChange = (e) => {
        this.setState({size: e.target.value}, () => {
            this.onChange();
        });
    }

    onUnitChange = (e) => {
        this.setState({unit: e.target.value}, () => {
            this.onChange();
        });
        
    }

    handleSliderChange = (e, newValue) => {
        this.setState({size: newValue});
    }

    render() {
        const {classes,  label, min, max} = this.props; //value
        let {size, unit} = this.state;

        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel>{label}</InputLabel>
                    <Grid container spacing={4}>
                        <Grid item xs={8}>
                            <Slider
                                value={size}
                                step={1}
                                min={min}
                                max={max}
                                onChange={this.handleSliderChange}
                                onBlur={this.onChange}
                                aria-labelledby="input-slider"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField value={size + unit}></TextField>
                        </Grid>
                    </Grid>
                </NoSsr>
            </div>
        );
    }
}

SizeSlider.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(SizeSlider);
