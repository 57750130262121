import {withStyles} from '@material-ui/core/styles';
import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Api from './../Share/api';
import idEncoder from './../Share/idEncoder';
import debounce from "../Share/debounce";
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import './EditModel.css';
import TemplateModifyStyled from '../Components/TemplateModify';

// import atob from 'atob';
// import btoa from 'btoa';

const debug = window.debug('app:rev-ui:EditTemplateModal');
let drawerWidth = 500;
const dialogStyles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
    paper: {
        root: {
            minHeight: "70vh"
        },
        paperFullWidth: {
            width: "1024px"
        }
    },
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
    },
    drawer: {
        
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerFullScreen: {
        width: "100%",
        flexShrink: 0
    },
    drawerPaper: {
        boxShadow: "3px 0px 12px -4px rgba(0,0,0,0.75);",
        borderRight: 0,
        backgroundColor: '#efefef',
        width: drawerWidth,
    },
    drawerPaperFullScreen: {
        backgroundColor: '#F4F6F8',
        width: "100%",
    },    
    MuiToolbar: {
        background: "#EA4E8F"
    },
    MuiAppBar: {
        background: "#01579b"
    }
};

/**
 * This will only show editing of the template, as it is opened in the template context,
 * not instance context.
 */
class EditTemplateModal extends React.Component {

    constructor(props) {
        super(props);

        let {templateName} = idEncoder.decode(props.match.params.id);

        this.state = {
            templateName,
            templateInspect: {},
            // data to use
            inspect: {},
            modalStyles: {
                top: `50%`,
                left: `50%`,
                width: '70%',
                minHeight: '70%',
                transform: `translate(-50%, -50%)`,
            },
            fullScreenMode: true,
            standalone: props.match.path.includes('/standalone/')
        };


        // debounce autosave
        // this.autoSave = debounce(this.autoSave, 1000);
        this.onSaveTemplate = debounce(this.onSaveTemplate, 1000);
    }

    componentDidMount() {
        document.title = this.displayName || 'Edit';
        this.runInspect();
    }

    // runInspect() {
    //     (new Api()).templateInspect(null, true, this.props.dataScope.ref, this.state.templateName).then((data) => {
    //         // inspect the selected template only
    //         this.setState({inspect: data.inspect[0]});
    //         this.setState({value: 2});
    //     });
    // }

    runInspect() {
        if (this.state.templateName === null) {
            (new Api()).templateInspect(this.state.editInstance, true, this.props.dataScope.ref).then((data) => {
                // inspect the selected template only
                this.setState({inspect: data.inspect[0]});
                // this.setState({templateInspect: data.templatesSchemas[0]});
                window.parent.postMessage({action: 'revstudio-loaded'}, '*')
                // console.log({data});

            });
        } else {
            (new Api()).templateInspect(null, true, this.props.dataScope.ref, this.state.templateName).then((data) => {
                // inspect the selected template only
                this.setState({inspect: data.inspect[0]});
                // this.setState({templateInspect: data.templatesSchemas[0]});
                window.parent.postMessage({action: 'revstudio-loaded'}, '*')
                this.setState({value: 2});

            });
        }
    }

    handleClose = () => {
        if (this.state.standalone) {
            window.parent.postMessage({action: 'close-revstudio'}, '*')
        } else {
            this.props.history.push('/');
        }
        
    };

    // /**
    //  * - Debounce
    //  * - Save to sandbox
    //  * @param type
    //  * @param value
    //  */
    // autoSave = (type, value, context) => {
    //     let rows = [];
    //     let prefix = type === 'style' ? '_style_' : '';
    //     let {instance, scope} = this.state.inspect;
    //     let t = Date.now();
    //     for (let [key, val] of Object.entries(value)) {
    //         rows.push({
    //             name: prefix + key, value: val, instance, scope, t
    //         });
    //     }

    //     (new Api()).save(rows, this.props.dataScope.ref).then((saved) => {
    //         // notify other components
    //         window.postMessage({action: 'saved'}, '*');
    //     });

    // };

    // redirect to browse a different template
    onChangeTemplate = (e) => {
        // notify other components
        //window.postMessage({action:''}, '*');
        debug('yo-ho', this.props.history);
    };

    prettyTemplateName = (templateName) => {
        return templateName.replace('_', ' ');
    };

    fullScreenToggle = (e) => {
        if (this.state.fullScreenMode) {
            this.setState({
                modalStyles: {
                    width: "70%",
                    minHeight: "70%",
                    top: `50%`,
                    left: `50%`,
                    transform: `translate(-50%, -50%)`
                }
            });
        } else {
            this.setState({modalStyles: {width: "100%", minHeight: "100%"}});
        }
        this.setState({fullScreenMode: !this.state.fullScreenMode});

    }

    onSaveTemplate = (type, data) => {
        debug({onSaveTemplate: data});

        //console.log('data', data);

        let row = {};
        // construct the template config from our data ...
        let values;
        try {
            if (data.json) {
                values = JSON.parse(data.json);
            }
        } catch (e) {
            // invalid json
            alert('Fix json errors before saving');
            return;
        }

        // row is a template
        row = {
            name: this.state.inspect.id.templateName,
            value: JSON.stringify({
                template: data.twig,
                config: {
                    styl: data.stylus || null,
                    script: data.script || null,
                    muiIcon: data.muiIcon || null,
                    title: data.title || null,
                    description: data.description || null,
                    styleCompiler: data.styleCompiler || null,
                    renderVersion: data.renderVersion || null,
                    tags: data.tags || null,
                    archived: data.archived || false,
                    values: values
                }
            }),
            t: Date.now()
        };

        // save the template
        (new Api()).save([row], this.props.dataScope.ref).then(() => {
            debug('saved template');
            // request rerender
            window.postMessage({action: 'saved'}, '*');

            // regenerate inspect
            this.runInspect();
        });
    };


    render() {
        const {classes} = this.props;
        const {value, inspect, fullScreenMode, standalone} = this.state;

        if (Object.keys(inspect).length > 0 && value === 0 && (!inspect.hasOwnProperty('schema') || !inspect.schema.length > 0)) {
            this.setState({value: 1});
        }

        return <Drawer 
                    className={fullScreenMode ? classes.drawerFullScreen : classes.drawer }   
                    open={true} 
                    onClose={this.handleClose} 
                    variant="persistent" 
                    anchor="left"
                    classes={{
                        paper: fullScreenMode ? classes.drawerPaperFullScreen :  classes.drawerPaper
                    }}
        >
            <div>
                <AppBar position="static">
                    <Toolbar className={classes.MuiToolbar}>
                        <Typography variant="h5" color="inherit">
                            <strong>{inspect !== undefined && inspect.hasOwnProperty('id') ? this.prettyTemplateName(inspect.id.templateName) : ''}</strong> block
                            editor
                        </Typography>
                        <div style={{position: "absolute", right: "12px"}}>
                        
                        <button onClick={this.fullScreenToggle} style={{cursor: 'pointer'}}>
                            {!fullScreenMode &&
                            <i title="Enter fullscreen mode" className="material-icons">fullscreen</i>}
                            {fullScreenMode &&
                            <i title="Exit fullscreen mode" className="material-icons">fullscreen_exit</i>}
                        </button>
                            
                            <button onClick={this.handleClose} style={{cursor: 'pointer'}}><i
                                className="material-icons">close</i></button>
                        </div>
                    </Toolbar>
                    </AppBar>
                    { Object.keys(inspect).length > 0 && 
                    <TemplateModifyStyled fullScreenMode={fullScreenMode} inspect={inspect}
                    onClose={this.handleClose} 
                    templateName={this.state.inspect.id.templateName}
                    standalone={standalone}
                    onSave={this.onSaveTemplate}></TemplateModifyStyled>
                    }
            </div>
        </Drawer>
    }
}

export default withStyles(dialogStyles)(EditTemplateModal);