import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';

const debug = window.debug('app:index');

const customHistory = createBrowserHistory();
const prevHistoryPush = customHistory.push;
let lastLocation = customHistory.location;

customHistory.listen(location => {
    lastLocation = location;
});
customHistory.push = (pathname, state = {}) => {
    debug('customPush', pathname);
    if (
        lastLocation === null ||
        pathname !==
        lastLocation.pathname + lastLocation.search + lastLocation.hash ||
        JSON.stringify(state) !== JSON.stringify(lastLocation.state)
    ) {
        debug('execute push');
        prevHistoryPush(pathname, state);
    }
};


ReactDOM.render(
    <Router history={customHistory}><App/></Router>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
