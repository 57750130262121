// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FieldWrapper } from "react-forms-processor";
import type { Field, FieldDef } from "react-forms-processor";

const styles = theme => ({
  root: {
    display: "flex !important",
    flexWrap: "nowrap",
    "margin-bottom": "0px !important"
  },
  formControl: {
    minWidth: 120
  },
  select:{
    borderRadius: "4px",
    border: "1px solid #D9DFE3",
    paddingLeft: "12px",
    marginTop: "0px !important"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  label: {
    position: "relative",
    marginBottom: 12
  }
});

class MaterialUiSelect extends React.Component<Field> {
  render() {
    // $FlowFixMe - HOC adds this class

    const {
      classes,
      description,
      disabled,
      id,
      name,
      options = [],
      value,
      label,
      onFieldChange
    } = this.props;
    const items = options.reduce((itemsSoFar, option) => {
      return itemsSoFar.concat(
        option.items.map(item => {
          if (typeof item === "string") {
            return <MenuItem value={item}>{item}</MenuItem>;
          } else {
            return (
              <MenuItem key={item.label} value={item.value}>{item.label || item.value}</MenuItem>
            );
          }
        })
      );
    }, []);
   
    return (

      <FormControl 
        key={id} 
        disabled={disabled}
        className={classes.root}>
        <InputLabel htmlFor={id} shrink={true} disableAnimation={true} className={classes.label}>{label}</InputLabel>
        <Select
          key={id}
          value={value}
          style={{height: 32}}
          classnameprefix="dropdown"
          disableUnderline={true}
          className={classes.select}
          IconComponent={ExpandMore}
          onChange={evt => {
            onFieldChange(id, evt.target.value);
          }}
          input={<Input name={name} id={id}/>}
        >
          {items}
        </Select>
      </FormControl>
    );
  }
} 

const StyledMaterialUiSelect = withStyles(styles)(MaterialUiSelect);

export default (props: FieldDef) => (
  <FieldWrapper {...props}>
    {/* $FlowFixMe */}
    <StyledMaterialUiSelect />
  </FieldWrapper>
);
