import {withStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Api from './../Share/api';
import idEncoder from './../Share/idEncoder';

const debug = window.debug('app:DeleteModal');

// import atob from 'atob';
// import btoa from 'btoa';

const styles = theme => ({
    paper: {
        position: 'absolute',
        minWidth: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        outline: 'none',
    },
});

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


class DeleteModal extends React.Component {

    constructor(props) {
        super(props);

        // editing either the block, or  a block attached on a parent block via property.
        function getInstance(id) {
            if (id.instance && !id.prop) {
                return id.instance;
            } else if (id.templateInstance) {
                return id.templateInstance;
            }
        }

        let id = idEncoder.decode(props.match.params.id);
        debug({settingID: id, params:props.match.params});
        this.state = {
            // tab ui
            value: 0,
            // instance holder
            id: id,
            editInstance: getInstance(id),
        };
    }

    componentDidMount() {
        this.onDelete();
    }

    handleClose = () => {
        this.props.history.go(-1);
        this.setState({ value: false });
    };

    // assign the new template to the id ....
    onDelete = async () => {
        let {id} = this.state;
        let row = {
            instance: id.instance,
            $value: {action: 'delete', value: id.templateInstance},
            name: id.prop
        };

        if (row) {
           debug(row);
            (new Api()).save([row], this.props.dataScope.ref, true, this.props.noSecurityContext).then(() => {
                // reload preview
                window.postMessage({action:'saved'}, '*');
                // automatically close modal after 1.3s
                setTimeout(() => this.props.history.replace('/'), 1300);
            });

        }
    };

    render() {
        const { classes } = this.props;

        return <Modal open={true} onClose={this.handleClose}>
            <div style={getModalStyle()} className={classes.paper}>
                <AppBar position="static">
                    <h1>Deleting ...</h1>
                </AppBar>
            </div>
        </Modal>
    }
}


export default withStyles(styles)(DeleteModal);