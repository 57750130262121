import React from "react";
import {
    Dialog, 
    DialogTitle, 
    Typography, 
    DialogContent, 
    DialogActions, 
    Button, 
    TextField, 
    Grid,
    Table,
    TableCell,
    TableRow ,
    TableHead,
    TableBody,
    Snackbar 
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';



export default class CookieManager extends React.Component {

    state = {
        cookies : {},
        addCookie: {name: '', value: ''},
        showSaveSnackBar: false
    }

    componentDidMount() {
        let cookies = window.localStorage.getItem('revui_cookies');
        console.log({cookies});
        if (cookies) {
            this.setState({cookies: JSON.parse(cookies)});
        }
    }

    onAddCookie(addName, addValue) {
        if (addName && addValue) {
            let cookies = this.state.cookies;
            cookies[addName] = addValue;
            this.setState({cookies}, () => {
                this.setState({addValue: '', addName: ''});
            });
        }
    }

    onCookieDelete(key) {
        let cookies = this.state.cookies;
        delete cookies[key];
        this.setState({cookies});
    }

    onSave(cookies) {
        window.localStorage.setItem('revui_cookies', JSON.stringify(cookies));
        this.setState({showSaveSnackBar: true});
    }

    render() {

        const { onClose } = this.props;
        const { cookies, addName, addValue, showSaveSnackBar } = this.state;

        return (
            <>
            <Dialog maxWidth="md" onClose={onClose} aria-labelledby="simple-dialog-title" open={true}>
                <DialogTitle id="simple-dialog-title">Cookie Manager</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">The cookies you define below will be passed to RevUI as if they were real cookies. This can be helpful if you need to see the page with a certain state.</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}><TextField required={true} id="cookieName" label="Name" value={addName} onChange={(e) => { this.setState({addName: e.target.value})}}  /></Grid>
                        <Grid item xs={3}><TextField required={true} id="cookieValue" label="Value" value={addValue}  onChange={(e) => { this.setState({addValue: e.target.value})}} /></Grid>
                        <Grid item xs={2}><Button onClick={() => {this.onAddCookie(addName, addValue)}} variant="outlined" color="primary">+</Button></Grid>
                    </Grid>
                   
                   
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            { Object.keys(cookies).map((key) => {
                                return <TableRow> 
                                    <TableCell>{key}</TableCell>
                                    <TableCell>{cookies[key]}</TableCell>
                                    <TableCell><Button onClick={() => { this.onCookieDelete(key) }} variant="outlined" color="secondary"><DeleteIcon/></Button></TableCell>
                                </TableRow>
                            })}
                            </TableBody>
                        </Table>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="outlined" color="secondary">Close</Button>
                    <Button onClick={() => {this.onSave(this.state.cookies)}} variant="outlined" color="primary">Save</Button>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={showSaveSnackBar}
                        autoHideDuration={5000}
                        onClose={() => { this.setState({showSaveSnackBar:false}) }}
                        message="Cookies saved"
                    />
                </DialogActions>
            </Dialog>
            </>
        );
    }
}

