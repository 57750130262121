import {} from 'dotenv/config'
import React, {Component} from 'react';
import './App.scss';
import Jwt from "./Share/jwt";
import PreviewViewSimple from "./Views/PreviewViewSimple";
import PreviewViewFull from "./Views/PreviewViewFull";
import {Route, withRouter} from 'react-router-dom';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import BrowseModal from "./Views/BrowseModal";
import DeleteModal from "./Views/DeleteModal";
import MediaModal from "./Views/MediaModal";
import ShareModal from "./Views/ShareModal";
import Api from './Share/api';
import idEncoder from "./Share/idEncoder";
import QueryString  from 'querystring';
import iFrameAdjust  from './Share/iFrameAdjust';
import sandboxHelper from "./Share/sandboxHelper";
import dataScopeHelper from "./Share/dataScopeHelper";
import SchemaAttachedEditor from "./Views/SchemaAttachedEditor";
import EditTemplateModal from './Views/EditTemplateModal';
import Admin from './Views/Admin';
import browseActions from './Components/BrowseActions';
import {withStyles} from '@material-ui/core/styles';
import UserError from './Share/userError';

global.env = {
    'api_endpoint': process.env.REACT_APP_API_ENDPOINT,
    'instance': process.env.REACT_APP_INSTANCE, // development instance bootstrap
    'cdnBasePath': process.env.REACT_APP_CDN_BASE_PATH,
    'jwt': process.env.REACT_APP_JWT,
    'dataScopeRef' : null,
    'mode': 'FULL',
    'noSecurityContext': false,
    'globalVariables': []
};


global.env = customiseEnv();

function customiseEnv() { // env is current instance, JWT and data scope
    let env = global.env;
    let initParams = QueryString.parse(window.location.search.substr(1));
    if (Object.keys(initParams).length > 0) {
        // apply hardcoded, then current env, then queryparams 
        let currentSaved = {};
        if (sessionStorage.getItem('env')) {
            currentSaved = JSON.parse(sessionStorage.getItem('env'));
        }
        global.env = Object.assign(global.env, currentSaved, initParams);
        sessionStorage.setItem('env', JSON.stringify(global.env));
    } else if (sessionStorage.getItem('env')) {
        global.env = Object.assign(global.env, JSON.parse(sessionStorage.getItem('env')));
    }

    return env; 
}




const GlobalCss = withStyles({
    '@global': {
      '.MuiButton-root': {
        borderRadius: 0,
        fontSize: "15px",
        boxShadow: "none",
        textTransform: 'none',
        padding: "8px 26px"
      },
      '.MuiPaper-rounded': {
          borderRadius: 4,
            boxShadow: "none",
          border: "none",
          overflow: "inherit"
      },
      '.MuiFormLabel-root': {
        fontFamily: "Mulish",
        fontSize: "13px",
        fontWeight: "600",
        lineHeight: "13px",
        letterSpacing: "0px",
        textAlign: "left",
        opacity: 0.7,
        color: "#13364F",
        marginBottom: "12px"
      },
      ".minorLabel":{
        fontFamily: "Mulish",
        fontSize: "13px",
        fontWeight: "400",
        lineHeight: "13px",
        letterSpacing: "0px",
        textAlign: "left",
        opacity: "0.7",
        color: "#13364F",
        marginBottom: "12px"
      },
      ".MuiSelect-selectMenu, .MuiSelect-root *, .MuiInputBase-input, div.dropdown__control, MuiSelect-nativeInput":{
        color: "#13364F !important",
        fontFamily: "Mulish",
        fontSize: "14px",
        fontWeight: "600",
        lineHeight: "14px",
        letterSpacing: "0px",
        textAlign: "left"
      },
      "div.dropdown__control *":{
        color: "#13364F !important"
      },
      ".MuiMenuItem-root":{
        color: "#13364F",
        fontFamily: "Mulish",
        fontSize: "13px",
        fontWeight: "600",
        lineHeight: "14px",
        letterSpacing: "0px",
        textAlign: "left",
        minHeight: "0px",
        paddingTop: 8,
        paddingBottom: 8
      },
      '.MuiSlider-thumb': {
            height: "20px",
            width: "20px", 
            backgroundColor: "#98D4D5",
            boxShadow: "0 2px 6px 0 rgba(115,187,189,0.3)"
      },
      '.MuiSlider-rail': {
          height: "8px",
          backgroundColor: "rgba(185,185,185,0.27)"
      },
      '.MuiSlider-track': {
        height: "8px"
      },
      '.MuiCardContent-root': {
            padding: "0px"
      },
      '.MuiCardContent-root:last-child': {
            padding: "0px"
      },
      'p': {
          color: '#13364F',
          fontSize: "14px"
      }
    },
  })(() => null);


iFrameAdjust.start();


const debug = window.debug('app:app');

class AppErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}

class App extends Component {

    // constructor(props) {
    //     super(props);
    // }

    // render() {
    //     return <div>Woohoo</div>;
    // }

    constructor(props) {
        super(props);
        debug({props});
        this.jwt = new Jwt();

        // set initial state
        this.state = this.jwt.generateTokenState(global.env.jwt, this.getTokenSetter());
        this.state.sideBarOpened = false;
        this.state.sideBarWidth = 0;
        this.state.instance = global.env.instance;
        this.state.campaignUuid = global.env.campaign;
        this.state.noSecurityContext = global.env.noSecurityContext;
        this.state.dataControlModel = this.getDataControlModel(this.state.instance);

        debug({token: this.state.token});

        this.state.previewRefresh = '';
        this.state.event = {};

        /**
         * Component communication
         * - events are posted here
         *  - action is handled
         *      - router is redirected
         *      - OR we let the component handle the event itself (we pass it in props.event)
         */
        window.addEventListener('message', event => {
            let passItInProps = false;
            let m = event.data;

            if (!m.action) {
                return;
            }
            if (m.action === 'render-block-click') {
            }
            if (m.action === 'saved') {
                this.setState({previewRefresh: Date.now()});
            }

            
            let id = btoa(JSON.stringify(m.id));
            let to = null;
            if (m.action === 'edit-block') {
                //to = `/popup/edit/${id}`;
            } else if (m.action === 'add-new-list-item') {
                to = `/popup/pick/${id}/append`;
            } else if (m.action === 'delete-block') {
                to = `/popup/delete/${id}`;
            } else if (m.action === 'media-manager') {
                to = `/popup/media/${id}`;
                if (m.hasOwnProperty('backOnSelect') && m.backOnSelect) {
                    to = `${to}/backonselect`
                }
                if (m.from) {
                    let set = m.hasOwnProperty('set') ? m.set : ""
                    // keep the query string on the url
                    to = {
                        pathname: to,
                        search: '?from=' + encodeURIComponent(JSON.stringify(m.from)) + '&event='+encodeURIComponent(JSON.stringify(m))+'&set=' + encodeURIComponent(set) ,
                    };
                }
                if (m.saveRow) {
                    if (to && to.pathname) {
                        to.search += '&saveRow=' + encodeURIComponent(JSON.stringify(to.saveRow));
                        to.pathname = '/standalone' + to.pathname;
                    }
                }
            } else if (m.action === 'sort') {
                this.saveMoveAction(m).then(function(){
                    
                });
            }

            if (to) {
                debug('time correction ' + to);
                this.props.history.push('/');
                this.props.history.push(to);
            }

            if (m.action === 'media-manager-set') {
                passItInProps = true;
            }
            if (passItInProps) {
                this.setState({event: m});
            }


        }, false);

        // create app wide theme deafaults
        // https://material-ui.com/customization/themes/
        // this is only needed to customize theme
        this.theme = createMuiTheme({
            palette: {
                primary: {main: "#13364F"},
                secondary: {main: '#97D3D5'},
            },
            typography: {useNextVariants: true, fontFamily: "Mulish"},
            overrides: {
                MuiAppBar: {
                    root: {
                        background: '#FFF'
                    }
                },
                MuiFormControl: {
                    root: {
                        width: "100%",
                        display: "block",
                        marginTop: "0",
                        marginBottom: "22px"
                    }
                },
                MuiTextField: { 
                  root: {
                      width: "100%",
                      marginBottom: 10,
                      paddingBottom: 0
                  }  
                }
            }
        });

    }


    persistDataControlModel(instance, model) {
        let key = '_dataControlModel_' + instance;
        window.localStorage.setItem(key, JSON.stringify(model));
    }

    getDataControlModel(instance) {   // data scope for instance
        let key = '_dataControlModel_' + instance;
        // let current = {type: null, ref: null, meta: null}; // initial dataScope model
        let model = {
            current: {
                type: 'branch',
                // sandbox or branch name
                ref: 'live',
                // warn user the first time this is used - if its a live environment, they will be making changes live
                meta: null
            },
            // time traveling model
            time:{
                enabled: false,
                value: null
            },
            // publish workflow model
            publish: {
                // state keeper
                // 0 ... not in publish workflow
                // 1 ... awaiting Accept for the preview
                // 2 ... publish successful
                state: 0,
                // publish preview data
                data: null,
                // requested publish params - what is this?
                request: null,
                // data ref it was published to -> this ref needs to be used to see the published data?
                publishRef: null
            }
        };

        function loadModel(key) {
            let model = null;
            try {
                model = JSON.parse(window.localStorage.getItem(key));
            } catch (e){}

            return model;
        }
        
        let {dataScopeRef: envDataScopeRef} = global.env;   // dataScope from query
        
        if (envDataScopeRef) { // specific dataScope was requested
            let {branch, sandbox, isLive} = dataScopeHelper.parseRef(envDataScopeRef); // get data from the reference
            if (sandbox) {
                model.current.type = 'sandbox';
                model.current.ref = sandbox;
                model.meta = null;
            } else {
                model.current.type = 'branch';
                model.current.ref = branch;
                model.current.ref = null;
            }
            this.persistDataControlModel(instance, model);  // persist it
        } else {
            // try loading the model from local storage
            let persistedModel = loadModel(key);
            if (!persistedModel) {
                this.persistDataControlModel(instance, model);  // persist default "live" model
            } else {
                model = persistedModel; // use it
            }
        }

        return model;
    }

    saveMoveAction({id, index, oldParentId, newParentId}) {
        let objId = idEncoder.decode(id);
        let oldParent = idEncoder.decode(oldParentId);
        let newParent = idEncoder.decode(newParentId);

        let rows = [];
        if (newParentId === oldParentId) {
            // same lister, need to trigger an internal move
            rows.push({
                instance: newParent.instance,
                name: newParent.prop,
                $value: {action: 'move', value: objId.templateInstance, index}
            });
        } else {
            rows.push({
                instance: oldParent.instance,
                name: oldParent.prop,
                $value: {action: 'delete', value: objId.templateInstance}
            });
            rows.push({
                instance: newParent.instance,
                name: newParent.prop,
                $value: {action: 'insert', value: objId.templateInstance, index}
            });
        }

        return (new Api()).save(rows, this.state.dataControlModel.current.ref, true, this.state.noSecurityContext);
    }

    getTokenSetter() {
        var self = this;
        return (tokenState) => {
            self.setState(tokenState);
        }
    }

    componentDidMount() {
        debug('componentDidMount');
    }

    componentDidUpdate() {
        if (this.state.event) { // reset event state as it was already passed to childrenas prop
            this.setState({event: null});
        }
    }

    componentWillUnmount() {
        debug('componentWillUnmount');
    }

    shouldComponentUpdate(nextProps, nextState) {
        debug('componentWillUnmount', nextProps, nextState);
        return true;
    }

    onShareClick = (shareUrl) => {
        let id = btoa(`{"shareUrl": "${shareUrl}"}`);
        this.props.history.push(`/popup/share/${id}`);
    };

    onSidebarOpening = (width) => {
        this.setState({sideBarOpened: true, sideBarWidth: width});
    }

    onSidebarClose = () => {
        this.setState({sideBarOpened: false, sideBarWidth: 0});
    }
    

    render() {
        let PreviewViewComponent = global.env.mode === "SIMPLE" ? PreviewViewSimple : PreviewViewFull;
        let securityContext = this.state.token && this.state.token.obj && this.state.token.obj.securityContext;
        let uploadPath = securityContext;
        let redirect = null;
        let noSecurityContext = this.state.noSecurityContext;
        let { previewRefresh, instance, dataControlModel, event, campaignUuid} = this.state;

        if (!dataControlModel) { // wait for dataControlModel setup?
            return null;
        }

        // anything with standalone at the start of the path shouldn't have the default route rendered
        //@todo this is currently throwing a clientside warning even though it's working
        return (
            <AppErrorBoundary>
            <MuiThemeProvider theme={this.theme}>
                <GlobalCss />
                {redirect}
                <Route path="/popup/delete/:id"
                       render={(props) => <DeleteModal {...props} dataScope={dataControlModel.current} noSecurityContext={noSecurityContext}/>}/>
                <Route path="/popup/edit-template/:id"
                       render={(props) => <EditTemplateModal {...props} dataScope={dataControlModel.current}/>}/>
                {/*
                    Open a template picker dialog 
                    Dialog will call the hooks we provide to render the template rows
                    and do something with the selection
                    props: id, action ????
                */}
                <Route path="/popup/pick/:id/:action"
                       render={(props) => <BrowseModal
                            {...props}
                            onBrowseAction={browseActions.actionFromRoute}
                            dataScope={dataControlModel.current}
                            noSecurityContext={noSecurityContext}
                            />
                        }
                />
                <Route path="/popup/browse-custom"
                       render={(props) => <BrowseModal
                            {...props}
                            customTitle={"Pick a component"}
                            onBrowseAction={browseActions.actionEditTemplate}
                            dataScope={dataControlModel.current}
                            noSecurityContext={noSecurityContext}/>
                        }
                />
                <Route path="/standalone/popup/media/:id/:action?"
                       render={(props) => <MediaModal {...props} uploadPath={uploadPath} dataScope={dataControlModel.current} noSecurityContext={noSecurityContext}/>}/>

                <Route path="/popup/media/:id/:action?"
                       render={(props) => <MediaModal {...props} uploadPath={uploadPath} dataScope={dataControlModel.current} noSecurityContext={noSecurityContext}/>}/>

                <Route path="/popup/share/:id"
                       render={(props) => <ShareModal {...props} dataScope={dataControlModel.current}/>}/>

                <Route path="/standalone/edit/attached/:externalId/:attached/:instances"
                       render={(props) => <SchemaAttachedEditor {...props} securityContext={securityContext}></SchemaAttachedEditor> } />

                <Route path="/standalone/browse-custom"
                       render={(props) => <BrowseModal
                            {...props}
                            customTitle={"Pick a component"}
                            fullScreenMode={true}
                            onBrowseAction={browseActions.actionEditTemplate}
                            dataScope={dataControlModel.current}
                            noSecurityContext={noSecurityContext}/>
                        }
                />

                <Route path="/standalone/edit-template/:id"
                       render={(props) => <Admin/>}
                />      
                

                <Route path="/standalone/edit-template/:id"
                       render={(props) => <EditTemplateModal {...props} dataScope={dataControlModel.current}/>}/>

                <Route render={(props) =>
                    <div >
                        {!props.history.location.pathname.includes('/standalone') &&
                        <PreviewViewComponent
                            {...props}
                            event={event}
                            mode={global.env.mode}
                            sideBarOpened={this.state.sideBarOpened}
                            sideBarWidth={this.state.sideBarWidth}
                            dataControlModel={dataControlModel}
                            onDataControlModelChange={val => {
                                this.setState((state) => {
                                    let updatedModel = {...state.dataControlModel, ...val};
                                    this.persistDataControlModel(this.state.instance, updatedModel);
                                    return {dataControlModel: updatedModel};
                                });

                            }}
                            previewRefresh={previewRefresh}
                            instance={instance}
                            onShareClick={this.onShareClick}
                            onSandboxToggle={this.onSandboxToggle}
                            onSandboxRecreate={this.onSandboxRecreate}
                            noSecurityContext={this.state.noSecurityContext}
                            campaignUuid={campaignUuid}
                            globalVariables={global.env.globalVariables}
                            offerIds={global.env.offerIds}
                        />}</div>}
                />

            </MuiThemeProvider>
            </AppErrorBoundary>
        );
    }
}

export default withRouter(App);
