
import Api from './../Share/api';
import idEncoder from "../Share/idEncoder";

const debug = window.debug('app:BrowseActions');

function buildUpdatedPropRow(action, id, refInstance) {
    let row = {};
    if (action === 'append') {

        // 3)
        row = {
            instance: id.instance,
            // scope: newInstance.scope,
            $value: {action: 'append', value: refInstance},
            name: id.prop
        };
    } else {
        // 2,3)
        row = {
            instance: id.instance,
            // scope: newInstance.scope,
            $value: {action: 'set', value: refInstance, replaces: id.templateInstance},
            name: id.prop
        };
    }

    return row;
};

// create a new empty template
function createNewTemplate(dataScope, newTemplateName) {
    debug('create template');
    let api = new Api();

    // create an empty template
    return api.templateClone(null, newTemplateName, dataScope.ref);
}

async function assignTemplateToBlock(action, dataScope, id, newTemplateName, noSecurityContext) {
    let created = await createNewTemplate(dataScope, newTemplateName);

    let api = new Api();
    let response = null;
    let assigned = null;
    if (created) {
        try {
            // create a new block and assign the template to it
            assigned = await api.templateAssign(null, id.instance, created.templateName, dataScope.ref);
            
            // change property value to point to the new block
            let newInstanceId =  assigned.value.instance;
            let row = buildUpdatedPropRow(action, id, newInstanceId);
            let paramNoSecurityContext = dataScope.type === 'sandbox' ? 0 : noSecurityContext;
            response = await api.save([row], dataScope.ref, true, paramNoSecurityContext);
        } catch (err) {
            debug({err});
        }
    }

    return {response, assigned};
}


export default {
    /**
     * supported/required query params
     * - action : "append"/"set" the template on a block
     * - id : block context identifier
     */
    actionFromRoute: async ({match, history, dataScope, location, template, newTemplateName, blockInstance, noSecurityContext}) => {

        // passed in block context identifier
        // let queryParams = new URLSearchParams(location.search);

        let id = idEncoder.decode(match.params.id);

        let paramNoSecurityContext = dataScope.type === 'sandbox' ? 0 : noSecurityContext;

        if (!template && newTemplateName) {
            // we are picking the new template to create and assign to a block
            // new template needs to be created ...
            let data = await assignTemplateToBlock(match.params.action, dataScope, id, newTemplateName, paramNoSecurityContext);
            history.push('/popup/edit/' + btoa(JSON.stringify({instance: data.assigned.value.instance})));
        }
        // if (isReusable(template)) {
        //     debug('picked - reusable', blockInstance);

        //     // 1) create a new instance
        //     // 2) assign the picked template to it
        //     // 3) append the new instance to the value
        //     try {
        //         let response = await (new Api()).blockClone(blockInstance, id.instance, dataScope.ref);
        //         let newInstance = response.clone.newInstance;

        //         let row = buildUpdatedPropRow(queryParams.action, id, newInstance);
        //         if (row) {
        //             debug(row);
        //             (new Api()).save([row], dataScope.ref).then(() => {
        //                 // reload preview
        //                 window.postMessage({action: 'saved'}, '*');
        //             });

        //         }
        //     } catch (e) {
        //     }
        // } else {
        else {
            debug('picked', template);
    
            // 1) create a new instance
            // 2) assign the picked template to it
            // 3) append the new instance to the value
            try {
                let response = await (new Api()).templateAssign(null, id.instance, template.name, dataScope.ref, paramNoSecurityContext);
                let newInstance = response.value;
    
                let row = buildUpdatedPropRow(match.params.action, id, newInstance.instance);
                if (row) {
                    // in sandbox mode, we always use security context 
                    
                    debug(row);
                    (new Api()).save([row], dataScope.ref, true, paramNoSecurityContext).then(() => {
                        // reload preview
                        window.postMessage({action: 'saved'}, '*');
                    });
    
                }
                history.push('/popup/edit/' + btoa(JSON.stringify({instance: newInstance.instance})));
    
            } catch (e) {
            }
        }

        
    },
    actionEditTemplate: async ({match, dataScope, template, history, newTemplateName}) => {
        
        if (template) {
            // template was picked - edit it]
            debug({template});
            let id = idEncoder.encode({templateName: template.name});
            history.push('/popup/edit-template/' + id);
        } else if (!template && newTemplateName) {
            // we are picking the new template to create and assign to a block
            // new template needs to be created ...
            let newTemplate = await createNewTemplate(dataScope, newTemplateName);

            // go and edit the new template
            history.push('/popup/edit-template/' + idEncoder.encode({templateName: newTemplate.templateName}));
        }
        

    }
};