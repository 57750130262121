/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import ColorPicker from 'material-ui-color-picker';


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "22px",
        zIndex: 99999,
        maxWidth: "300px"
    },
    label: {
        fontSize: "12px",
        paddingBottom: "8px",
        display: "block"
    }
});


class BoxShadow extends React.Component {
    state = {
        inputValue: this.props.value,
    };

   

    handleInputChange = (newValue) => {
        //this.setState({inputValue});
       // return inputValue;
    };

    


    render() {
        const {classes,  label} = this.props; //value

        return (
            <div className={classes.root}>
                <NoSsr>
                <InputLabel className={classes.label}><strong>{label}</strong></InputLabel>

                    <InputLabel className={classes.label}>Horizontal Length</InputLabel>
                    <Input id="bsHLength" aria-describedby="Horizontal Length" />
                    <InputLabel className={classes.label}>Vertical Lengt</InputLabel>
                    <Input id="bsVLength" aria-describedby="Vertical Length" />
                    <InputLabel className={classes.label}>Blur Radius</InputLabel>
                    <Input id="bsBlurRadius" aria-describedby="Blur Radius" />
                    <InputLabel className={classes.label}>Spread Radius</InputLabel>
                    <Input id="bsSpreadRadius" aria-describedby="Spread Radius" />
                    <InputLabel className={classes.label}>Shadow Colour</InputLabel>
                    <ColorPicker id="bsShadowColour" aria-describedby="Shadow Colour" />
                    <InputLabel className={classes.label}>Background Colour</InputLabel>
                    <ColorPicker id="bsBackgroundColour" aria-describedby="Background Colour" />
                    <InputLabel className={classes.label}>Box Colour</InputLabel>
                    <ColorPicker id="bsBoxColour" aria-describedby="Box Colour" />
                    <InputLabel className={classes.label}>Opacity</InputLabel>
                    <Input id="bsOpacity" aria-describedby="Opacity" />
                </NoSsr>
            </div>
        );
    }
}

BoxShadow.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(BoxShadow);
