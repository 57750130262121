import React from "react";
import {convertFromHTML, convertToRaw, ContentState, EditorState} from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import debounce from "../Share/debounce";
import InputLabel from "@material-ui/core/InputLabel";

/**
 * Why draft-js?
 *  - supports any content
 *  - you can mangle any content -> replace image blocks with a react Image component
 *  - developed by facebook
 *
 * References:
 * https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/convertFromHTML/convert.html
 * https://draftjs.org/docs/api-reference-data-conversion.html#content
 *
 * Higher level component:
 * https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
 */


export default class HtmlEditor extends React.Component {
    constructor(props) {
        super(props);
        
        let contentState = this.toContentState(props.value);
        let editorState =  EditorState.createWithContent( contentState /*, decorator*/);

        this.state = {editorState};

        this.parentOnChange = debounce(() => {
            const content = this.state.editorState.getCurrentContent();
            this.props.onChange(draftToHtml(convertToRaw(content)))
        }, 0); // currently disabled
    }

    onChange = (editorState) => {
        this.setState({editorState});
        this.parentOnChange();
    };

    toContentState(html) {
        let state = null;
        try {
            const blocksFromHTML = convertFromHTML(html);
            state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        } catch(e) {
            state = ContentState.createFromText('');
        }
        return state;
    }

    componentDidUpdate(prevProps) {
        // only enabled when requested
        // we don't want to update on save as we loose cursor position.
        // alternative is to recreate the component instead of updating it. This can be done with prop "key"
        if (!this.props.watchValueUpdate || this.props.watchValueUpdate === prevProps.watchValueUpdate) {
            return;
        }

        if (this.props.value !== prevProps.value) {
            const editorState = EditorState.createWithContent(this.toContentState(this.props.value))
            this.setState({editorState});
        }
    }

    render() {
        const { editorState } = this.state;
        const {label} = this.props;
        return (
            <div>
                <InputLabel>{label}</InputLabel>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onChange}
                />
            </div>
        );
    }
}
