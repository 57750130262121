/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import MediaPicker from '../../MediaPicker';
import ColorPicker from 'material-ui-color-picker';
import {Select,  MenuItem, InputLabel, Grid, Checkbox} from '@material-ui/core';
const styles = theme => ({
    
});

// CSS SYNTAX
//background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;

function stripQuotes(image) {
    return image.replace(/'/g,'');
}

class Background extends React.Component {
    state = {
        inputValue: this.props.value,
        image: '',
        usebg: true,
        position: '',
        size: '',
        color: '',
        repeat: ''
    };
    componentDidMount = () => {
        let { inputValue } = this.state;

        if (inputValue) {
            let val = JSON.parse(inputValue);

            this.setState(
                {
                    image: val.variables.image,
                    position: val.variables.position,
                    size: val.variables.size,
                    color: val.variables.color,
                    repeat: val.variables.repeat,
                    usebg: val.variables.usebg
                }
            )
        }

        // if (typeof inputValue === 'string' || inputValue instanceof String) {
        //     if (this.props.initialValue && inputValue === null){
        //         inputValue = this.props.initialValue
        //     }
        //     let size = inputValue.match(/(\d+)/)[0];
        //     let unit = inputValue.substring(size.toString().length);
        //     this.setState({size, unit});
        // }
    }
    


    onChange = () => {
         let {image, position,size,color,repeat,usebg} = this.state;
        image = stripQuotes(image);
         let value = {
            "set": `${color} ${usebg && image ? ('url("' + image + '")') : ''} ${position} ${size} ${repeat}`,
            "quoteless":  true,
            "variables": {image: `'${image}'`, usebg, position,size,color: color,repeat}
         };
         this.props.onChange(value);
    }

    onColorChange = (val) => {
        //When clicking off the colour picker, it was setting val to undefined(?)
        if(val !== undefined){
            this.setState({color: val}, () => {
                this.onChange();
            });
        }
   }
    onPositionChange = (e) => {
         this.setState({position: e.target.value}, () => {
             this.onChange();
         });
    }
    onUseBGChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
         this.setState({usebg: value}, () => {
             this.onChange(); 
         });
    }

    onSizeChange = (e) => {
         this.setState({size:  e.target.value}, () => {
             this.onChange();
         });
         
    }

    onImageChange = (val) => {
        this.setState({image: val}, () => {
            this.onChange();
        });
   }
   

    onRepeatChange = (e) => {
        this.setState({repeat:  e.target.value}, () => {
            this.onChange();
        });
        
   }
    render() {
        const {classes, label, id, event} = this.props; //value
        let {image, position,size,color,repeat,usebg} = this.state;
        image = stripQuotes(image);
        return (
            <div className={classes.root}>
                <NoSsr>
                    <InputLabel>{label}</InputLabel>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <MediaPicker event={event} value={image} id={id} onChange={this.onImageChange} />
                            <InputLabel style={{marginTop: 18, display:"inline"}}  id="border-style">Enable Image?</InputLabel>
                            <Checkbox style={{marginTop: -2, display:"inline-block"}}   value={usebg} onChange={this.onUseBGChange} checked={this.state.usebg}/>
                        </Grid>      
                        <Grid item xs={6}> 
                            <InputLabel style={{marginTop: 18}}  id="border-style">Position</InputLabel>
                            <Select value={position} onChange={this.onPositionChange}> 
                                <MenuItem value={"center center"}>center center</MenuItem>
                                <MenuItem value={"left center"}>left center</MenuItem>
                                <MenuItem value={"right center"}>right center</MenuItem>
                                <MenuItem value={"center top"}>center top</MenuItem>
                                <MenuItem value={"left top"}>left top</MenuItem>
                                <MenuItem value={"right top" }>right top</MenuItem>
                                <MenuItem value={"center bottom"}>center bottom</MenuItem>
                                <MenuItem value={"left bottom"}>left bottom</MenuItem>
                                <MenuItem value={"right bottom"}>right bottom</MenuItem>
                            </Select>
                       
                            <InputLabel style={{marginTop: 18}}  id="border-style">Size</InputLabel>
                            <Select value={size} onChange={this.onSizeChange}> 
                                <MenuItem value={"auto"}>auto</MenuItem>
                                <MenuItem value={"cover"}>cover</MenuItem>
                                <MenuItem value={"contain"}>contain</MenuItem>
                                <MenuItem value={"100%"}>100%</MenuItem>
                            </Select>
                        
                            <InputLabel style={{marginTop: 18}}  id="border-style">Repeat</InputLabel>
                            <Select value={repeat} onChange={this.onRepeatChange}> 
                                <MenuItem value={"repeat"}>repeat</MenuItem>
                                <MenuItem value={"no-repeat"}>no-repeat</MenuItem>
                                <MenuItem value={"repeat-x"}>repeat-x</MenuItem>
                                <MenuItem value={"repeat-y"}>repeat-y</MenuItem>
                                <MenuItem value={"space"}>space</MenuItem>
                            </Select>
                       
                
                            <InputLabel style={{marginTop: 18}}  id="border-style">Colour</InputLabel>
                            <ColorPicker  defaultValue={color || ''}
                                placeholder='Pick a color'
                                onChange={ this.onColorChange }
                                />
                        </Grid>
                    </Grid>                
                </NoSsr>
            </div>
        );
    }
}

Background.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Background);
