import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import React from "react";
import Api from './../Share/api';
import idEncoder from './../Share/idEncoder';
import {withRouter} from 'react-router-dom';
import {Toolbar, TextField, Button, Grid, Snackbar, IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import copyToClipboard from '../Share/copyToClipboard';

import './BroweModel.css';

const styles = theme => ({
    paper: {
        position: 'absolute',
        minWidth: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        outline: 'none',
        maxHeight: '70vh'
    },
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
    },
    MuiToolbar: {
        background: "#13364F",
        color: "#FFF"
    },
    MuiAppBar: {
        background: "#01579b"
    }
});

let modalBodyClasses = {
    modalBody: {
        padding: "20px"
    }
};


class ShareModal extends React.Component {

    constructor(props) {
        super(props);

        // editing either the block, or  a block attached on a parent block via property.


        let id = idEncoder.decode(props.match.params.id);
                this.state = {
            // tab ui
            value: 0,
            // instance holder
            id: id,
            // data to use
            list: [],
            modalStyles: {
                top: `50%`,
                left: `50%`,
                width: '70%',
                minHeight: '70%',
                transform: `translate(-50%, -50%)`,
            },
            fullScreenMode: false,
            shareUrl: "",
            snackOpen: false
        };


    }

    setShareUrl() {
        let url = this.state.id.shareUrl;
        this.setState({'shareUrl': url});
    }


    componentDidMount() {
        document.title = this.displayName || 'Browse';
        this.runApi();
        this.setShareUrl();
    }

    runApi() {
        (new Api()).templateBrowse(this.props.dataScope.ref).then((data) => {
            this.setState({list: data.data});
        });
    }


    handleClose = () => {
        this.props.history.push('/');
    };

    handleSnackClose = () => {
        this.setState({snackOpen: false});
    };

    copy = () => {
        this.setState({snackOpen: true});
                copyToClipboard(this.state.shareUrl);
    }


    render() {
        const {classes} = this.props;
        const {shareUrl} = this.state;


        return <Dialog open={true} onClose={this.handleClose} maxWidth={"lg"}>
            <div>
                <Toolbar className={classes.MuiToolbar}>
                    <Typography variant="h5" color="inherit">
                        Share this page
                    </Typography>

                </Toolbar>
                <div style={modalBodyClasses.modalBody}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField
                                id="outlined-name"
                                label="Share Link"
                                value={shareUrl}
                                margin="normal"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" color="secondary" onClick={this.copy}>
                                Copy
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Copied to your clipboard</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.handleSnackClose}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </div>
        </Dialog>
    }
}

export default withRouter(withStyles(styles)(ShareModal));