import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";


const styles = theme => ({
    root: {
        display: "block",
        padding: "0px 8px",
        marginBottom: "0px !important",
        borderRadius: "4px",
        border: "1px solid #D9DFE3"
    },
    disableBorder: {
        border: "0px"
    },
    helper: {
        display: "block",
        fontSize: "13px",
        fontStyle: "oblique",
        opacity: 0.7,
        marginBottom: "8px"
    }
});

class TextField extends React.Component {

    render() {

        let {classes, label, description, disableBorder, placeholder} = this.props;

        return (
            <>
            <div className="tooltip-contain"> 
                { label && <span className="field-label label-with-tooltip">{label}</span> }
                { description && <span data-text={description} className="i-tooltip">i</span> }
            </div>
            <div className={`${classes.root} ${disableBorder ? classes.disableBorder : ''}`}>
                <input {...this.props} placeholder = {placeholder}></input>
            </div>
            </>
        )
    }
}

export default withStyles(styles, {withTheme: true})(TextField);

