import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

const styles = {
    heading: {
        "margin": "8px 0px",
        fontFamily: "Mulish",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "18px",
        letterSpacing: "0px",
        textAlign: "left",
        "color": "#13364F"
    }
};

class DividerTitleField extends React.Component {
    render() {
        const {label} = this.props;
        return (
            <h3 style={styles.heading}>{label}</h3>
        );
    }
}

DividerTitleField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DividerTitleField);
