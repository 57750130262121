import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import atob from "atob";

const debug = window.debug('app:rev-ui:comp:MediaPicker');

const styles = {

    label: {
        fontSize: "12px",
        paddingBottom: "20px",
        display: "block"
    }
};

class MediaPicker extends React.Component {

    componentDidMount() {
        this.handleEvent(this.props.event);
    }

    componentDidUpdate(prevProps) {
        this.handleEvent(this.props.event);
    }

    handleEvent = (event) => {
        if (event && event.action === 'media-manager-set' && event.transaction == this._trans(this.props)) {
            this.props.onChange(event.media.publicPath);
        }
    }

    removeMedia = () => {
        this.props.onChange("");
    }

    _trans(props) {
        let {id, value} = this.props;
        return `${id}__${value}`;
    }

    onOpenMediaPicker = () => {
        window.postMessage({
            action: 'media-manager',
            backOnSelect: true,
            transaction: this._trans(this.props),
            from: {type: 'editModal', pathname: window.location.pathname},
        }, '*');
    }

 
    render() {

        const {classes, value, label} = this.props;

        return (
            <div className={classes.root}>
                <InputLabel className={classes.label}>{label}</InputLabel> 
                <Grid container spacing={2} className={classes.grid}>   
                    {value && <><Grid item className={classes.gridItem} xs={9}>{ value && <img alt="" style={{maxWidth: "200px",maxHeight: "200px", border: "2px dashed #B9B9B9", padding: 8, marginBottom:10}} src={value}/> }</Grid>
                        <Grid item className={classes.gridItem} xs={3}><IconButton onClick={this.removeMedia}><CloseIcon  /></IconButton></Grid>
                    </>}
                    <Grid item className={classes.gridItem} xs={12}><Button variant="contained" color="secondary" onClick={this.onOpenMediaPicker}>
                        {value && <>Replace image</> } {!value && <>Add image</> }</Button></Grid>            
                    </Grid>
            </div>
        );
    }
}

MediaPicker.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MediaPicker);
