/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ColourPicker from './ColourPicker';
import Size from './Size';
import MediaPicker from '../../MediaPicker';
import FullFontSelector from './FullFontSelector';
import TextField from './TextField';
import {RadioGroup, Radio, Slider, Checkbox, Select, MenuItem} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { BlockPicker } from 'react-color';

const Color = require('color');


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "5px",
        zIndex: 9999,
        maxWidth: "700px"
    },
    label: {
        fontSize: "16px",
        paddingBottom: "12px",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },

});


class WidgetCreator extends React.Component {
    
    state = {
        inputValue: this.props.value
    };

    buildLayout = () => {

        let {layout, shape, backgroundColor, width, height, xdist, ydist, bannertext, icon, iconSize, iconColor, pulsing, widgetSize} = this.state;
        let value = {
            "set": layout,
            "data": JSON.stringify({layout,shape, backgroundColor, width, height, xdist, ydist, bannertext, icon, iconSize, iconColor, pulsing, widgetSize}),
            "quoteless":  false,
            "variables": {layout, shape, backgroundColor, width, height, xdist, ydist,  bannertext, icon, iconSize, iconColor, pulsing, widgetSize}
        };
        this.props.onChange(value);
    }
    handleShapeChange = (e, newValue) => {
        this.setState({shape: newValue}, () => {
            this.buildLayout();
        });
    }   
   
    handleLayoutChange = (e, newValue) => {
        this.setState({layout: newValue}, () => {
            this.buildLayout();
        });
    }

    onTextChange = (e) => {
        this.setState({bannertext: e.target.value}, () => {
            this.buildLayout();
        });
    }
    onUsePulsingChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({pulsing: value}, () => {
            this.buildLayout();
        });
    }

    
    componentDidMount = () => {
        let { value } = this.props;
        if (value !== null && value !== undefined) {
            console.log(value)


            try {
                let jsonValue = JSON.parse(value);

                if (jsonValue !== null) {
                    let jsonData = JSON.parse(jsonValue.data);
                    console.log("jsonData",jsonData)
                    this.setState({
                        layout: jsonData.layout,
                        shape: jsonData.shape,
                        backgroundColor: jsonData.backgroundColor,
                        height: jsonData.height,
                        width: jsonData.width,
                        xdist: jsonData.xdist,
                        ydist: jsonData.ydist,
                        bannertext: jsonData.bannertext,
                        type: jsonData.type,
                        icon: jsonData.icon,
                        iconColor: jsonData.iconColor,
                        iconSize: jsonData.iconSize,
                        pulsing: jsonData.pulsing,
                        widgetSize: jsonData.widgetSize
                    });
                }
            } catch (e) {
                this.setState({layout: value });
            }

        }
        
    }

    render() {
        const { classes, label, id, event } = this.props;
        const { layout, shape, backgroundColor, width, height, xdist, ydist, bannertext,type, icon, iconColor, iconSize, pulsing, widgetSize} = this.state;

        let rowDir = {
            flexDirection: "row"
        }

      
        let showBannerText = shape == "shape-banner" && type == "create" ? { display: "flex"} : { display: "none"}; 
        let marginGrid = { marginBottom: "16px"};

        let ytext = layout == "layout-top-left" || layout == "layout-top-right" ? "Distance from top" : "Distance from bottom"

        return (
            <div className={classes.root}>
                <NoSsr>
                    <div id="RoundelContainer" >
                        <InputLabel className={classes.label}>{label}</InputLabel>

                        <Grid container spacing={2}>                              
                            <Grid item xs={4}>
                                <InputLabel className="minorLabel">Widget Size</InputLabel>
                                   <Select onChange={(e) => {this.setState({widgetSize: e.target.value}, () => {this.buildLayout()}); }}
                                    value={widgetSize} id="widgetSize" label={"Widget size"}>
                                    <MenuItem value={"xs"}>Extra Small</MenuItem>
                                    <MenuItem value={"sm"}>Small</MenuItem>
                                    <MenuItem value={"md"}>Medium</MenuItem>
                                    <MenuItem value={"lg"}>Large</MenuItem>
                                    <MenuItem value={"xl"}>Extra Large</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel className="minorLabel">Background Colour</InputLabel>
                                <ColourPicker
                                    defaultValue={backgroundColor || ''}
                                    onChange={(val, backgroundColor) => {
                                        if (val && isColor(val)) {
                                            this.setState({backgroundColor: val}, () => {this.buildLayout()});
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <InputLabel className="minorLabel">Type</InputLabel>
                                <RadioGroup
                                    aria-label="RoundelShape"
                                    name="RoundelShape"
                                    value={shape}
                                    onChange={this.handleShapeChange}
                                    style={rowDir}
                                >
                                    <label className={shape == 'shape-circle' ? 'layout-radio layout-one-graphic selected'  : 'layout-radio layout-circle-graphic '}>
                                        <span className={"gfx-circle gfx-shape"}></span>
                                        <FormControlLabel className="layout-input" value="shape-circle" control={<Radio />} label="Circle" />
                                    </label>
                                    <label className={shape == 'shape-square' ? 'layout-radio layout-two-graphic selected'  : 'layout-radio layout-square-graphic '}>
                                        <span className={"gfx-square gfx-shape"}></span>
                                        <FormControlLabel className="layout-input" value="shape-square" control={<Radio />} label="Square" />
                                    </label>
                                    <label className={shape == 'shape-quarter-circle' ? 'layout-radio layout-three-graphic selected'  : 'layout-radio layout-quarter-circle-graphic '}>
                                        <span className={"gfx-quarter-circle"}></span>
                                        <FormControlLabel className="layout-input" value="shape-quarter-circle" control={<Radio />} label="quarter circle" />
                                    </label> 
                                </RadioGroup>         
                            </Grid>         
                        </Grid>         
                        <Grid container spacing={2} style={showBannerText}>
                            <Grid item xs={12}>
                                <InputLabel className="minorLabel">Banner Text</InputLabel> 
                            </Grid>
                            <Grid item xs={12}>
                                <TextField style={{margin:0, minWidth: 40}} onChange={(e) => {this.setState({bannertext: e.target.value})}} onBlur={this.onTextChange}  value={bannertext}></TextField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>          
                                <InputLabel className="minorLabel">Position</InputLabel>    
                                <RadioGroup
                                    aria-label="RoundelLayout"
                                    name="RoundelLayout"
                                    value={layout}
                                    onChange={this.handleLayoutChange}
                                    style={rowDir}
                                >
                                    <label className={layout == 'layout-top-left' ? 'layout-radio layout-top-left-graphic selected'  : 'layout-radio layout-top-left-graphic '}>
                                        <span className={"gfx-top-left gfx-layout " + shape}></span>
                                        <FormControlLabel className="layout-input" value="layout-top-left" control={<Radio />} label="Top Left" />
                                    </label>
                                    <label className={layout == 'layout-bottom-left' ? 'layout-radio layout-bottom-left-graphic selected'  : 'layout-radio layout-bottom-left-graphic '}>
                                        <span className={"gfx-bottom-left gfx-layout " + shape}></span>
                                        <FormControlLabel className="layout-input" value="layout-bottom-left" control={<Radio />} label="Bottom Left" />
                                    </label>
                                    <label className={layout == 'layout-top-right' ? 'layout-radio layout-two-graphic selected'  : 'layout-radio layout-top-right-graphic '}>
                                        <span className={"gfx-top-right gfx-layout " + shape}></span>
                                        <FormControlLabel className="layout-input" value="layout-top-right" control={<Radio />} label="Top Right Top" />
                                    </label>
                                    <label className={layout == 'layout-bottom-right' ? 'layout-radio layout-bottom-right-graphic selected'  : 'layout-radio layout-bottom-right-graphic '}>
                                        <span className={"gfx-bottom-right gfx-layout " + shape}></span>
                                        <FormControlLabel className="layout-input" value="layout-bottom-right" control={<Radio />} label="Bottom Right" />
                                    </label>
                                </RadioGroup>    
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={marginGrid}>
                            <Grid item xs={4}>
                                <InputLabel className="minorLabel">Distance from side</InputLabel>
                                <Size  value={xdist} onChange={(v) => {this.setState({xdist: v}, () => {this.buildLayout()}); }}></Size>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel className="minorLabel">{ytext}</InputLabel>
                                <Size  value={ydist} onChange={(v) => {this.setState({ydist: v}, () => {this.buildLayout()}); }}></Size>
                            </Grid>                      
                        </Grid>                    

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <InputLabel className="minorLabel">Trigger Icon</InputLabel> 
                                <Select onChange={(e) => {this.setState({icon: e.target.value}, () => {this.buildLayout()}); }}
                                    value={icon} id="icon" label={"Icon image"}>
                                    <MenuItem value={"icon-wallet"}>Wallet</MenuItem>
                                    <MenuItem value={"icon-purse"}>Purse</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel className="minorLabel">Icon Colour</InputLabel>
                                <Select onChange={(e) => {this.setState({iconColor: e.target.value}, () => {this.buildLayout()}); }}
                                    value={iconColor} id="icon" label={"Icon Colour"}>
                                    <MenuItem value={"icon-light"}>Light</MenuItem>
                                    <MenuItem value={"icon-dark"}>Dark</MenuItem>
                                </Select>
                            </Grid>
                          
                        </Grid>              
                
                    </div>
                </NoSsr>
            </div>
        );
    }
    
}


function isColor(val) {
    let success = false;
    try {
        Color(val);
        success = true;
    } catch (e) {
    }
    return success;
}



WidgetCreator.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(WidgetCreator);
