
import Api from "./api";

const sandboxHelper = {

    // returns sandboxUuid
    generateCode: async function({branch = null, instance = null}) {
        let api = new Api();
        return api.sandboxCreate(instance, branch);
    },
    // handle sandbox request param in an uniform way everywhere
    // figure out the if sandbox is supposed to be on or off ...
    fromInput: function(stringParam) {
        if (stringParam && stringParam.indexOf('__') !== -1) {
            // its already a valid sandbox code
            return stringParam;
        }
        return null;
    },
    isSandbox: function(ref) {
        return (ref || '').indexOf('__') !== -1;
    }
};

export default sandboxHelper;