const debug = window.debug('app:api');

class Api {
    constructor() {
        this.endPoint = global.env.api_endpoint;
    }

    async templateAssign(instance, scopeFromInstance, templateName, dataScopeRef, noSecurityContext) {
        return this.fetch(this.src('/template/assign'), this.makeOpts('POST', {
            instance: instance,
            scopeFromInstance,
            templateName,
            dataScopeRef,
            jwt: global.env.jwt,
            noSecurityContext
        }));
    }

    async templateClone(templateName, newTemplateName, dataScopeRef) {
        return this.fetch(this.src('/template/clone'), this.makeOpts('POST', {
            templateName,
            newTemplateName,
            dataScopeRef,
            jwt: global.env.jwt
        }));
    }

    async blockClone(instance, useInstanceRootScope, dataScopeRef) {
        return this.fetch(this.src('/block/clone'), this.makeOpts('POST', {
            instance,
            useInstanceRootScope,
            dataScopeRef,
            jwt: global.env.jwt
        }));
    }

    async templateInspect(instance, noRecurse, dataScopeRef, templateName = null, attachPropsOnly = null, attachId = null) {
        let params = {
            noRecurse: noRecurse ? 1 : 0,
            dataScopeRef,
            jwt: global.env.jwt
        };
        if (instance) {
            params.instance = instance;
        }
        if (templateName) {
            params.templateName = templateName;
        }
        if (attachPropsOnly) {
            params.attachPropsOnly = attachPropsOnly;
        }
        if (attachPropsOnly) {
            params.attachId = attachId;
        }
        debug({params});
        return this.fetch(this.src('/template/inspect', params), 'GET');
    }

    
    async attachInspect(instance, securityContext ) {
        let params = {
            instance,
            securityContext,
            jwt: global.env.jwt
        };
        return this.fetch(this.src('/attach/inspect', params), 'GET');
    }



    async dataInspect(instance, resolution = 20) {
        return this.fetch(this.src('/data/inspect', {
            instance,
            resolution,
            jwt: global.env.jwt
        }), 'GET');
    }

    async dataPublish(params) {
        return this.fetch(this.src('/data/publish'), this.makeOpts('POST', Object.assign(params, {
            jwt: global.env.jwt
        })));
    }

    async dataBranch(scope, branch) {
        return this.fetch(this.src('/data/branch'), this.makeOpts('POST', {
            scope,
            toRef: branch,
            jwt: global.env.jwt
        }));
    }


    async templateBrowse(dataScopeRef, forBlockPropId = null) {
        let request = {dataScopeRef, jwt: global.env.jwt};
        if (forBlockPropId) {
            request['forBlockPropId'] = forBlockPropId;
        }
        return this.fetch(this.src('/template/browse', request), 'GET');
    }

    async instanceBrowseByTag(tags) {
        let request = {tags, jwt: global.env.jwt};
        return this.fetch(this.src('/template/browseInstances', request), 'GET');
    }

    async save(rows, dataScopeRef, changeOnly = true, noSecurityContext = false) {
        return this.fetch(this.src('/save'), this.makeOpts('POST', {data: rows, dataScopeRef, changeOnly: changeOnly ? 1 : 0, noSecurityContext: noSecurityContext ? 1 : 0, jwt: global.env.jwt}));
    }

    // location is deduced from JWT
    async browseMedia() {
        return this.fetch(this.src('/media/browse', {jwt: global.env.jwt}), 'GET');
    }

    async sandboxCreate(instance, branch = null) { // create a new sandbox
        return this.fetch(this.src('/sandbox/new'), this.makeOpts('POST', {jwt: global.env.jwt, instance, branch}));
    }

    async uploadMedia(file, path) {
        let data = new FormData()
        data.append('file', file)
        return this.fetch(this.src('/media/upload', {path, jwt: global.env.jwt}), {
            method: "POST",
            body: data
        });
    }
    

    makeOpts(method, data, rawData) {
        let opts = {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors'
        };

        if (method === 'POST') {
            opts = Object.assign(opts, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: rawData || JSON.stringify(data), // body data type must match "Content-Type" header
            });
        }

        return opts;
    }

    async fetch(url, opts) {
        debug({url, opts});
        if (typeof opts === 'string') {
            opts = {method: opts};
        }
        return fetch(url, opts).then(response => {
            if (response.headers.get('content-type') && response.headers.get('content-type').indexOf('application/json') !== -1) {
                // parse json
                return response.json();
            }

            return response.text();
        });
    }


    src(path, queryParams) {
        // fix slashes
        let endpoint = this.endPoint;
        endpoint += this.endPoint[this.endPoint.length - 1] !== '/' ? '/' : '';
        if (path[0] === '/') {
            // remove forward slash
            path = path.substring(1);
        }

        // build query
        let query = '';
        if (queryParams) {
            const params = new URLSearchParams();
            for (const [key, val] of Object.entries(queryParams)) {
                params.append(key, val);
            }
            query = '?' + params.toString();
        }

        return `${endpoint}${path}${query}`;
    }


}

export default Api;