import {withStyles} from '@material-ui/core/styles';
import React, {Fragment, useState, useRef} from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Api from './../Share/api';
import Button from '@material-ui/core/Button';
import {withRouter} from 'react-router-dom';
import Toolbar from "@material-ui/core/Toolbar";
import mediaNameHelper from './../Share/mediaNameHelper';
import Chip from "@material-ui/core/Chip";
import Dialog from '@material-ui/core/Dialog';
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import './MediaModel.css';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from '@material-ui/core/TextField';
const debug = window.debug('app:rev-ui:MediaModal');

const styles = theme => ({
    paper: {
        position: 'absolute',
        minWidth: theme.spacing.unit * 60,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        outline: 'none',
        maxHeight: '70vh'
    },
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
    },
    MuiToolbar: {
        background: "#13364F",
        color: "#FFF"
    },
    MuiAppBar: {
        background: "#01579b",
        color: "#FFF"
    }
});

let modalBodyClasses = {
    modalBody: {
        padding: "20px",
        position: "relative",
        overflow: "auto",
        height: "calc(100% - 100px)"
    }
};

const imageEditorTheme = {
    'common.bi.image': 'https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png',
    'common.bisize.width': '251px',
    'common.bisize.height': '21px',
    'common.backgroundImage': 'none',
    'common.backgroundColor': '#1e1e1e',
    'common.border': '0px',

    // header
    'header.backgroundImage': 'none',
    'header.backgroundColor': 'transparent',
    'header.border': '0px',

    // load button
    'loadButton.backgroundColor': '#fff',
    'loadButton.border': '1px solid #ddd',
    'loadButton.color': '#222',
    'loadButton.fontFamily': '\'Noto Sans\', sans-serif',
    'loadButton.fontSize': '12px',

    // download button
    'downloadButton.backgroundColor': '#fdba3b',
    'downloadButton.border': '1px solid #fdba3b',
    'downloadButton.color': '#fff',
    'downloadButton.fontFamily': '\'Noto Sans\', sans-serif',
    'downloadButton.fontSize': '12px',

    // main icons
    'menu.normalIcon.path': 'https://revuicdn.revlifter.com/global/revuistudio/toast/icon-d.svg',
    'menu.normalIcon.name': 'icon-d',
    'menu.activeIcon.path': 'https://revuicdn.revlifter.com/global/revuistudio/toast/icon-b.svg',
    'menu.activeIcon.name': 'icon-b',
    'menu.disabledIcon.path': 'https://revuicdn.revlifter.com/global/revuistudio/toast/icon-a.svg',
    'menu.disabledIcon.name': 'icon-a',
    'menu.hoverIcon.path': 'https://revuicdn.revlifter.com/global/revuistudio/toast/icon-c.svg',
    'menu.hoverIcon.name': 'icon-c',
    'menu.iconSize.width': '24px',
    'menu.iconSize.height': '24px',

    // submenu primary color
    'submenu.backgroundColor': '#1e1e1e',
    'submenu.partition.color': '#3c3c3c',

    // submenu icons
    'submenu.normalIcon.path': 'https://revuicdn.revlifter.com/global/revuistudio/toast/icon-d.svg',
    'submenu.normalIcon.name': 'icon-d',
    'submenu.activeIcon.path': 'https://revuicdn.revlifter.com/global/revuistudio/toast/icon-c.svg',
    'submenu.activeIcon.name': 'icon-c',
    'submenu.iconSize.width': '32px',
    'submenu.iconSize.height': '32px',

    // submenu labels
    'submenu.normalLabel.color': '#8a8a8a',
    'submenu.normalLabel.fontWeight': 'lighter',
    'submenu.activeLabel.color': '#fff',
    'submenu.activeLabel.fontWeight': 'lighter',

    // checkbox style
    'checkbox.border': '0px',
    'checkbox.backgroundColor': '#fff',

    // range style
    'range.pointer.color': '#fff',
    'range.bar.color': '#666',
    'range.subbar.color': '#d1d1d1',

    'range.disabledPointer.color': '#414141',
    'range.disabledBar.color': '#282828',
    'range.disabledSubbar.color': '#414141',

    'range.value.color': '#fff',
    'range.value.fontWeight': 'lighter',
    'range.value.fontSize': '11px',
    'range.value.border': '1px solid #353535',
    'range.value.backgroundColor': '#151515',
    'range.title.color': '#fff',
    'range.title.fontWeight': 'lighter',

    // colorpicker style
    'colorpicker.button.border': '1px solid #1e1e1e',
    'colorpicker.title.color': '#fff'
};


function EditMedia(props) {
    return <div>
        <Button variant="contained" color="secondary" onClick={props.onSave}
                style={{position: "absolute", left: 30, top: 30, zIndex: 999999}}>Save as</Button>
        <ImageEditor
            ref={props.imageEditorRef}
            includeUI={{
                loadImage: {
                    path: props.publicPath,
                    name: 'SampleImage'
                },

                theme: imageEditorTheme,
                initMenu: 'filter',
                uiSize: {
                    width: '100%',
                    height: '750px'
                },
                menuBarPosition: 'bottom'
            }}
            cssMaxHeight={500}
            cssMaxWidth={700}
            selectionStyle={{
                cornerSize: 20,
                rotatingPointOffset: 70
            }}
            usageStatistics={true}
        />
    </div>
}

function MediaPreview(props) {

    let debug = window.debug('app:MediaPreview');

    function renderMedia() {
        let {media, classes} = props;

        if (!media.name) {
            debug({media});
            return <div></div>;
        }

        // parse the media filename
        let {width, height} = mediaNameHelper.mediaNameDecode(media.name);

        debug(media);
        return <Fragment>
            <img alt="" src={media.publicPath} className={classes.img} style={{maxWidth: 250}}/>
            <p>{media.publicPath}</p>
            <p>w: {width}, h: {height}</p>
        </Fragment>
    }


    return <Fragment>
        {props.media && renderMedia()}
    </Fragment>
}

let StyledMediaPreview = withStyles({img: {width: '100%'}})(MediaPreview);

function BrowseMedia(props) {
    let {classes, mediaList, onSelect, onEdit, highLightMedia} = props;

    let [textFilter, setTextFilter] = useState("");

    function style(media) {
        let style = {padding: "4px"};
        if (media !== undefined && highLightMedia && media.name === highLightMedia.name) {
            style.background = "#333";
            style.color = "#FFF";
        }
        return style;
    }

    function renderMediaList() {
        let list = [];

        let first = highLightMedia ? highLightMedia.name : '';

        mediaList = mediaList.sort(function (x, y) {
            return x.name === first ? -1 : y.name === first ? 1 : 0;
        });

        console.log("mediaList", mediaList)

        function thumbnail(path) {
            // split to path and filename
            let [partName, container] = path.split('/').reverse();
            let mediaData = mediaNameHelper.mediaNameDecode(partName);
            mediaData.width = 150;
            mediaData.height = 100;
            return global.env.cdnBasePath + '/' + container + '/' + mediaNameHelper.mediaNameEncode(mediaData);
        }

        if (mediaList) {
            if (mediaList.length === 0) {
                list = [];
            } else {
                let grouped = {};
                for (let item of mediaList) {
                    let {width, height, name, version} = mediaNameHelper.mediaNameDecode(item.name); 
                    if (!version) {
                        continue;
                    }
                    if (!grouped[name]) {
                        grouped[name] = {max: null, list:[]};
                    }
                    let g = grouped[name];
                    
                    if (g.max === null) {
                        g.max = item;
                    } else {
                        let {width:gWidth, height:gHeight} = mediaNameHelper.mediaNameDecode(g.max.name); 
                        if (gWidth * gHeight < width * height) {
                            g.max = item;
                        }
                    }
                    g.list.push(item);
                    grouped[name] = g;
                }
            
                grouped = Object.entries(grouped).map(i => i[1]);
                list = grouped.map(itemGroup => {
                    const imageList = itemGroup.list.map(image => ({
                        ...image,
                        lastModified: new Date(image.lastModified)
                    }));
                    imageList.sort((a, b) => a.lastModified - b.lastModified);
                    let item = imageList[0];

                    let {name} = item.name ? mediaNameHelper.mediaNameDecode(item.name) : "untitled-image";
                    
                    if (name.toLowerCase().includes(textFilter.toLowerCase()) && item.publicPath) {
                        return <Fragment>
                            <Card className={classes.browseMediaCard} onClick={e => onSelect(item, e)} >
                                <CardContent>
                                    <div className={classes.browseMediaCardImage} style={{"background-image": "url("+item.publicPath+")"}} title={name}></div>
                                </CardContent>
                            </Card>
                        </Fragment>;
                    }else{
                        return null;
                    }
                });
            }
        } else {
            list = ['loading media....'];
        }

        return list.map((item, index) => item && <Grid item xs={2} style={style(mediaList[index])}
                                               key={index}>{item}</Grid>);
    }

    function onTextFilterChange(e, val) {
        setTextFilter(e.target.value);
    }


    return <div>
        <TextField placeholder={"Filter by name"} value={textFilter} onChange={onTextFilterChange}></TextField>
        <Grid container spacing={8} className={classes.ul}>
            {renderMediaList()}
        </Grid>
    </div>
}

let StyledBrowseMedia = withStyles({
    ul: {listStyle: 'none', padding: "10px 24px", marginTop: 20},
    chipRoot: {fontSize: '10px', height: 'auto'},
    chipLabel: {fontSize: '10px',  padding: '4px', height: 'auto'},
    browseMediaCard: {textAlign: 'center'},
    browseMediaCardImage : {width: "100%", height: 150, marginBottom: 8, cursor: "pointer", border: "1px solid rgba(0,0,0,0.05)", "box-shadow":"0 5px 5px aliceblue","background-position": "center", "background-size": "contain","background-repeat":"no-repeat"},
    browseMediaCardName:  {padding: "8px 0"},
    browseMediaCardButton: {margin: 2}
})(BrowseMedia);

// uploadPath
function UploadMedia(props) {
    let debug = window.debug('app:UploadMedia');
    const inputEl = useRef(null);
    function getDimensions(file) { 
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.onload = function () {
                resolve({width: this.width, height: this.height});
                // alert("The image width is " +this.width + " and image height is " + this.height);
            };
            image.src = URL.createObjectURL(file);
        });
    }

    async function handleUpload(files) {
        let api = new Api();
        let lastData = null;

        async function sendFile(file) {
            // upload the file to the server
            debug(file);
            let fileDimensions = await getDimensions(file);
            const fileNameExt = mediaNameHelper.parseNameExtension(file.name);
            let path = mediaNameHelper.mediaNameEncode({
                ...fileDimensions,
                name: mediaNameHelper.cleanupStr(fileNameExt.name),
                ext: mediaNameHelper.cleanupStr(fileNameExt.ext),
            });
            debug({path});
            let uploadedFile = await api.uploadMedia(file, path);
            props.setHighLightMedia(uploadedFile.media);
            return uploadedFile;
        }

        for (let i = 0, numFiles = files.length; i < numFiles; i++) {
            const file = files[i];
            // upload a file
            lastData = await sendFile(file);
        }

        // call the parent handler with the uploaded files identifiers
        props.onUpload(lastData);
    }

    return <div>
        <input ref={inputEl} type={"file"} onChange={(e) => handleUpload(inputEl.current.files)}/>
        <br/>
        <p><i>Allowed image types: png, jpg, gif</i></p>
    </div>
}


class MediaModal extends React.Component {

    imageEditorRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            // tab ui
            value: 0,
            // data to use
            list: [],
            // selected media
            media: null,
            // browsable media
            mediaList: [],
            backOnSelect: false,
            editPublicPath: null,
            highLightMedia: null
        };
    }

    componentDidMount() {
        document.title = this.displayName || 'Media manager';
        this.runApi();
    }

    runApi() {
        return (new Api()).browseMedia().then(media => this.setState({mediaList: media.media}));
    }

    handleChange = (event, value) => {
        this.setState({value});
    };

    handleClose = () => {
        this.props.history.push('/');
    };

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        let byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        let ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        let blob = new Blob([ab], {type: mimeString});
        return blob;
    }

    // onSaveMedia = async () => {
    //     let newFileName = prompt("Please enter a new file name", "");
    //     let imageEditor = this.imageEditorRef.current.imageEditorInst;
    //     let data = imageEditor.toDataURL();
    //     let canvasSize = imageEditor.getCanvasSize();
    //     let newName = mediaNameHelper.mediaNameEncode({
    //         ext: 'png',
    //         name: mediaNameHelper.cleanupStr(newFileName),
    //         width: canvasSize.width,
    //         height: canvasSize.height
    //     });
    //     let file = new File([this.dataURItoBlob(data)], `${newName}`);
    //     let api = new Api();
    //     let media = await api.uploadMedia(file, `${newName}`);
    //     this.runApi();
    //     this.setState({value: 0, highLightMedia: media.media});
    // };

    onSetMedia = (media) => {
        this.runApi();
        this.setState({media, value: 0});
        let searchProps = new URLSearchParams(window.location.search.slice(1));
        if (searchProps.has('event')) {
            let event = JSON.parse(decodeURIComponent(searchProps.get('event')));
            if (this.props.match.params.action === "backonselect") {
                let searchProps = new URLSearchParams(window.location.search.slice(1));
                let from = searchProps.get('from');
                from = JSON.parse(from);
                this.props.history.push(from);
            }
            // send setMedia event
            window.postMessage({
                action: 'media-manager-set',
                transaction: event.transaction, // transactional event
                media
            }, '*');
        }
    };

    onSetHighLightMedia = (media) => {
        this.setState({highLightMedia: media});
    }

    onEditMedia = (media) => {
        this.setState({editPublicPath: media.publicPath, value: 1});
    }

    onUploadMedia = (media) => {
        // do something with the upladed file
        debug({media});

        // media was uploaded in a context of a template.
        // Open the media list tab
        // list media (refresh?)
        this.runApi();
        this.setState({media, value: 0});
    };

    onPreviewMedia = () => {

    };

    // if this is a step 2 dialog
    hasFrom = () => {
        return !!window.location.search.length;
    };

    // go back when ?from was passed as a query string
    goToFrom = (media = null) => {
        let searchProps = new URLSearchParams(window.location.search.slice(1));
        let from = searchProps.get('from');
        from = JSON.parse(from);
        if (searchProps.get('set')) {
            let set = {
                id: searchProps.get('set'),
                value: media.publicPath
            }
            from.search = `?set=${btoa(JSON.stringify(set))}`;
        }
        this.props.history.push(from);
    };

    render() {
        const {classes} = this.props;
        const {value, media, mediaList, editPublicPath, highLightMedia} = this.state;

        return <Dialog open={true} onClose={this.handleClose} fullWidth={true} maxWidth={"lg"}>
            <div style={{height: '80vh',overflow: "hidden"}}>
                <Toolbar className={classes.MuiToolbar}>
                    <Typography variant="h5" color="inherit">
                        Media
                        {this.hasFrom() && <Button color="secondary" variant="contained" style={{marginLeft: 22}}
                                                   onClick={this.goToFrom}>&lt; back</Button>}
                    </Typography>
                </Toolbar>
                <Tabs value={value} onChange={this.handleChange} className={classes.MuiAppBar}>
                    <Tab label="Browse"/>
                    <Tab label="Upload"/>
                </Tabs>

                <div style={modalBodyClasses.modalBody}>
                    <div>
                        {value === 0 && <StyledBrowseMedia mediaList={mediaList}
                                                           onEdit={this.onEditMedia}
                                                           onSelect={this.onSetMedia}
                                                           highLightMedia={highLightMedia}></StyledBrowseMedia>}
                        {value === 1 &&
                        <UploadMedia setHighLightMedia={this.onSetHighLightMedia}
                                onUpload={this.onUploadMedia}>
                        </UploadMedia>}
                    </div>
                    <div>
                        {media && <StyledMediaPreview media={media}></StyledMediaPreview>}
                    </div>
                </div>
            </div>
        </Dialog>
    }
}
   

export default withRouter(withStyles(styles)(MediaModal));