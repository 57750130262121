// @flow
import React from "react";
import MultiSelect from "./components/fields/MultiSelect";
import RadioGroup from "./components/fields/RadioGroup";
import Select from "./components/fields/Select";
import TextField from "./components/fields/TextField";
import Checkbox from "./components/fields/Checkbox";
import Switch from "./components/fields/Switch";

import type {
  FieldRenderer,
} from "react-forms-processor";

const renderer: FieldRenderer = (
  field,
  onChange,
  onFieldFocus,
  onFieldBlur
) => {
  const { id, type } = field;
  switch (type) {
    case "text":
      return <TextField key={id} {...field} />;

    case "textarea":
      return <TextField key={id} multifield {...field} />;

    case "checkbox":
      return <Checkbox key={id} {...field} />;

    case "switch":
      return <Switch key={id} {...field} />;

    case "select":
      return <Select key={id} {...field} />;

    case "multiselect":
      return <MultiSelect key={id} {...field} />;

    case "radiogroup":
      return <RadioGroup key={id} {...field} />;

    default:
      return <div>No mapped field for form id: { id }</div>;
  }
};

export default renderer;
