import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";


const styles = theme => ({
    flexField: {
        display: "flex",
        "align-items": "center",
        "flex-direction": "row"
    },
    inlineLabel: {
        "margin-bottom": "0px !important",
    },

});

class CheckboxStyle extends React.Component {

        
    state = {
        inputValue: this.props.value,
        checked: false
    };

    componentDidMount = () => {
        let { inputValue } = this.state;
        if (inputValue) {
            let val = JSON.parse(inputValue);
            if (val) {
                let data = JSON.parse(val.data);
                this.setState({
                    checked: data.checked
                });
            }
        }
    }

    onCheckChange = (e) => {
        const target = e.target;
        const checked = target.type === 'checkbox' ? target.checked : target.value;
        const checkedVal = checked ? "true" : "false";

        let value = {
            "set": checkedVal,
            "data": JSON.stringify({checked}),
            "quoteless":  true,
            "variables": {checked}
        };
        this.setState({ checked: checked });
        this.props.onChange(value);
    }

    render() {
        let {checked} = this.state;
        let {classes, label} = this.props;
        
        
        return (
            <>
            <div className={classes.flexField}>
                <Checkbox  value={checked} onChange={this.onCheckChange} checked={this.state.checked} />
                { label && <InputLabel className={classes.inlineLabel}>{label}</InputLabel>}
            </div> 
            </>
        )
    }
}

export default withStyles(styles, {withTheme: true})(CheckboxStyle);

