/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import AsyncSelect from 'react-select/lib/Async';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginBottom: "22px",
       
       
    },
    label: {
        fontSize: "14px  !important",
        marginBottom: "16px !important",
        display: "block"
    },
    minorLabel: {
        color: "#13364F !important",
        fontSize: "12px  !important",
        marginBottom: "8px  !important"
    },
    select: {
       
    },
    grid: {
       

    },
    gridItem: {
      
    }

});


function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

class FontSelector extends React.Component {
    state = {
        inputValue: this.props.value,
        suggestions: [],
        fonts: [],
        variantOptions: [],
        variantSelected: new Map(),
        familyValue: null,
        selectedVariant: null
    };

    queryFonts(value) {
        return this.state.fonts.filter(item => {
            return item.family.toLowerCase().includes(value.toLowerCase())
        }).map(suggestion => ({
            value: suggestion.family,
            label: suggestion.family,
            data: suggestion
        }));
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({inputValue});
        return inputValue;
    };

    onFontFamilyChange = (val) => {
        //this.setState({variantSelected: new Map()});


        this.setState({
            "variantOptions": val.data.variants.map(variant => ({
                value: variant,
                label: variant
            }))
        }, function() {
            this.setState({selectedVariant: this.state.variantOptions[0]}, function () {
                this.setState({familyValue: val}, () => {
                    this.onAnyChange({familyValue: val});
                });

            });
        });

        val.data.variants.map(variant => {
            return this.setState({variantSelected: this.state.variantSelected.set(variant, false)});
        });
    }

    onVariantChange = (val) => {
        this.setState({selectedVariant: val}, function () {
            this.onAnyChange();
        });
        /*const item = e.target.name;
         const isChecked = e.target.checked;
         this.setState({variantSelected: this.state.variantSelected.set(item, isChecked)}, () => {
             this.onAnyChange();
         });
            */
    }


    onAnyChange = () => {
        let payload = {familyValue: this.state.familyValue, selectedVariant: this.state.selectedVariant.value};
        this.props.onChange(payload);
    }


    render() {
        const {classes, value, label, showTitle} = this.props;

        const {fonts} = this.state;
        if (fonts.length === 0) {
            fetch("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCMKDhshrAoOW32TCPbye_nOqSBW4br658")
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        fonts: data.items
                    });

                    if (value && isJson(value)) {
                        let valueJson = JSON.parse(value);
                        this.setState({
                            variantOptions: data.items.filter(item => {
                                return item.family === valueJson.set
                            })[0].variants.map(variant => ({
                                value: variant,
                                label: variant
                            }))
                        });

                        /*data.items.filter(item => {
                            return item.family === valueJson.set
                        })[0].variants.map(variant => {
                            this.setState({variantSelected: this.state.variantSelected.set(variant, false)});
                        });*/

                        let selectedFont = data.items.filter(item => {
                            return item.family === valueJson.set
                        })[0];

                        this.setState({
                            familyValue: {
                                value: selectedFont.family,
                                label: selectedFont.family,
                                data: selectedFont
                            }
                        });

                        if (valueJson.variants.length > 0) {
                            this.setState({
                                selectedVariant: {
                                    value: valueJson.variants[0],
                                    label: valueJson.variants[0]
                                }
                            });
                        }


                    }
                });
        }

        const loadOptions = (inputValue, callback) => {
            callback(this.queryFonts(inputValue));
        };

        return (
            <div className={classes.root}>
                <NoSsr>
                    { showTitle && <InputLabel className={classes.label}>{label}</InputLabel>}
                    <Grid container spacing={2} className={classes.grid}>
                        <Grid item className={classes.gridItem} xs={7}>
                            <InputLabel className="minorLabel">Font</InputLabel>
                            <AsyncSelect 
                                key={value}
                                className={classes.select}
                                classNamePrefix={"dropdown"}
                                cacheOptions
                                loadOptions={loadOptions}
                                defaultOptions
                                value={this.state.familyValue}
                                onInputChange={this.handleInputChange}
                                onChange={this.onFontFamilyChange}
                            />
                        </Grid>
                        <Grid item className={classes.gridItem} xs={5}>
                            <InputLabel className="minorLabel">Weight &amp; style</InputLabel>
                            <Select
                                classNamePrefix={"dropdown"}
                                value={this.state.selectedVariant}
                                options={this.state.variantOptions}
                                onChange={this.onVariantChange}
                            />
                        </Grid>
                    </Grid>


                    {/*{*/}
                    {/*    this.state.variantOptions.map(item => (*/}
                    {/*        <label id={item.value} key={item.value}>*/}
                    {/*            {item.label}*/}
                    {/*            <Checkbox id={item.value} key={item.value} name={item.label}*/}
                    {/*                      checked={variantSelected.get(item.label) || false}*/}
                    {/*                      onChange={this.onVariantChange}/>*/}
                    {/*        </label>*/}
                    {/*    ))*/}
                    {/*}*/}


                </NoSsr>
            </div>
        );
    }
}

FontSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(FontSelector);
