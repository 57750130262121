/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import { InputLabel, Button, Grid, Card, CardContent, CardActions, Typography, Select, MenuItem} from '@material-ui/core';
import Size from './Size';
import ColorPicker from 'material-ui-color-picker';


const styles = theme => ({
    
});


class BorderStyle extends React.Component {
    state = {
        inputValue: this.props.value,
        border: {
            l: "0px", r:"0px", t:"0px", b:"0px", color: '#333', style: 'solid', rounding: "0px",
        },
        editorProperty: "border"       
    };

    componentDidMount = () => {
        let { inputValue } = this.state;
        if (inputValue) {
            let val = JSON.parse(inputValue);
            if (val) {
                let data = JSON.parse(val.data);
                this.setState({
                    border: data.border || this.state.border, 
                });
            }
        }
        
    }

    updateProperty = (property1, property2, value) => {
        let property = this.state[property1];
        property[property2] = value;
        this.setState({[property1]: property});
        this.onChange()
    }

    onChange = () => {
        let { margin, padding, border} = this.state;
        let value = {
            "set": `'${border.l}'`,
            "data": JSON.stringify({border}),
            "quoteless":  true,
            "variables": {
                borderLeft: `${border.l}`,
                borderRight: `${border.r}`,
                borderBottom: `${border.b}`,
                borderTop: `${border.t}`,
                borderColor: `${border.color}`,
                borderStyle: `${border.style}`,
                borderRounding: `${border.round}`

            }
        };
        this.props.onChange(value);
    }

    render() {
        let { editorProperty,   margin, padding, border } = this.state;
        const {classes,  label} = this.props;

        return (
            <div className={classes.root}>
                <NoSsr>
                
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <InputLabel>{label}</InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <div class="">
                                    <Card style={{width: "100%"}}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                
                                                 <Grid item xs={4}>  
                                                    <InputLabel style={{marginTop: 18}}  id="border-style">Style</InputLabel>
                                                    <Select onChange={(e) => { this.updateProperty(editorProperty, 'style', e.target.value) }} value={border.style} id="border-style" label={"Border Style"}>
                                                        <MenuItem value={"solid"}>Solid</MenuItem>
                                                        <MenuItem value={"dashed"}>Dashed</MenuItem>
                                                        <MenuItem value={"dotted"}>Dotted</MenuItem>
                                                    </Select>
                                                 </Grid>

                                                 <Grid item xs={4}>
                                                    <InputLabel style={{marginTop: 18}}  id="border-style">Colour</InputLabel>
                                                    <ColorPicker onChange={(v) => { this.updateProperty(editorProperty, 'color', v) }} value={border.color}></ColorPicker>
                                                 </Grid>
                                                 
                                                 <Grid item xs={4}>
                                                    <InputLabel style={{marginTop: 18}}  id="border-style">Rounding</InputLabel>
                                                    <Size  initialValue={this.state[editorProperty].round} value={this.state[editorProperty].t} onChange={(v) => { this.updateProperty(editorProperty, 'round', v) }}></Size>
                                                </Grid>
                                                 
                                               
                                                <Grid item xs={4}>
                                                    <Size label={"Top " + editorProperty} initialValue={this.state[editorProperty].t} value={this.state[editorProperty].t} onChange={(v) => { this.updateProperty(editorProperty, 't', v) }}></Size>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Size label={"Right " + editorProperty} initialValue={this.state[editorProperty].t}  value={this.state[editorProperty].r} onChange={(v) => { this.updateProperty(editorProperty, 'r', v) }}></Size>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Size label={"Bottom " + editorProperty} initialValue={this.state[editorProperty].t}  value={this.state[editorProperty].b} onChange={(v) => { this.updateProperty(editorProperty, 'b', v) }}></Size>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Size label={"Left " + editorProperty}  initialValue={this.state[editorProperty].t}  value={this.state[editorProperty].l} onChange={(v) => { this.updateProperty(editorProperty, 'l', v) }}></Size>
                                                </Grid>
                                               
                                            </Grid>
                                                
                                        </CardContent>
                 
                                    </Card>    
                                </div>

                        </Grid>
                    </Grid>
                </NoSsr>
            </div>
        );
    }
}

BorderStyle.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(BorderStyle);
