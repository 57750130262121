// @flow
import React from "react";
import { Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FieldWrapper } from "react-forms-processor";
import type { Field, FieldDef } from "react-forms-processor";

class MaterialSwitch extends React.Component<Field> {
  render() {
    const {
      disabled,
      id,
      name,
      onFieldChange,
      value,
      label,
      description,
      defaultValue
    } = this.props; 
    let stringValue =  value !== undefined ? value.toString() : undefined;
    let checked = value;
    if(stringValue === undefined && defaultValue !== undefined){
      stringValue = defaultValue.toString();
      checked = defaultValue;
    }

    return (
      //$FlowFixMe
      <>
      <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", margin: 0}}>
        <div>
          <span className="field-label label-with-tooltip">{label}</span>
          { description && <span data-text={description} className="i-tooltip">i</span> }
        </div>
        <FormControlLabel
          style={{margin: 0}}
          control={
            <Switch
              key={id}
              name={name}
              disabled={disabled}
              value={stringValue}
              checked={checked}
              onChange={evt => onFieldChange(id, evt.target.checked)}
            />
          }
        />
      </div>
      </>
    );
  }
}

export default (props: FieldDef) => (
  <FieldWrapper {...props}>
    {/* $FlowFixMe */}
    <MaterialSwitch />
  </FieldWrapper>
);
